import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import localeMX from '@angular/common/locales/es-MX';
import { registerLocaleData } from '@angular/common';
registerLocaleData(localeMX);

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
// import { FCM } from '@ionic-native/fcm/ngx';
// import { FCM } from 'cordova-plugin-fcm-with-dependecy-updated/ionic/ngx';
import { FirebaseX } from '@ionic-native/firebase-x/ngx';

import { Vibration } from '@ionic-native/vibration/ngx';
import { AppModules } from './modules/app';
import { LibModule } from './lib/lib.module';
import { HttpClientModule } from '@angular/common/http';
import { File } from '@ionic-native/file/ngx';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot({ mode: 'md' }),
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    LibModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    // AppModules
  ],
  providers: [
    StatusBar,
    SplashScreen,
    // FCM,
    FirebaseX,
    File,
    Vibration,
    { provide: LOCALE_ID, useValue: 'es-MX' },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
