import { Component, OnInit, Input, ViewChild, AfterViewInit } from '@angular/core';
// import { FormControlComponent } from 'src/app/lib/forms/controls/ControlRegistry';
import { Control } from '../../Control';
import { IRepositoryBase } from 'src/app/lib/data/repository';
import { SelectModalCtrlArgs } from './selectmodal.args';
import { UiService } from 'src/app/lib/services/ui.service';
import { LoggerService } from 'src/app/lib/services/logger.service';
import { DataService } from 'src/app/lib/services/data.service';
import { ModelDescriptor } from 'src/app/lib/data/model';
import { IonicSelectableComponent } from 'ionic-selectable';
import { Form } from '../../Form';
import { AbsControlComponent } from '../AbsControlComponent';
import { Subject } from 'rxjs';

// @FormControlComponent('selectModal')
export class SelectKeyValue {
  key: any; value: any;
}

@Component({
  selector: 'app-selectmodal',
  templateUrl: './selectmodal.component.html',
  styleUrls: ['./selectmodal.component.scss'],
})
export class SelectModalComponent extends AbsControlComponent implements OnInit {
  @ViewChild('ionSelectableCtrl', { static: true }) ionSelectableCtrl: IonicSelectableComponent;
  @Input() control: Control;
  @Input() form: Form;
  args: SelectModalCtrlArgs;
  valueRepo: IRepositoryBase;
  valueModel: ModelDescriptor;
  valueForm: Form;
  isLoading = true;
  selectItems: any[];
  value: any;
  valueUI: any;
  primaryKey: string;
  textField: string;
  private isAdd = false;

  onSelectItemsUpdated = new Subject<SelectModalComponent>();

  constructor(
    private loggerServ: LoggerService,
    private dataServ: DataService,
    private uiServ: UiService,
  ) {
    super();
  }

  async ngOnInit() {
    /*if (this.control.skip) {
      return;
    }*/

    this.setupAbsControl();
    if (!this.control.args) {
      this.loggerServ.error('SelectmodalComponent', 'Missing arguments in field declaration');
      return;
    }
    this.args = this.control.args as SelectModalCtrlArgs;

    if (this.args.model) {
      try {
        // # Model options
        try {
          this.valueModel = this.dataServ.getModel(this.args.model);
        } catch (err) {
          this.uiServ.alert(err);
          return;
        }
        if (this.args.repo) {
          this.valueRepo = this.valueModel.getRepo(this.args.repo);
          // console.log(this.args.repo, this.valueRepo);
        } else {
          this.valueRepo = this.valueModel.defaultRepo;
        }
        this.primaryKey = this.valueModel.primaryKey;
        this.valueForm = this.valueModel.createDefaultForm();
        this.textField = '__str__';
      } catch (err) {
        this.uiServ.error(err);
      }
    } else {
      this.primaryKey = 'key';
      this.textField = 'value';
      // console.log(this.args, this.primaryKey, this.textField, this.args.options);
    }

    await this.updateItems();

    // # Transform value if its an object instead of primary key value
    if (this.control.ngControl.value && this.control.ngControl.value[this.primaryKey]) {
      this.control.ngControl.patchValue(this.control.ngControl.value[this.primaryKey]);
    }

    // # Pass initial value to UI
    const currentValue = this.control.ngControl.value;
    if (this.args.isMultiple && Array.isArray(currentValue)) {
      const selectedValues = this.selectItems.filter(f => currentValue.indexOf(f[this.primaryKey]) >= 0);
      this.valueUI = selectedValues;
    }
    if (!this.args.isMultiple && currentValue) {
      // tslint:disable-next-line: triple-equals
      const search = this.selectItems.filter(f => f[this.primaryKey] == currentValue);
      if (search.length) {
        this.valueUI = search[0];
      }
    }
    this.updateContextValue(this.valueUI);
    // console.log(this.valueUI);

    this.isLoading = false;
  }

  async updateItems() {
    try {
      // Get new items
      if (this.args.options) {
        // # Fixed options
        this.selectItems = this.args.options;
      } else {
        this.selectItems = await this.valueRepo.query();
      }
      // Filter?
      if (this.selectItems && this.selectItems.length) {
        this.selectItems = this.execFilter(this.selectItems);
      }
      // Auto assing single value
      if (this.selectItems.length === 1) {
        this.updateValue(this.selectItems[0]);
      }

      // Remove missing value
      if (this.valueUI) {
        const found = this.selectItems.find(iter => iter.id === this.valueUI.id);
        if (!found) {
          console.log('VALUE NOT FOUND IN SELECT ', this.valueUI, this.selectItems);
          this.updateValue(undefined);
        }
      }
      this.onSelectItemsUpdated.next(this);
    } catch (err) {
      this.uiServ.error(err);
    }
  }

  async search(event: {
    component: IonicSelectableComponent,
    text: string
  }) {
    let text = event.text.trim().toLowerCase();
    event.component.startSearch();


    try {
      // Get new items
      if (this.args.options) {
        // # Fixed options
        this.selectItems = this.args.options;
      } else {
        this.selectItems = await this.valueRepo.query({ search: text });
      }
      // Filter?
      if (this.selectItems && this.selectItems.length) {
        this.selectItems = this.execFilter(this.selectItems);
      }
      // Auto assing single value
      if (this.selectItems.length === 1) {
        this.updateValue(this.selectItems[0]);
      }

      this.onSelectItemsUpdated.next(this);
    } catch (err) {
      this.uiServ.error(err);
    }

    event.component.endSearch();

  }

  setValueByIndex(id: number = 0) {
    if (!id) { id = 0; }
    if (!this.selectItems || id >= this.selectItems.length) {
      return;
    }
    this.updateValue(this.selectItems[id]);
  }

  setValueById(id: any) {
    const sel = this.selectItems.find(iter => iter.id === id);
    // console.log(id, sel);
    if (sel) {
      this.updateValue(sel);
    }
  }

  setValueByFilter(predicate: (value: any, index: number, obj: any[]) => unknown) {
    if (!this.selectItems) {
      this.updateValue(null);
      return;
    }
    const sel = this.selectItems.find(predicate);
    // console.log(id, sel);
    if (sel) {
      this.updateValue(sel);
    }
  }

  execFilter(values: any[]): any[] {
    if (!this.args.filter) { return values; }
    const filterContext = this.form.context;
    return values.filter(iter => {
      filterContext.setValueObject('__item', iter);
      return (filterContext.execute(this.args.filter));
    });
  }

  updateValue(val: any) {
    if (val && val[this.primaryKey]) {
      this.control.ngControl.setValue(val[this.primaryKey]);
    } /*else if (val && val.key) {
      this.control.ngControl.setValue(val.key);
    }*/ else {
      this.control.ngControl.setValue(undefined);
      console.log('Value ', this.valueUI, ' not found ');
    }
    this.valueUI = val;
    this.onChange(null);
    // console.log('SELECT CHANGED,', this.control.field, val);
  }

  onChange(evt) {
    if (this.args.isMultiple && Array.isArray(this.valueUI)) {
      const selectedValues = this.valueUI.map(f => f[this.primaryKey]);
      this.control.ngControl.setValue(selectedValues);
    } else if (this.valueUI && this.valueUI[this.primaryKey]) {
      this.control.ngControl.setValue(this.valueUI[this.primaryKey]);
    } else {
      // console.log('Value ', this.valueUI, ' not found in ', this.control.field);
      this.control.ngControl.setValue(undefined);
      // this.control.value = undefined;
    }
    this.updateContextValue(this.valueUI);
    // console.log('Changed to ', this.control.ngControl.value, this.control.ngControl);
    this.modified(null);
  }

  updateContextValue(valueObj: any) {
    if (this.args.storeValueInContext) {
      this.form.context.setValueObject('select__' + this.control.field, valueObj);
      // console.log(this.form.context);
    }
    /* if(this.args.storeValueIn) {
      this.form.context.setValue(this.args.storeValueIn.namespace, this.args.storeValueIn.nameOrValue, valueObj);
      console.log(this.form.context);
    }*/
  }

  async onIoSelAdd(event: {
    component: IonicSelectableComponent
  }) {
    this.value = null;
    this.isAdd = true;
    console.log(event);
    event.component.showAddItemTemplate();
  }

  async onIoSelEdit(event: {
    component: IonicSelectableComponent,
    item: any
  }) {
    this.value = event.item;
    this.isAdd = false;
    console.log(event);
    event.component.showAddItemTemplate();
  }

  async onIoSelDel(event: {
    component: IonicSelectableComponent,
    item: any
  }) {
    console.log(event);
  }

  async onValueSaved(value: any, isNew: boolean) {
    console.log(value);
    if (isNew) {
      this.selectItems.unshift(value);
    }
    this.ionSelectableCtrl.hideAddItemTemplate();
  }

  async onFormCanceled() {
    this.ionSelectableCtrl.hideAddItemTemplate();
  }

  async onFormDeleted(value: any) {
    // tslint:disable-next-line: triple-equals
    const search = this.selectItems.findIndex(f => f[this.primaryKey] == value[this.primaryKey]);
    if (search >= 0) {
      this.selectItems.splice(search, 1);
    }
    this.ionSelectableCtrl.hideAddItemTemplate();
  }

  getReadonlyText() {
    // console.log('VAL ', this.valueUI);
    if (this.valueUI) {
      return this.valueUI[this.textField];
    } else { return ''; }
  }

  canAddItems(): boolean {
    if (!this.valueModel) {
      return false;
    }
    if (this.args) {
      if (this.args.allowAdd !== undefined) {
        return this.args.allowAdd;
      }
      if (this.args.allowAddWhen) {
        return this.form.context.execute(this.args.allowAddWhen);
      }
    }
    return true;
  }

  canEditItems(): boolean {
    if (!this.valueModel) {
      return false;
    }
    if (this.args) {
      if (this.args.allowEdit !== undefined) {
        return this.args.allowEdit;
      }
      if (this.args.allowEditWhen) {
        return this.form.context.execute(this.args.allowEditWhen);
      }
    }
    return true;
  }

  clear() {
    this.valueUI = undefined;
    this.onChange(null);
  }

  async open() {
    // this.ionSelectableCtrl.
    await this.ionSelectableCtrl.open();
  }

  async close() {
    this.ionSelectableCtrl.close();
  }

  select(nombreId: string, nombreIdProp?: string) {
    // console.log(nombreId);
    if (!nombreIdProp) {
      nombreIdProp = 'nombre_id';
    }
    const val = this.selectItems.find(iter => iter[nombreIdProp] === nombreId);
    if (val) {
      this.updateValue(val);
    }
  }

  getDisplayedValue() {
    // if(this.)
  }

}
