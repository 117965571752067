<div>
  <ion-toolbar (click)="isClosed = !isClosed" class="toolbarTop">
    <ion-label>
      <span class="label plabel">
        {{control.label}}
        <span *ngIf="args.carousel && items.length">({{getCurrent()}}/{{items.length}})</span>
        <span *ngIf="args.carousel && !items.length">({{items.length}})</span>
        <span *ngIf="!args.carousel">({{items.length}})</span>
      </span>
    </ion-label>

    <ion-buttons slot="end" *ngIf="args.carousel">
      <ion-button color="primary" (click)="goTo(-1, $event)" [disabled]="items.length < 2 || isAnimating">
        <ion-icon name="arrow-back"></ion-icon>
      </ion-button>
      <ion-button color="primary" (click)="goTo(1, $event)" [disabled]="items.length < 2 || isAnimating">
        <ion-icon name="arrow-forward"></ion-icon>
      </ion-button>
      <ion-button color="primary" (click)="removeValue(null, $event)" [disabled]="!items.length || isAnimating">
        <ion-icon name="trash"></ion-icon>
      </ion-button>
      <ion-button color="primary" (click)="addValue(null, $event)" [disabled]="isAnimating" [disabled]="!canAddMore()">
        <ion-icon name="add"></ion-icon>
      </ion-button>
    </ion-buttons>

    <ion-buttons slot="end" *ngIf="!args.carousel">
      <ion-button color="primary" (click)="addValue(null, $event)" [disabled]="!canAddMore()">
        <ion-icon name="add"></ion-icon>
      </ion-button>
    </ion-buttons>

  </ion-toolbar>

  <div class="childForms" [hidden]="isClosed || items.length == 0">
    <div *ngIf="helpText" class="help-text">{{helpText}}</div>
    <div *ngFor="let element of items; let indx = index" [class]="getClass(indx)">
      <div class="itemHeader" *ngIf="!args.carousel" (click)="element.isOpen = !element.isOpen">
        <ion-label>
          <span class="label plabel">#{{indx + 1}}</span>
        </ion-label>
        <ion-buttons slot="end">
          <ion-button color="primary" (click)="removeValue(indx, $event)">
            <ion-icon name="trash"></ion-icon>
          </ion-button>
        </ion-buttons>
      </div>
      <lib-form [model]="objModel" [repo]="objRepo" [form]="element.form" [value]="element.value" [syncAfterSave]="true"
        [parentControl]="control" [hidden]="!element.isOpen">
      </lib-form>
    </div>
  </div>

  <ion-toolbar class="footerBar toolbarBottom"></ion-toolbar>
</div>