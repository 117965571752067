// tslint:disable: variable-name
import { Model } from '../../../lib/data/model';
import { Casos } from './casos';
import { DateControlArgs } from 'src/app/lib/forms/controls/date/date.args';
import { Form } from 'src/app/lib/forms/Form';
import { Aprobaciones } from './aprobaciones';
import { SelectModalCtrlArgs } from 'src/app/lib/forms/controls/selectmodal/selectmodal.args';
import { SelectCtrlArgs } from 'src/app/lib/forms/controls/select/args';
import { UsuarioPrecios } from './usuarioPrecios';
import { NumberCtrlArgs, NumberCtrlType } from 'src/app/lib/forms/controls/number/args';


export enum TipoDeSeguimiento {
  Oculto = 0,
  RespuestaPrecio = 1,
  Mensaje = 2,
  Votar = 3,
  EscalarCaso = 4,
  DeComite = 5,
  MensajeApoyo = 6,
  Costo = 7
}

@Model({
  name: 'Seguimientos',
  primaryKey: 'id',
  restEndpoint: 'precios/seguimientos/',
  singular: 'seguimiento',
  plural: 'seguimientos',
  singularArticle: 'el',
  pluralArticle: 'los',
  forms: {
    default: () => {
      return new Form({
        childControls: [
          { field: 'precio', type: 'number', label: 'Precio', required: true, args: new NumberCtrlArgs({ type: NumberCtrlType.Currency }) },
          { field: 'moneda', label: 'Moneda', type: 'selectModal', required: true, args: new SelectModalCtrlArgs({ model: 'sistema_Moneda', titleProperty: 'nombre', allowAdd: true }) },
          { field: 'fecha_vigencia', type: 'date', label: 'Vigencia', required: true, args: new DateControlArgs({ min: 'now', pickerFormat: 'DD MMM YYYY HH mm', displayFormat: 'DD de MMMM de YYYY, hh:mm A', maxRel: { years: 1 } }) },
          { field: 'comentarios', type: 'textarea', label: 'Comentarios' },

          { field: 'caso', label: '', type: 'number', hidden: true },
          { field: 'usuario_creador', label: '', type: 'number', hidden: true },
          { field: 'usuario_asignado', label: '', type: 'number', hidden: true },
          { field: 'tipo', label: '', type: 'number', hidden: true },
        ]
      });
    },
    preguntaApoyo: () => {
      return new Form({
        hideTitle: true,
        disableDelete: true,
        saveText: 'Preguntar',
        title: '',
        childControls: [
          { field: 'usuario_asignado', label: 'Preguntar a', type: 'select', args: new SelectCtrlArgs({ options: [] }) },
          { field: 'comentarios', type: 'textarea', label: 'Comentarios' },

          { field: 'caso', label: '', type: 'number', hidden: true },
          { field: 'usuario_creador', label: '', type: 'number', hidden: true },
          { field: 'usuario_asignado', label: '', type: 'number', hidden: true },
          { field: 'tipo', label: '', type: 'number', hidden: true },
        ]
      });
    },
    Preview: () => {
      return new Form({
        hideTitle: true,
        disableDelete: true,
        readonly: true,
        disableSave: true,
        childControls: [
          { field: 'precio', type: 'number', label: 'Precio', required: true, args: new NumberCtrlArgs({ type: NumberCtrlType.Currency }) },
          { field: 'moneda', label: 'Moneda', type: 'selectModal', required: true, args: new SelectModalCtrlArgs({ model: 'sistema_Moneda', titleProperty: 'nombre', allowAdd: true }) },
          { field: 'fecha_vigencia', type: 'date', label: 'Vigencia', required: true, args: new DateControlArgs({ min: 'now', pickerFormat: 'DD MMM YYYY HH mm', displayFormat: 'DD de MMMM de YYYY, hh:mm A' }) },
          { field: 'comentarios', type: 'textarea', label: 'Comentarios' }
        ]
      });
    },
    Costo: () => {
      return new Form({
        childControls: [
          { field: 'precio', type: 'number', label: 'Costo', required: true, args: new NumberCtrlArgs({ type: NumberCtrlType.Currency }) },
          { field: 'moneda', label: 'Moneda', type: 'selectModal', required: true, args: new SelectModalCtrlArgs({ model: 'sistema_Moneda', titleProperty: 'nombre', allowAdd: true }) },
          { field: 'fecha_vigencia', type: 'date', label: 'Vigencia', required: true, args: new DateControlArgs({ min: 'now', pickerFormat: 'DD MMM YYYY HH MM', displayFormat: 'DD de MMMM de YYYY, hh:MM A', maxRel: { years: 1 } }) },
          { field: 'fecha_entrega', label: 'Fecha de entrega', type: 'date', required: true, args: new DateControlArgs({ min: 'now', pickerFormat: 'DD MMM YYYY HH MM', displayFormat: 'DD de MMMM de YYYY, hh:MM A', maxRel: { years: 1 } }) },
          { field: 'comentarios', type: 'textarea', label: 'Comentarios' },

          { field: 'caso', label: '', type: 'number', hidden: true },
          { field: 'usuario_creador', label: '', type: 'number', hidden: true },
          { field: 'usuario_asignado', label: '', type: 'number', hidden: true },
          { field: 'tipo', label: '', type: 'number', hidden: true },
        ]
      });
    },
    CostoPreview: () => {
      return new Form({
        hideTitle: true,
        disableDelete: true,
        readonly: true,
        disableSave: true,
        childControls: [
          { field: 'precio', type: 'number', label: 'Costo', args: new NumberCtrlArgs({ type: NumberCtrlType.Currency }) },
          { field: 'moneda', label: 'Moneda', type: 'selectModal', args: new SelectModalCtrlArgs({ model: 'sistema_Moneda', titleProperty: 'nombre', allowAdd: true }) },
          { field: 'fecha_vigencia', type: 'date', label: 'Vigencia', args: new DateControlArgs({ min: 'now', pickerFormat: 'DD MMM YYYY HH mm', displayFormat: 'DD de MMMM de YYYY, hh:mm A' }) },
          { field: 'fecha_entrega', label: 'Fecha de entrega', type: 'date', args: new DateControlArgs({ min: 'now', pickerFormat: 'DD MMM YYYY HH MM', displayFormat: 'DD de MMMM de YYYY, hh:MM A' }) },
          { field: 'comentarios', type: 'textarea', label: 'Comentarios' }
        ]
      });
    }
  }
})
export class Seguimientos {
  id: number;
  caso: number;
  usuario_creador: number;
  usuario_asignado: number;
  fecha_vigencia: string;
  fecha_alta: string;
  comentarios: string;
  precio: number;
  comite = false;  // Si es para el comité
  aprobado = true; // Si el seguimiento se aprobó por el comité
  tipo: TipoDeSeguimiento;
  usuario_creador_nombre: string;
  usuario_asignado_nombre: string;
  aprobaciones: Aprobaciones[];
  _miAprobacion: Aprobaciones;
  _votoFinalizado = false;
}
