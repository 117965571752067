// tslint:disable: variable-name
import { Model } from '../../../lib/data/model';
import { Form } from 'src/app/lib/forms/Form';
import { SelectModalCtrlArgs } from 'src/app/lib/forms/controls/selectmodal/selectmodal.args';
import { SelectCtrlArgs, SelectCtrlArgsOptions } from 'src/app/lib/forms/controls/select/args';

import { CrudSettings } from 'src/app/lib/data/CrudSettings';

import { DateControlArgs } from 'src/app/lib/forms/controls/date/date.args';
import { NumberCtrlArgs, NumberCtrlType } from 'src/app/lib/forms/controls/number/args';

export enum PrecioTipoPrecio {
  CONTADO = 1,
  CREDITO = 2
}
export const PrecioTipoPrecio_Select: SelectCtrlArgsOptions[] = [
  { key: 1, value: 'Contado' },
  { key: 2, value: 'Crédito' },
];
@Model({
  name: 'Precios',
  primaryKey: 'id',
  restEndpoint: 'precios/precios/',
  singular: 'precio',
  plural: 'precios',
  singularArticle: 'el',
  pluralArticle: 'los',
  forms: {
    default: () => {
      return new Form({
        title: null,
        childControls: [
          { field: 'producto', label: 'Producto', type: 'selectModal', required: true, args: new SelectModalCtrlArgs({ model: 'Producto', titleProperty: 'nombre', allowAdd: true }) },
          { field: 'presentacion', label: 'Envasado', type: 'selectModal', required: true, args: new SelectModalCtrlArgs({ model: 'TipoDePrecio', titleProperty: 'nombre', allowAdd: true }) },
          { field: 'sucursales', label: 'Sucursal', type: 'selectModal', required: true, args: new SelectModalCtrlArgs({ model: 'Sucursal', titleProperty: 'nombre', allowAdd: true }) },
          { field: 'moneda', label: 'Moneda', type: 'selectModal', required: true, args: new SelectModalCtrlArgs({ model: 'Moneda', titleProperty: 'nombre', allowAdd: true }) },
          { field: 'precio', type: 'number', label: 'Precio contado', required: true, args: new NumberCtrlArgs({type:NumberCtrlType.Currency}) },
          { field: 'precio_credito', type: 'number', label: 'Precio crédito', args: new NumberCtrlArgs({type:NumberCtrlType.Currency}) },
          //{ field: 'tipo_precio', label: 'Tipo de precio', type: 'select', args: new SelectCtrlArgs({ options: PrecioTipoPrecio_Select }) },
          { field: 'impuesto_integrado', label: 'Impuesto en precio', type: 'checkbox' },
          { field: 'porcentaje_impuesto', label: 'Porcentaje', type: 'number' },
          { field: 'fecha_vigencia', type: 'date', label: 'Vigencia', args: new DateControlArgs({}) },
          { field: 'folio_inventario', label: 'Folio inventario', type: 'number', max: 10 },
        ]
      });
    }
  },
  cruds: {
    default: new CrudSettings({
      labelProperty: '_editLabel',
    })
  }
})
export class Precios {
  id: number;
  fecha_vigencia: string;
  producto: number;
  presentacion: number;
  sucursales: number;
  precio: number;
  precio_contado: number;
  moneda: number;
  impuesto_integrado = false;
  porcentaje_impuesto: number;
  folio_inventario: string;
  tipo_precio: PrecioTipoPrecio;
  precio_credito: any;
  view: {
    fecha_vigencia: string;
    producto: string;
    presentacion: string;
    moneda: string;
    medida: string;
    sucursal: string;
    porcentaje_impuesto: number;
    inventario: number;
    tipo_precio: PrecioTipoPrecio;
  };

  public get _editLabel(): string {
    return 'Producto: ' + this.view.producto + ',<br>Presentación: ' + this.view.presentacion + ',<br>Moneda: ' + this.view.moneda + ',<br>Sucursal: ' + this.view.sucursal;
  }
}
