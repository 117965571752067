<ion-item>
  <ion-label [class]="getLabelClass()" [position]="labelPosition">{{control.label}}</ion-label>
  <div class="formatedValueBox" *ngIf="!hasFocus">
    {{control.ngControl.value | currency:'MXN' }}
  </div>
  <ion-input
    type="number"
    [formControl]="control.ngControl"
    [min]="control.min"
    [max]="control.max"
    (ionChange)="modified($event)"
    [readonly]="form.computeIsControlReadonly(control)"
    inputmode="decimal"
    [disabled]="form.disabled || control.disabled"
    (ionFocus)="focusChange(true)"
    (ionBlur)="focusChange(false)" >
  </ion-input>
  <div *ngIf="args.suffix" class="suffix">
    {{args.suffix}}
  </div>
</ion-item>