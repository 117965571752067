import { IControlArgs } from '../../Control';

export class SelectCtrlArgsOptions {
  key: number | string;
  value: string;
}

export class SelectCtrlArgs implements IControlArgs {
  options: SelectCtrlArgsOptions[] = [];
  suffix: string;

  constructor(other?: Partial<SelectCtrlArgs>) {
    if (other) {
      Object.assign(this, other);
    }
  }
}

window['SelectCtrlArgs'] = SelectCtrlArgs;
window['SelectCtrlArgsOptions'] = SelectCtrlArgsOptions;
