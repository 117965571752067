<ion-item>
  <ion-label [class]="getLabelClass()" [position]="labelPosition">{{control.label}}</ion-label>
  <ion-input
    type="text"
    [formControl]="control.ngControl"
    [minlength]="control.min"
    [maxlength]="control.max"
    (ionChange)="modified($event)"
    [readonly]="form.computeIsControlReadonly(control)"
    [disabled]="form.disabled || control.disabled">
  </ion-input>
</ion-item>