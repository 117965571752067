import { Injectable } from '@angular/core';
// import { NotificationData } from '@ionic-native/fcm/ngx';
// import { FCM } from 'cordova-plugin-fcm-with-dependecy-updated/ionic/ngx';
import { Platform, AlertController } from '@ionic/angular';
import { AuthService } from './auth.service';
import { ConfigService } from './config.service';
import { UiService } from './ui.service';
import { LoggerService } from './logger.service';
import { WebapiService } from './webapi.service';
import { Subject } from 'rxjs';
import { NotificationMessage, NotificationOrigin } from '../data/NotificationMessage';
import { FirebaseX } from '@ionic-native/firebase-x/ngx';
import { NotificationData } from '@ionic-native/fcm';
import { PlatformService } from './platform.service';


export class FCMSettings {
  logEvents?= true;
  webapiSaveTokenURL = '';
  defaultTopics?: string[];
  loggedInTopics?: string[];
  notificationListeners?: ((msg: NotificationMessage) => void)[];
  // Wether events should not be raised if the app is already open
  ignoreTaps?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class FcmService {
  tokenAcquired: boolean;
  deviceToken: string;
  settings: FCMSettings;
  didSetup = false;
  notificationArrived: Subject<NotificationMessage> = new Subject<NotificationMessage>();

  constructor(
    private fbx: FirebaseX,
    private platform: Platform,
    private authServ: AuthService,
    private alertCtrl: AlertController,
    private uiServ: UiService,
    private platformServ: PlatformService,
    private loggerServ: LoggerService,
    private webapiServ: WebapiService) {
  }

  async setup(settings: FCMSettings) {
    // this.fcm = FCM;
    this.settings = settings;
    if (this.platformServ.isNative) {
      await this.uiServ.wait(1000);
      await this.initFCM();
    }
    this.loggerServ.info('FCMService', 'Initialized');
  }

  private async initFCM() {
    try {
      const hasPermisson = await this.fbx.hasPermission();
      if (!hasPermisson) {
        const result = await this.fbx.grantPermission();
        console.log(result);
        if (!result) {
          this.logErr('Notification permission denied!');
          return;
        }
      }

      // Configure device token
      let token: string;
      try {
        token = await this.fbx.getToken();
        this.storeDeviceToken(token);
        this.deviceToken = token;
      } catch (err) {
        console.error(err);
        this.logErr('Error al obtener token FCM', err);
      }

      if (!this.didSetup) {
        this.fbx.onTokenRefresh().subscribe((newToken) => {
          this.storeDeviceToken(newToken);
        });

        // Configure topic listening
        this.fbx.subscribe('system').
          then((data) => { this.log('Escuchando tópico: system'); }).
          catch((err) => { this.logErr(err); });


        this.fbx.onMessageReceived().subscribe((data) => this.newNotificationArrived(data), (err) => { this.logErr(err); });

        this.log('FCM plugin initialized');
        this.didSetup = true;
      } else {
        this.log('FCM plugin reinitialized');
      }
    } catch (err) {
      this.log(err);
    }
  }

  private newNotificationArrived(notData: NotificationData) {
    if (notData.wasTapped && this.didSetup) {
      return;
    }

    const msg = new NotificationMessage();
    msg.wasTapped = notData.tap === 'background';
    msg.origin = NotificationOrigin.PUSH;
    if (typeof (notData.data) === 'string') {
      const tmpData = JSON.parse(notData.data);
      msg.type = tmpData.type;
      msg.data = tmpData;
    } else {
      Object.assign(msg, notData);
      Object.assign(msg.data, notData);
    }
    if (this.platform.is('ios')) {
      try {
        msg.title = notData.aps.alert.title;
        msg.body = notData.aps.alert.body;
      } catch (err) {
        this.loggerServ.error('FCMServ', 'Error decoding iOS FCM notification', err);
      }
    }
    console.log(notData, msg);

    this.log('New notification arrived: ', msg);
    if (this.settings.notificationListeners) {
      for (const listener of this.settings.notificationListeners) {
        listener(msg);
      }
    }
    this.notificationArrived.next(msg);
  }

  private log(msg: string, obj?: any) {
    if (!this.settings.logEvents) { return; }
    this.loggerServ.info('FCMService', msg, obj);
  }

  private logErr(msg: string, obj?: any) {
    this.loggerServ.error('FCMService', msg, obj);
  }

  private async storeDeviceToken(token: string) {
    if (this.settings.webapiSaveTokenURL && this.authServ.isLoggedIn) {
      this.deviceToken = token;
      try {
        await this.webapiServ.post(this.settings.webapiSaveTokenURL, { token });
      } catch (err) {
        this.loggerServ.error('FCMService', 'Token could not be saved to backend', err);
      }
    }
    this.log('Device token set to ', token);
  }

  simulateNotification(msg: NotificationMessage) {
    msg.simulated = true;
    this.log('Simulating notification: ', msg);
    if (this.settings.notificationListeners) {
      for (const listener of this.settings.notificationListeners) {
        listener(msg);
      }
    }
    this.notificationArrived.next(msg);
  }
}
