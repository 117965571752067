import { Component, OnInit, Input } from '@angular/core';
import { SelectCtrlArgs } from './args';
import { Form } from '../../Form';
import { Control } from '../../Control';
import { AbsControlComponent } from '../AbsControlComponent';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
})
export class SelectComponent extends AbsControlComponent implements OnInit {
  @Input() control: Control;
  @Input() form: Form;
  args: SelectCtrlArgs;

  constructor() {
    super();
  }

  ngOnInit() {
    this.setupAbsControl();
    this.args = this.control.args as SelectCtrlArgs;
    if (!this.args) {
      this.args = new SelectCtrlArgs();
      // throw new Error('CONTROL ' + this.control.field + ' NO TIENE CONFIGURADO SelectCtrlArgs!');
    }
  }

  getValue() {
    const value = this.control.ngControl.value;
    // tslint:disable-next-line: triple-equals
    const opc = this.args.options.filter(iter => iter.key == value);
    if (opc.length) {
      return opc[0].value;
    } else {
      return '';
    }
  }
}
