import { IControlArgs } from '../../Control';

export class TextImageArgs implements IControlArgs {
  maxSizeMB = 0.3;
  disableClear = false;
  onlyCamera = false;

  constructor(other?: Partial<TextImageArgs>) {
    if (other) {
      Object.assign(this, other);
    }
  }
}

window['TextImageArgs'] = TextImageArgs;