<ion-item>
  <ion-label [class]="getLabelClass()" [position]="labelPosition">{{control.label}}</ion-label>
  <ion-datetime
    [formControl]="UIControl"
    (ionChange)="onChange($event)"
    doneText="Aceptar"
    cancelText="Cancelar"
    [monthNames]="monthNames"
    [monthShortNames]="monthShortNames"
    [displayFormat]="args.displayFormat"
    [pickerFormat]="args.pickerFormat"
    [min]="minDate"
    [max]="args.max"
    [readonly]="form.computeIsControlReadonly(control)"
    [disabled]="form.disabled || control.disabled"
    cancelText="Cancelar"
    doneText="Aceptar"
    [pickerOptions] = "customPickerOptions"
    >
  </ion-datetime>
</ion-item>
<!-- display-timezone="America/Mazatlan" -->