import { Context, ValueCalcFunc } from './context';

export class Title {
  when: string;
  text: string;
}

/** Container for programmatic UI titles */
export class Titles {

  titles: Title[];

  code: ValueCalcFunc;

  constructor(titles: Title[], code?: ValueCalcFunc) {
    this.titles = titles;
    this.code = code;
  }

  hasTitles() {
    return this.titles !== undefined && this.titles.length > 0;
  }

  getTitle(context?: Context, defaultTitle?: string, arg?: any) {
    if (this.code) {
      const val = this.code(context, context.values, arg);
      if (val) {
        return val;
      } else {
        return defaultTitle;
      }
    }
    if (!this.titles) {
      return defaultTitle;
    }
    for (const titleIter of this.titles) {
      if (titleIter.when && context && context.execute(titleIter.when, false, arg)) {
        return context.execute(titleIter.text, undefined, arg);
      }
    }
    return defaultTitle;
  }

  /*setTitle(name: string, def: Title) {
    if (!this.titles) {
      this.titles = {};
    }
    this.titles[name] = def;
  }*/
}
window['Titles'] = Titles;
