import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { InitGuard } from 'src/app/lib/services/init.guard';
import { Usuario } from './models/usuario';
import { Cimquito } from './models/cimquito';
import { Notificacion } from './models/notificacion';
import { EventsService } from 'src/app/lib/services/events.service';
import { DataService } from 'src/app/lib/services/data.service';
import { Form, FormEvent } from 'src/app/lib/forms/Form';
import { Control } from 'src/app/lib/forms/Control';
import { ContextGuard } from 'src/app/lib/services/context.guard';

const routes: Routes = [
  { path: '', redirectTo: 'init', pathMatch: 'full' },
  { path: 'init', loadChildren: () => import('./pages/init/init.module').then(m => m.InitPageModule) },
  { path: 'dash', canActivate: [InitGuard], loadChildren: () => import('../system/pages/dash/dash.module').then(m => m.DashPageModule) },
  { path: 'editar/:model', canActivate: [InitGuard], loadChildren: () => import('../../lib/pages/crud/crud.module').then(m => m.CrudPageModule) },
  { path: 'editar/:model/:contexts', canActivate: [InitGuard], loadChildren: () => import('../../lib/pages/crud/crud.module').then(m => m.CrudPageModule) },
  { path: 'editar_dato/:model/:contexts/:value', canActivate: [InitGuard, ContextGuard], loadChildren: () => import('../../lib/pages/edit/edit.module').then(m => m.EditPageModule) },
  { path: 'models', canActivate: [InitGuard], loadChildren: () => import('../../lib/pages/test-model-list/test-model-list.module').then(m => m.TestModelListPageModule) },
  { path: 'opciones', canActivate: [InitGuard], loadChildren: () => import('../system/pages/options/options.module').then(m => m.OptionsPageModule) },
  { path: 'calendario', canActivate: [InitGuard], loadChildren: () => import('../system/pages/calendar/calendar.module').then(m => m.CalendarPageModule) },
  { path: 'calendario/:contexts', canActivate: [InitGuard, ContextGuard], loadChildren: () => import('../system/pages/calendar/calendar.module').then(m => m.CalendarPageModule) },
  { path: '**', canActivate: [InitGuard], loadChildren: () => import('./pages/not-found/not-found.module').then(m => m.NotFoundPageModule) }
];

export const SystemModels: any[] = [
  Usuario, Cimquito
];

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forChild(routes),
    CommonModule
  ],
  exports: [
    RouterModule
  ]
})
export class SystemModule {

  constructor(
    private eventServ: EventsService,
    private dataServ: DataService
  ) {
    eventServ.onInit.subscribe(async () => { this.setupApp(); });
  }

  setupApp() {
    this.dataServ.getModel('sistema_Notificacion').onFormEvent = Notificacion.HandleFormEvents;
  }
}
