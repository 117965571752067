// tslint:disable: no-string-literal
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Form } from '../../forms/Form';
import { IPaginationInfo, IRepositoryBase } from '../../data/repository';
import { ModelDescriptor } from '../../data/model';
import { CrudSettings } from '../../data/CrudSettings';
import { UiService } from '../../services/ui.service';
import { LoggerService } from '../../services/logger.service';
import { DataService } from '../../services/data.service';
import { ModalController } from '@ionic/angular';
import { EditorComponent } from '../editor/editor.component';

@Component({
  selector: 'lib-crud',
  templateUrl: './crud.component.html',
  styleUrls: ['./crud.component.scss'],
})
export class CrudComponent implements OnInit {
  @Input() model: ModelDescriptor;
  @Input() modelName: string;
  @Input() settings: CrudSettings;
  @Input() repo: IRepositoryBase;
  @Input() enableRender = true;
  @Input() paginationLimit = 64;
  @Output() changed = new EventEmitter(true);

  // form: Form;
  formCtr: () => Form;
  isFirstLoading = true;
  valuesSrc: any[] = [];
  valuesFiltered: any[] = [];
  paginationOffset: number;
  currentPaginationState: IPaginationInfo;
  localFilterTerm: string;
  searchTerm: string;
  private isAdd = false;

  constructor(
    private uiServ: UiService,
    private loggerServ: LoggerService,
    private dataServ: DataService,
    private modalCtrl: ModalController
  ) { }

  async ngOnInit() {

    // # Get model info
    if (!this.model && this.modelName) {
      this.model = this.dataServ.getModel(this.modelName);
    }
    if (!this.model) {
      this.loggerServ.error('CRUD', 'Model not configured correctly. Missing model property in crud.component.');
      return;
    }

    // # Get settings
    if (!this.settings) {
      if (this.model.cruds) {
        this.settings = this.model.cruds[Object.keys(this.model.cruds)[0]];
      }
    }
    if (!this.settings) {
      this.loggerServ.error('CRUD', 'No CRUD settings set in component');
      return;
    }

    // # Get Form
    if (!this.formCtr) {
      if (!this.settings.form) {
        this.formCtr = this.model.getDefaultForm();
      } else if (typeof this.settings.form === 'string') {
        this.formCtr = this.dataServ.getForm(typeof this.settings.form);
      } else {
        this.formCtr = this.settings.form;
      }
    }
    if (!this.formCtr) {
      this.loggerServ.error('CRUD', 'Form not found for model ' + this.model.name);
      return;
    }
    // this.form.onSaved = () => { this.onSaved(); };

    // # Get repo
    if (!this.repo) {
      if (!this.settings.repository) {
        this.repo = this.dataServ.getModelRepoDesc(this.model);
      } else {
        if (typeof this.settings.repository === 'string') {
          this.repo = this.dataServ.getRepo(this.settings.repository);
        } else {
          this.repo = this.settings.repository;
        }
      }
    }
    if (!this.repo) {
      this.repo = this.model.defaultRepo;
    }
    if (!this.repo) {
      // this.repo = this.model.defaultRepo;
      this.loggerServ.error('CRUD', 'Repository not set for ' + this.model.name);
    }
    if (this.enableRender) {
      await this.refresh();
    }
    this.isFirstLoading = false;
  }

  async refresh() {
    this.isFirstLoading = true;
    try {
      // console.log(this.settings.queryParams);
      const repoParams = { limit: this.paginationLimit };
      const queryParams = this.computeQueryParams();
      this.valuesSrc = await this.repo.query(queryParams, repoParams);
      this.currentPaginationState = this.repo.pagination;
      if (this.currentPaginationState && this.valuesSrc) {
        this.paginationOffset = this.valuesSrc.length;
      }
      // console.log(this.currentPaginationState);
      // this.valuesSrc.sort((a, b) => this.sortItems(a, b));
      this.valuesFiltered = this.valuesSrc;
    } catch (err) {
      this.uiServ.error(err, 'toast');
    }
    this.isFirstLoading = false;
  }

  async next() {
    try {
      // console.log(this.settings.queryParams);
      const params = { limit: this.paginationLimit, offset: this.paginationOffset };
      const nextValues = await this.repo.query(this.settings.queryParams, params);
      this.valuesSrc.push(...nextValues);
      this.currentPaginationState = this.repo.pagination;
      if (this.currentPaginationState) {
        this.paginationOffset += this.paginationLimit;
      }
      this.valuesFiltered = this.valuesSrc;
      console.log(this.currentPaginationState);
      // this.valuesSrc.sort((a, b) => this.sortItems(a, b));
      this.valuesFiltered = this.valuesSrc;
    } catch (err) {
      this.uiServ.error(err, 'toast');
    }
  }

  async loadNext(event?): Promise<boolean> {
    await this.next();
    console.log(this.valuesSrc.length, this.currentPaginationState.count);
    const finishedLoading = !this.currentPaginationState || this.valuesSrc.length >= this.currentPaginationState.count;
    // finishedLoading = !finishedLoading;
    if (event) {
      event.target.disabled = finishedLoading;
    }
    console.log('Scroll state: ' + finishedLoading);
    return finishedLoading;
  }

  computeQueryParams(): any {
    if (!this.settings.queryParams) { this.settings.queryParams = {}; }
    /*if (this.settings.searchFields) {
      // TODO
    } else {
      const searchField = this.settings.searchField ? this.settings.searchField : 'nombre';
      if (this.searchTerm) {
        this.settings.queryParams[searchField] = this.searchTerm;
      } else {
        if (this.settings.queryParams) {
          delete this.settings.queryParams[searchField];
        }
      }
    }*/
    if (this.searchTerm) {
      this.settings.queryParams['search'] = this.searchTerm;
    } else {
      delete this.settings.queryParams['search'];
    }
    return this.settings.queryParams;
  }

  // https://stackoverflow.com/questions/1129216/sort-array-of-objects-by-string-property-value
  sortItems(a: any, b: any) {
    let aTerm = a[this.settings.labelProperty];
    let bTerm = b[this.settings.labelProperty];
    if (!aTerm) { aTerm = ''; }
    if (!bTerm) { bTerm = ''; }
    return aTerm.localeCompare(bTerm);
  }

  cancelFiltering() {
    this.valuesFiltered = this.valuesSrc;
  }

  filterByTerm(term: string) {
    if (!this.valuesSrc) {
      this.valuesFiltered = [];
      return;
    }
    this.localFilterTerm = term;
    if (!this.localFilterTerm) {
      this.valuesFiltered = this.valuesSrc;
      return;
    }
    const lowerCaseTerm = term.toLowerCase();
    this.valuesFiltered = this.valuesSrc.filter((value, index) => {
      if (!value[this.settings.labelProperty].toLowerCase) { return; }
      return (value[this.settings.labelProperty].toLowerCase().indexOf(lowerCaseTerm) >= 0);
    });
  }

  async edit(item: any, forceIsNew?: boolean) {
    const myForm = this.formCtr();
    myForm.context = this.settings.context;
    myForm.hideTitle = true;
    if (!item) {
      forceIsNew = true;
    }
    // console.log(item);
    console.log(this.settings);
    const modal = await this.modalCtrl.create({
      component: EditorComponent,
      componentProps: {
        model: this.model,
        form: myForm,
        repo: this.repo,
        value: item,
        isModal: true,
        // title: modalTittle,
        showCancel: true,
        context: this.settings.context,
        forceIsNew
      }
    });
    await modal.present();
    myForm.editorComponentId = modal.id;
    const result = await modal.onDidDismiss();
    if (result.role === 'saved' || result.role === 'deleted') {
      await this.refresh();
      if (this.localFilterTerm) {
        this.filterByTerm(this.localFilterTerm);
      }
      await this.changed.emit();
    }
  }

  getLabel(item: any) {
    if (this.settings.customLabel) {
      return this.settings.customLabel(item);
    }
    if (!this.model.crudLabelSettings) {
      return item[this.settings.labelProperty];
    } else {
      let label = '';
      (this.model.crudLabelSettings).forEach(labelCompo => {
        // Skip this component?
        if (labelCompo.hideWhen && this.settings.context) {
          this.settings.context.setValueObject('value', item);
          if (this.settings.context.execute(labelCompo.hideWhen)) {
            return;
          }
        }
        // Field
        if (labelCompo.field) {
          label += item[labelCompo.field];
        }
        if (!labelCompo.noSpace) {
          label += ' ';
        }
      });
      return label;
    }
  }
}
