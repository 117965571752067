import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UiService } from 'src/app/lib/services/ui.service';
import { Control } from '../../Control';
import { Form } from '../../Form';
import { AbsControlComponent } from '../AbsControlComponent';
import { TextImageArgs } from './args';
import imageCompression from 'browser-image-compression';

@Component({
  selector: 'app-text-image',
  templateUrl: './text-image.component.html',
  styleUrls: ['./text-image.component.scss'],
})
export class TextImageComponent extends AbsControlComponent implements OnInit {
  @Input() control: Control;
  @Input() form: Form;
  @ViewChild('cameraInput') cameraInput;
  args: TextImageArgs;
  helpText: string;

  constructor(
    private uiServ: UiService,
  ) {
    super();
  }

  ngOnInit() {
    this.setupAbsControl();
    this.args = this.control.args as TextImageArgs;
    if (!this.args) {
      this.args = new TextImageArgs();
    }

  }

  loadImage() {
    this.cameraInput.nativeElement.click();
  }

  async pictureTaken(evt) {
    const data = evt.target.files[0];
    if(!evt.target.files[0]) {
      return;
    }
    this.uiServ.loadingMessage('Cargando...');
    try {
      const img =  await this.processImageFile(data);
      this.control.ngControl.patchValue(img);
      this.modified(null);
      this.uiServ.clearLoadingMessage();
    } catch (err) {
      // console.error(err);
      this.uiServ.clearLoadingMessage();
      this.uiServ.error(err);
    }
    this.uiServ.clearLoadingMessage();
  }

  async processImageFile(file: File): Promise<string> {
    // this.showFileLength(file);
    const cfile = await imageCompression(file, {
      maxSizeMB: this.args.maxSizeMB,
      // maxWidthOrHeight: this.args.maxSize,
      // https://www.npmjs.com/package/browser-image-compression
    });
    // this.showFileLength(cfile);
    return await new Promise((res, rej) => {
      const reader = new FileReader();
      reader.onload = (evtFile) => {
        res(evtFile.target.result as string);
      };
      reader.onerror = () => {
        rej();
      };
      reader.readAsDataURL(cfile);
    });
  }

  showFileLength(file: File) {
    const reader = new FileReader();
    reader.onload = (evtFile) => {
      console.log('File ' + file.name + ' size ' + (evtFile.target.result as string).length);
    };
    reader.readAsDataURL(file);
  }

  clear() {
    this.control.ngControl.patchValue(undefined);
    this.modified(null);
  }

}
