import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormArray } from '@angular/forms';
import { FormComponent, ICompositeFormControl } from 'src/app/lib/components/form/form.component';
import { Context } from 'src/app/lib/data/context';
import { ModelDescriptor } from 'src/app/lib/data/model';
import { IRepositoryBase } from 'src/app/lib/data/repository';
import { DataService } from 'src/app/lib/services/data.service';
import { LoggerService } from 'src/app/lib/services/logger.service';
import { UiService } from 'src/app/lib/services/ui.service';
import { Control } from '../../Control';
import { Form } from '../../Form';
import { AbsControlComponent } from '../AbsControlComponent';
import { ObjectArrayCtrlArgs } from './args';
import { trigger, state, style, animate, transition } from '@angular/animations';

class ArrayElement {
  form: Form;
  value: any;
  cssClass: string;
  isOpen: boolean;
}

@Component({
  selector: 'app-object-array',
  templateUrl: './object-array.component.html',
  styleUrls: ['./object-array.component.scss']
})
export class ObjectArrayComponent extends AbsControlComponent implements OnInit, ICompositeFormControl {
  @Input() control: Control;
  @Input() form: Form;
  args: ObjectArrayCtrlArgs;
  helpText: string;
  itemActive = 0;
  items: ArrayElement[] = [];
  ngFormArray: FormArray;
  animLength = 0.6 * 1000;
  isAnimating = false;
  isClosed = false;

  objModel: ModelDescriptor;
  objRepo: IRepositoryBase;

  invalidCheckBeforeAdd = true;

  constructor(
    private dataServ: DataService,
    private uiServ: UiService,
    private loggerServ: LoggerService
  ) {
    super();
  }

  async ngOnInit() {
    if (this.control.skip) {
      return;
    }
    this.setupAbsControl();
    this.args = this.control.args as ObjectArrayCtrlArgs;
    if (!this.args) {
      this.args = new ObjectArrayCtrlArgs();
      // this.uiServ.errorDev('Argumentos de campo ' + this.control.field + ' no establecidos en metadata!');
      return;
    }

    // Create child values
    this.objModel = this.dataServ.getModel(this.args.model);
    this.ngFormArray = new FormArray([]);
    this.form.ngFormGroup.setControl(this.control.field, this.ngFormArray);

    /*this.objForm = this.objModel.createDefaultForm(); // TODO set this to configurable ?
    this.objForm.parent = this.form;
    this.objForm.hideTitle = true;*/

    // Value
    // const isActive = this.form.computeIsControlHidden(this.control);
    /* if (!this.control.ngControl.value) {
      this.control.ngControl.patchValue({});
    }*/

    const orgValuesArray = this.control.ngControl.value as [];
    if (orgValuesArray) {
      for (const val of orgValuesArray) {
        this.addValue(val);
      }
    } else {
      this.control.ngControl.patchValue([]);
    }

    // const value = this.control.ngControl.value || {};
    // const val = this.control.ngControl.value;
    // console.log(this.control.field, val);
  }

  childFormCreated(form: FormComponent) {
    // this.loggerServ.info('Form', 'Linked array child form "' + this.control.field + '" to parent array.');
    this.ngFormArray.push(form.ngForm);
    this.control.childControls = this.items.map(iter => iter.form);
    this.updateControlValues();

    form.form.onControlValueChanged.subscribe((input) => {
      this.form.childChanged(input);
    });
  }

  private hasInvalidField(ctrl: AbstractControl) {
    ctrl.updateValueAndValidity({ onlySelf: false, emitEvent: true });
    ctrl.markAsDirty();
    ctrl.markAsTouched();

    if (ctrl.invalid) {
      return false;
    }
    const childControls = ctrl['controls'] as AbstractControl[];
    if (childControls && Array.isArray(childControls)) {
      for (const ctrl of childControls) {
        if (!this.hasInvalidField(ctrl)) {
          return false;
        }
      }
    }
  }

  async isValid(): Promise<boolean> {
    if (this.invalidCheckBeforeAdd && this.ngFormArray.controls.length) {
      /*this.ngFormArray.markAllAsTouched();
      this.ngFormArray.updateValueAndValidity({ onlySelf: false });
      await this.uiServ.wait(20);
      if (this.ngFormArray.invalid) {
        this.uiServ.alert('Existen campos inválidos, por favor corríjalos antes de agregar un nuevo elemento.');
        return;
      }*/
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < this.items.length; i++) {
        const child = this.items[i];
        const allInvalidFields = child.form.ngComponent.getInvalidFields() as string[];
        if (allInvalidFields.length) {
          let txt = 'No es posible agregar nuevo dato, los siguientes campos no son válidos:<b><br>';
          allInvalidFields.forEach(fieldName => {
            txt += '-' + fieldName + '<br>';
          });
          txt += '</b><br>Por favor corrija los campos marcados con rojo.';
          await this.uiServ.alert(txt);
          this.ngFormArray.markAllAsTouched();
          this.ngFormArray.updateValueAndValidity({ onlySelf: false });
          return false;
        }
      }
    }
    return true;
  }

  async addValue(value?: any, event?: Event) {
    if (!value) {
      value = {};
    }
    if (event) {
      event.stopPropagation();
    }

    if (!await this.isValid()) {
      return;
    }
    // await this.uiServ.wait(2000);*/
    // return;

    // await this.uiServ.wait(10);

    if (this.items[this.itemActive]) {
      const oldActive = this.itemActive;
      this.isAnimating = true;
      this.items[oldActive].cssClass = 'slideToLeft';
      setTimeout(() => {
        this.items[oldActive].cssClass = 'invisible';
        this.isAnimating = false;
      }, this.animLength);
    }

    const form = this.objModel.createDefaultForm();
    form.parent = this.form;
    form.hideTitle = true;
    const childContext = new Context('child_array_' + this.control.field);
    childContext.merge(this.form.context);
    form.context = childContext;
    this.items.push({
      form,
      value,
      cssClass: 'slideFromRight',
      isOpen: true
    });
    this.itemActive = this.items.length - 1;
  }

  async removeValue(index?: number, event?: Event) {
    if (event) {
      event.stopPropagation();
    }
    if (!await this.uiServ.pregunta('¿Está seguro que desea eliminar este dato?')) {
      return;
    }
    // let index = this.itemActive;
    if (index === undefined || index === null) {
      index = this.itemActive;
    }
    const delItem = this.items[index];
    this.items.splice(index, 1);
    this.ngFormArray.removeAt(index);

    this.itemActive -= 1;
    if (this.itemActive < 0) {
      this.itemActive = this.items.length - 1;
    }
    if (this.items.length) {
      this.items[this.itemActive].cssClass = 'visible';
    }
    this.updateControlValues();
  }

  updateControlValues() {
    const values = this.items.map(iter => iter.value);
    if (values.length) {
      this.control.ngControl.patchValue(values);
    } else {
      this.control.ngControl.patchValue(undefined);
    }
  }

  goTo(positions: number, event?: Event) {
    if (positions > 0) {
      this.items[this.itemActive].cssClass = 'slideToLeft';
    } else {
      this.items[this.itemActive].cssClass = 'slideToRight';
    }
    if (event) {
      event.stopPropagation();
    }

    // console.log(this.itemActive, '=', this.items[this.itemActive].cssClass);
    const oldActive = this.itemActive;
    this.isAnimating = true;
    setTimeout(() => {
      this.items[oldActive].cssClass = 'invisible';
      this.isAnimating = false;
    }, this.animLength);

    this.itemActive += positions;
    if (this.itemActive < 0) {
      this.itemActive = this.items.length - 1;
      // this.items[this.itemActive].cssClass = 'slideFromRight';
    } else if (this.itemActive >= this.items.length) {
      this.itemActive = 0;
      // this.items[this.itemActive].cssClass = 'slideFromLeft';
    }

    {
      if (positions > 0) {
        this.items[this.itemActive].cssClass = 'slideFromRight';
      } else {
        this.items[this.itemActive].cssClass = 'slideFromLeft';
      }
    }/*else {
      this.items[this.itemActive].cssClass = 'slideFromRight visible';
    }*/

    // console.log(this.itemActive, '=', this.items[this.itemActive].cssClass);
  }

  getCurrent() {
    if (!this.items.length) { return 0; }
    return this.itemActive + 1;
  }

  isActive(itemIndex: number) {
    return itemIndex === this.itemActive;
  }

  getClass(itemIndex: number) {
    const itm = this.items[itemIndex];
    let cclass = '';
    if (this.args.carousel) {
      cclass = 'formContainerCarousel ' + itm.cssClass;
    } else {
      cclass = 'formContainer';
    }
    if (itemIndex === this.itemActive) {
      cclass += ' visible';
    }
    return cclass;
  }

  canAddMore() {
    // console.log(this.args.max);
    if (typeof this.args.max !== 'number') { return true; }
    return this.items.length < this.args.max;
  }

  collectValue() {
    if (this.control.notSaveWhen && this.form.context.execute(this.control.notSaveWhen)) {
      return undefined;
    }
    const values = [];
    for (const itm of this.items) {
      const obj = {};
      for (const ctrl of itm.form.childControls) {
        const ctrlValue = ctrl.ngComponent.collectValue();
        if (ctrlValue !== undefined) {
          obj[ctrl.field] = ctrlValue;
        }
      }
      values.push(obj);
    }
    return values;
  }

}
