<ion-header *ngIf="showHeader">
  <ion-toolbar color="primary">
    <ion-title>{{title}}</ion-title>
    <ion-buttons slot="end" *ngIf="isModal">
      <ion-button (click)="cancel()">
        <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
      <ion-back-button></ion-back-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content *ngIf="!isLoading">
  <div *ngIf="helpText" class="help-text">{{helpText}}</div>
  <lib-form  [model]="model" [repo]="repo" [form]="form" [value]="value" [syncAfterSave]="syncAfterSave" [isNew]="isNew" (canceled)="cancel()">
  </lib-form>
</ion-content>