import { Component, OnInit, Input } from '@angular/core';
import { ModelDescriptor } from 'src/app/lib/data/model';
import { IRepositoryBase } from 'src/app/lib/data/repository';
import { DataService } from 'src/app/lib/services/data.service';
import { LoggerService } from 'src/app/lib/services/logger.service';
import { ModalController } from '@ionic/angular';
import { Form } from '../../forms/Form';
import { Context } from '../../data/context';
import { ContextService } from '../../services/context.service';
import { UiService } from '../../services/ui.service';
import { ExposedComponent } from '../ComponentRegistry';
// import {} from '../../components/'

@ExposedComponent('EditorComponent')
@Component({
  selector: 'lib-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.scss'],
})
export class EditorComponent implements OnInit {
  @Input() model: ModelDescriptor;
  @Input() modelName: string;
  @Input() form: Form;
  @Input() formName: string;
  @Input() value: any;
  @Input() repo: IRepositoryBase;
  @Input() repoName: string;
  @Input() title: string;
  @Input() isModal = false;
  @Input() showCancel = false;
  @Input() syncAfterSave = true;
  @Input() helpText: string;
  @Input() context: Context;
  @Input() showHeader = true;
  isNew: boolean;
  isSaved: boolean;
  forceIsNew: boolean;
  isLoading = true;

  constructor(
    public dataServ: DataService,
    public modalCtrl: ModalController,
    public contextServ: ContextService,
    public uiServ: UiService,
    public loggerServ: LoggerService) { }

  async ngOnInit() {

    // # Get model info
    if (!this.model && this.modelName) {
      this.model = this.dataServ.getModel(this.modelName);
    }
    if (!this.model) {
      this.loggerServ.error('Form', 'Model not configured correctly. Missing model property in editor.component.');
      return;
    }

    // # Get Form
    if (!this.form && this.formName) {
      this.form = this.dataServ.createForm(this.formName);
    }
    if (!this.form) {
      this.form = this.model.createDefaultForm();
    }
    if (!this.form) {
      this.loggerServ.error('Form', 'Form not found for model ' + this.model.name);
      return;
    }
    this.form.onSaved = () => { this.onSaved(); };
    this.form.onDeleted = () => { this.onDeleted(); };
    this.form.editorComponent = this;

    // # Get repo
    if (!this.repo) {
      if (this.repoName) {
        this.repo = this.dataServ.getRepo(this.repoName);
      } else {
        this.repo = this.model.defaultRepo;
      }
      if (!this.repo) {
        this.loggerServ.error('Form', 'Repository not set for ' + this.model.name);
      }
    }

    const hasKey = this.value && this.value[this.model.primaryKey];

    if (!hasKey || this.forceIsNew) {
      this.isNew = true;
    } else {
      this.isNew = false;
    }

    console.log(this.value, this.isNew);
    // console.log(this.form);

    // # Prepare contexts
    let context: Context;
    if (this.context) {
      context = this.context.clone('editor');
    } else {
      context = this.contextServ.createFromGlobalContext('editor.component');
    }
    this.form.context = context;

    // this.isLoading = false;
    // this.form.context.setValue('form', 'isNew', this.isNew);
    this.form.context.setValueObject('editor', () => this);
    this.form.context.setValueObject('ogValues', this.value);

    // # Get title
    // console.log(this);
    this.updateTitle();

    // console.log(this.isNew);
    this.isLoading = false;
  }

  updateTitle(arg?: any) {
    /*this.form.context.printState();
    this.form.context.print();
    console.log(this);*/
    // console.log(this);
    if (this.title) { return; }

    let defaultTitle = this.model.singular ? this.model.singular.toLowerCase() : 'dato';
    if (this.isNew) {
      defaultTitle = 'Crear ' + defaultTitle;
    } else {
      defaultTitle = 'Editar ' + defaultTitle;
    }

    // if (this.model.titles.hasTitles()) {
    this.title = this.model.titles.getTitle(this.form.context, defaultTitle, arg);
    // }
    /*if (!this.title) {
      if (this.isNew) {
        this.title = this.model.titles.getTitle('createHeader', this.form.context, 'Crear ' + this.model.singular);
      } else {
        this.title = this.model.titles.getTitle('editHeader', this.form.context, 'Editar ' + this.model.singular);
      }
    }*/
  }

  async cancel() {
    if (this.form && this.form.ngFormGroup.dirty) {
      if (!await this.uiServ.preguntaAlerta('Se perderán los datos que haya ingresado, ¿Desea continuar?')) {
        return;
      }
    }
    await this.modalCtrl.dismiss(this, 'cancel');
  }

  private onSaved() {
    this.modalCtrl.dismiss(this, 'saved');
  }

  private onDeleted() {
    this.modalCtrl.dismiss(this, 'deleted');
  }
}
