import { Component, OnInit, Input } from '@angular/core';
import { Control } from '../../Control';
import { Form } from '../../Form';
import { NumberCtrlArgs } from './args';
import { AbsControlComponent } from '../AbsControlComponent';

@Component({
  selector: 'app-number',
  templateUrl: './number.component.html',
  styleUrls: ['./number.component.scss'],
})
export class NumberComponent extends AbsControlComponent implements OnInit {
  @Input() control: Control;
  @Input() form: Form;
  args: NumberCtrlArgs;
  hasFocus = false;

  constructor() {
    super();
  }

  ngOnInit() {
    this.setupAbsControl(this.createNumberFieldRequirements());
    this.args = this.control.args as NumberCtrlArgs;
    if (!this.args) {
      this.args = new NumberCtrlArgs();
    }
  }

  focusChange(state: boolean) {
    this.hasFocus = state;
  }
}
