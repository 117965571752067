// tslint:disable: variable-name
import { Model } from '../../../lib/data/model';
import { Seguimientos } from './seguimientos';
import { UsuarioPrecios } from './usuarioPrecios';

@Model({ name: 'Aprobaciones', primaryKey: 'id', restEndpoint: 'precios/aprobaciones/' })
export class Aprobaciones {
  id: number;
  seguimiento: number | Seguimientos;
  usuario_comite: number | UsuarioPrecios;
  fecha_alta: string;
  comentarios: string;
  aprobado = false;
  atendido = false;
  usuario_creador_nombre: string;
}

