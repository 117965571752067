import { Component, OnInit, Input } from '@angular/core';
import { Control } from '../../Control';
import { Form } from '../../Form';
import { AbsControlComponent } from '../AbsControlComponent';

@Component({
  selector: 'app-text',
  templateUrl: './text.component.html',
  styleUrls: ['./text.component.scss'],
})
export class TextComponent extends AbsControlComponent implements OnInit {
  @Input() control: Control;
  @Input() form: Form;

  constructor() {
    super();
  }

  ngOnInit() {
    this.setupAbsControl(this.createTextFieldRequirements());
  }


}
