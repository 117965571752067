import { Injectable } from '@angular/core';
import { IonicStorageRepository } from '../data/ionic.repository';
import { Model } from '../data/model';
import { IRepository } from '../data/repository';
import { DataService } from './data.service';

@Model({ name: 'FrontendData', primaryKey: 'id' })
export class FrontendData {
  _dirty?= true;
  usuario?: number;
  id?: number;
  tipo?: string;
  sub_tipo?: string;
  objeto?: any;
}


@Injectable({
  providedIn: 'root'
})
export class FrontendDataService {
  cacheInLocalStorage = true;
  defaultCaching = true;
  endpoint = 'api/sistema/frontend-data/';
  frontendRepo: IRepository<FrontendData>;
  backendRepo: IRepository<FrontendData>;
  negDataCounter = -1;

  constructor(
    private dataSrv: DataService
  ) { }

  async setup() {
    const negCounter = localStorage.getItem('frontEndDataCounter');
    if (negCounter) {
      this.negDataCounter = parseInt(negCounter, 10);
    }

    const model = this.dataSrv.getModelFromClass(FrontendData);
    this.frontendRepo = new IonicStorageRepository(model);
    await this.frontendRepo.setup();
    const backModel = this.dataSrv.getModel('sistema_FrontendData');
    this.backendRepo = backModel.defaultRepo;

    try {
      const res = await this.backendRepo.query();
      this.frontendRepo.clear();
      for (const resObj of res) {
        await this.frontendRepo.upsert(resObj);
      }
    } catch (err) {
      console.error(err);
    }
  }

  async get(tipo: string, subtipo?: string, defaultData?: FrontendData, cache?: boolean) {
    const qryPrms: FrontendData = { tipo };
    // tslint:disable-next-line: no-string-literal
    if (subtipo) { qryPrms.sub_tipo = subtipo; }
    const found = await this.frontendRepo.query(qryPrms);
    if (found.length) {
      return found[0];
    } else {
      return defaultData;
    }
  }

  async getAll(tipo: string, subtipo?: string, cache?: boolean): Promise<FrontendData[]> {
    const qryPrms: FrontendData = { tipo };
    // tslint:disable-next-line: no-string-literal
    if (subtipo) { qryPrms.sub_tipo = subtipo; }
    return await this.frontendRepo.query(qryPrms);
  }

  private getNextFrontendId(): number {
    const numb = this.negDataCounter--;
    localStorage.setItem('frontEndDataCounter', numb.toString());
    return numb;
  }

  async save(data: FrontendData) {
    try {
      const newData = await this.backendRepo.upsert(data);
      if (newData) {
        data = newData;
      }
    } catch (err) {
      if (!data.id) {
        data.id = this.getNextFrontendId();
      }
      // TODO save this data later when online
      console.error(err);
    }
    try {
      await this.frontendRepo.upsert(data);
    } catch (err) {
      console.error(err);
    }
  }

  async delete(data: FrontendData) {
    try {
      await this.backendRepo.delete(data);
    } catch (err) {
      console.error(err);
    }
    try {
      await this.frontendRepo.delete(data);
    } catch (err) {
      console.error(err);
    }
  }


}
