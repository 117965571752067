<ion-list *ngIf="!isLoading" class="formContainer">
  <ion-item *ngIf="form.title && !form.hideTitle">
    <h1>{{form.title}}</h1>
  </ion-item>
  <ion-item *ngIf="!form.title && !form.hideTitle">
    <h1 *ngIf="isNew">Crear {{model.singular}}</h1>
    <h1 *ngIf="!isNew">Editar {{model.singular}}</h1>
  </ion-item>

  <form [formGroup]="ngForm" class="formCtr">

    <ng-container *ngFor="let control of form.childControls; let i = index">
      <div [hidden]="form.computeIsControlHidden(control)">
        <ng-template #dynamic></ng-template>
        <app-validator-error [control]="control"></app-validator-error>
      </div>
    </ng-container>


  </form>

</ion-list>

<div *ngIf="form && !form.readonly && !form.parent" class="formActions">
  <ion-row>
    <ion-col *ngFor="let act of form.actions">
      <ion-button expand="full" [color]="act.color" (click)="act.onClick(this, $event)">
        <ion-icon [name]="act.icon" *ngIf="act.icon"></ion-icon>&nbsp;
        {{act.text}}
      </ion-button>
    </ion-col>

    <ion-col *ngIf="isModal">
      <ion-button color="medium" expand="full" (click)="cancel()" [disabled]="form.disabled">
        <ion-icon name="alert-circle-outline"></ion-icon>&nbsp;{{form.cancelText}}
      </ion-button>
    </ion-col>
    <ion-col *ngIf="!form.disableDelete && !isNew">
      <ion-button color="danger" (click)="delete()" expand="full" [disabled]="form.disabled">
        <ion-icon name="close-circle-outline"></ion-icon>&nbsp;{{form.deleteText}}
      </ion-button>
    </ion-col>
    <ion-col *ngIf="canSave()">
      <ion-button type="submit" color="success" expand="full" [disabled]="form.disabled" (click)="save()">
        <ion-icon name="checkmark-circle-outline"></ion-icon>&nbsp;{{form.saveText}}
      </ion-button>
    </ion-col>
  </ion-row>
</div>