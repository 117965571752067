import { IControlArgs } from '../../Control';

export class ObjectArrayCtrlArgs implements IControlArgs {
  suffix: string;
  count = 0;
  min = 0;
  max: number;
  model: string;
  carousel?: boolean;

  constructor(other?: Partial<ObjectArrayCtrlArgs>) {
    if (other) {
      Object.assign(this, other);
    }
  }
}

window['ObjectArrayCtrlArgs'] = ObjectArrayCtrlArgs;
