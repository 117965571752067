import { Injectable, EventEmitter } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { AlertController, ModalController, LoadingController, NavController, ToastController } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import { ActivatedRoute } from '@angular/router';
import { Observable, fromEvent, Subscription } from 'rxjs';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { FrontendData, FrontendDataService } from './frontend-data.service';
import { IUiService } from './ui.service.interface';
import { RuntimeServices } from './runtimeServices';

export class UiServiceSettings {
  appTitle = 'AppTittle';
  darkThemeClass = '';
  useDarkTheme = false;
  appBigLogo = '';
  appSmallLogo = '';
  lightModeStatusBar = '';
  darkModeStatusBar = '';
}

// Opcioens de usuario
export class UiUserSettings extends FrontendData {
  objeto: {
    tema: string;
    mostrar_noticias: boolean;
    agrupar_siempre_menus: boolean;
  };
  constructor() {
    super();
    this.objeto = {
      tema: null,
      agrupar_siempre_menus: false,
      mostrar_noticias: true
    };
  }
}

export class UIThrowableError {
  constructor(
    public mensaje: string,
    public type?: 'error' | 'alert',
    public dialogType?: 'alert' | 'toast'
  ) { }
}

@Injectable({
  providedIn: 'root'
})
export class UiService implements IUiService {
  modalClasses: { [className: string]: any; } = {};
  private textoDeCarga = new EventEmitter<string>();
  private loadingDlg: HTMLIonLoadingElement;
  get darkMode(): boolean {
    if (this.themeOverride) {
      return this.darkModeOverride;
    }
    return this.darkModeEnabled;
  }
  get isOverridingTheme(): boolean {
    return this.themeOverride;
  }
  private darkModeEnabled = false;
  private themeOverride = false;
  private darkModeOverride = false;
  setupSettings: UiServiceSettings;
  userSettings: UiUserSettings;

  constructor(
    private alertCtrl: AlertController,
    private modalCtrl: ModalController,
    private navCtrl: NavController,
    private toastCtrl: ToastController,
    private statusBar: StatusBar,
    private fDataServ: FrontendDataService,
    private loadingCtrl: LoadingController) {
    const mqList = window.matchMedia('(prefers-color-scheme: dark)');
    mqList.addEventListener('change', (ev) => {
      // console.log(ev);
      this.darkModeEnabled = ev.matches;
      this.onThemeChanged();
    });
    this.darkModeEnabled = mqList.matches;
    RuntimeServices.uiServ = this;
  }

  async setup(settings: UiServiceSettings) {
    // this.darkMode = false;
    this.setupSettings = settings;
    // this.userSettings = await this.fDataServ.get('ui-user-settings', null, new UiUserSettings()) as UiUserSettings;

    // # Load default UI settings
    this.updateUserSettings(new UiUserSettings());
  }

  async loadUserConfigs() {
    this.userSettings = await this.fDataServ.get('ui-user-settings', null, new UiUserSettings()) as UiUserSettings;
    this.updateUserSettings(this.userSettings);
  }

  private updateUserSettings(settings: UiUserSettings) {
    this.userSettings = settings;
    const theme = this.userSettings.objeto.tema;
    if (theme && this.setupSettings.darkThemeClass) {
      this.themeOverride = true;
      this.darkModeOverride = theme === 'true';
    }
    // console.log(theme);
    this.onThemeChanged();
  }


  registrarModal(name: string, modalClass: any) {
    this.modalClasses[name] = modalClass;
    // console.log('Registrado', this.modalClasses);
  }

  setThemeMode(override: boolean, darkTheme: boolean) {
    this.themeOverride = override;
    this.darkModeOverride = darkTheme;
    this.onThemeChanged();
    if (override) {
      localStorage.setItem(this.setupSettings.appTitle + '_darkTheme', darkTheme ? 'true' : 'false');
    } else {
      localStorage.removeItem(this.setupSettings.appTitle + '_darkTheme');
    }
  }

  private onThemeChanged() {
    if (this.setupSettings) {// && this.settings.useDarkTheme && this.settings.darkThemeClass) {
      const defTheme = 'DefaultTheme';
      if (this.darkMode) {
        document.body.classList.add(this.setupSettings.darkThemeClass);
        document.body.classList.remove(defTheme);
        if (this.setupSettings.darkModeStatusBar) {
          this.statusBar.backgroundColorByHexString(this.setupSettings.darkModeStatusBar);
        }
      } else {
        document.body.classList.remove(this.setupSettings.darkThemeClass);
        document.body.classList.add(defTheme);
        if (this.setupSettings.lightModeStatusBar) {
          this.statusBar.backgroundColorByHexString(this.setupSettings.lightModeStatusBar);
        }
      }
    }
    // console.log(this.darkMode, this.settings);
  }

  obtenerModal(name: string): any {
    if (!this.modalClasses[name]) {
      this.error('No existe modal configurado para esta acción!');
    }
    return this.modalClasses[name];
  }

  async guardando(texto?: string): Promise<HTMLIonLoadingElement> {
    const text = texto ? texto : 'Guardando...';
    const dlg = await this.loadingCtrl.create({ message: texto });
    await dlg.present();
    return dlg;
  }

  async error(content: any, dialogType?: 'alert' | 'none' | 'toast', header?: string) {
    await this.clearLoadingMessage();
    console.error('Mensaje de error desplegado, contenido de error :', content);
    let mess = 'Lo sentimos, hubo un problema inesperado al realizar su petición. ';
    if (!header) { header = 'Error'; }
    // let header = 'Lo sentimos';
    if (content) {
      if (content instanceof HttpErrorResponse) {
        const httpError = content as HttpErrorResponse;
        if (httpError.error && httpError.error.mensaje) {
          mess = httpError.error.mensaje;
        } else if (httpError.error && httpError.error.detail) {
          mess = httpError.error.detail;
        } else if (httpError.error && httpError.error.non_field_errors) {
          mess = httpError.error.non_field_errors[0];
        } else if (httpError.status !== undefined) {
          switch (httpError.status) {
            case 0: mess += 'No fue posible la comunicación con el servidor (#0).'; break;
            case 400: mess = this.getBackendErrors(httpError); break;
            case 401: mess = 'No tiene permiso para realizar esa acción (#401).'; break;
            case 403: mess = 'No tiene permiso para realizar esa acción (#403).'; break;
            case 404: mess += 'El recurso que solicitó ya no existe o ha cambiado de lugar (#404).'; break;
            case 406:
              mess = 'No es  posible realizar esta acción';
              if (httpError.error && httpError.error.message) {
                mess += '. ' + httpError.error.message;
              }
              mess += ' (#406).';
              break;
            case 500:
              mess += 'Por favor inténtelo de nuevo en unos momentos, si el problema persiste notifique a sistemas. (#500).'; break;
            case 502: case 503:
              // tslint:disable-next-line: max-line-length
              mess += 'Los servicios en línea se encuentran temporalmente fuera de servicio. Por favor inténtelo de nuevo en unos momentos.'; break;
            default: mess += ' (Código ' + content.status + ')'; break;
          }
        }
      } else {
        if (typeof content === 'string') {
          mess = content;
        } else if (content instanceof UIThrowableError) {
          mess = content.mensaje;
          dialogType = content.dialogType;
          if (content.type === 'alert') {
            header = 'Alerta';
          } else if (content.type === 'error') {
            header = 'Error';
          }
        } else if (content && content.mensaje) {
          mess = content.mensaje;
          if (content && content.header) {
            header = content.header;
          }
        } else {
          if (!environment.production && content && content.message) {
            header = 'Error en entorno de desarrollo:';
            mess = content.message;
            // mess = 'Error en entorno de desarrollo:<br>' + content.message;
          }
        }
      }
    }
    if (!dialogType) { dialogType = 'alert'; }
    if (dialogType === 'alert') {
      const alert = await this.alertCtrl.create({
        header,
        message: mess,
        buttons: [{ text: 'Aceptar', role: 'cancel' }]
      });
      await alert.present();
      await alert.onDidDismiss();
    } else if (dialogType === 'toast') {
      const toast = await this.toastCtrl.create({
        message: mess, duration: 6000, color: 'danger'
      });
      await toast.present();
    }
  }

  private getBackendErrors(err: HttpErrorResponse): string {
    if (err.error) {
      if (Array.isArray(err.error)) {
        let msg = 'No se pudo realizar esta acción, el servidor regresó:<br><br>';
        // tslint:disable-next-line: forin
        for (const errmsg in err.error) {
          msg += ' - ' + err.error[errmsg] + '<br>';
        }
        return msg;
      }
    }
    return 'No se realizó la comunicación adecuada con el servidor (#400).';
  }

  async errorDev(content: any) {
    this.error(content, 'alert');
  }

  async alert(msg: string) {
    await this.clearLoadingMessage();
    const alert = await this.alertCtrl.create({
      header: '⚠ Advertencia',
      message: msg,
      buttons: [{ text: 'Aceptar', role: 'cancel' }]
    });
    await alert.present();
  }

  /** Alert in dev mode */
  async alertDev(msg: string) {
    await this.clearLoadingMessage();
    const alert = await this.alertCtrl.create({
      header: '⚠ Advertencia a desarrollador',
      message: msg,
      buttons: [{ text: 'Aceptar', role: 'cancel' }]
    });
    await alert.present();
  }

  async pregunta(cuestion: string): Promise<boolean> {
    const dlg = await this.alertCtrl.create({
      message: cuestion,
      buttons: [
        { text: 'Cancelar', role: 'cancel' },
        { text: 'Aceptar', role: 'ok' }]
    });
    await dlg.present();
    const result = await dlg.onDidDismiss();
    if (result.role === 'ok') {
      return true;
    } else {
      return false;
    }
  }

  async preguntaAlerta(cuestion: string): Promise<boolean> {
    const dlg = await this.alertCtrl.create({
      header: '⚠ Advertencia',
      message: cuestion,
      buttons: [
        { text: 'Cancelar', role: 'cancel' },
        { text: 'Aceptar', role: 'ok' }]
    });
    await dlg.present();
    const result = await dlg.onDidDismiss();
    if (result.role === 'ok') {
      return true;
    } else {
      return false;
    }
  }

  async ok(texto?: string, title?: string) {
    await this.clearLoadingMessage();
    const msg = texto ? texto : 'Petición procesada correctamente';
    const gif = await this.alertCtrl.create({ message: msg, header: title || '✔', buttons: [{ text: 'Aceptar', role: 'ok' }] });
    await gif.present();
    await gif.onDidDismiss();
  }

  async askInput(text: string, placeholder?: string, type?: 'date' | 'email' | 'number' | 'password' | 'search' | 'tel' | 'text' | 'url' | 'time' | 'week' | 'month' | 'datetime-local' | 'checkbox' | 'radio' | 'textarea'): Promise<string> {
    if (!type) {
      type = 'textarea';
    }
    const dlg = await this.alertCtrl.create({
      message: text,
      inputs: [
        { name: 'input', type, placeholder }
      ],
      buttons: [
        { text: 'Cancelar', role: 'cancel' },
        { text: 'Aceptar', role: 'ok' }]
    });
    await dlg.present();
    const result = await dlg.onDidDismiss();
    if (result.role === 'ok') {
      return result.data.values.input;
    } else {
      return undefined;
    }
  }

  async enContruccion() {
    await this.clearLoadingMessage();
    await this.alert('Esta funcionalidad estará disponible próximamente');
  }

  /*async modal(componentClass: any, props: {}) {
    const modal = await this.modalCtrl.create({
      component: componentClass,
      componentProps: props
    });
    modal.present();
    return await modal.onDidDismiss();
  }*/

  wait(ms: number): Promise<void> {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, ms);
    });
  }

  async loadingMessage(message: string) {
    if (!this.loadingDlg) {
      const text = message ? message : 'Cargando...';
      this.loadingDlg = await this.loadingCtrl.create({ message: text });
      await this.loadingDlg.present();
      // console.log('MSG-CREATED-WITH', message);
    } else {
      // console.log('MSG-REUSED-WITH', message);
      this.loadingDlg.message = message;
    }
  }

  async clearLoadingMessage() {
    if (this.loadingDlg) {
      try {
        this.loadingDlg.dismiss();
        await this.loadingDlg.onDidDismiss();
      } catch (err) {
        console.error(err);
      }
      this.loadingDlg = null;
      // console.log('MSG-Destroyed');
    }
  }

  async toastAlert(message: string, closable: boolean, duration: number, position: 'top' | 'middle' | 'bottom') {
    if (!duration) {
      const toast = await this.toastCtrl.create({
        message, position, duration, buttons: [{
          text: 'Cerrar', role: 'cancel'
        }]
      });
      await toast.present();
    } else {
      const toast = await this.toastCtrl.create({
        message, position, duration
      });
      await toast.present();
    }
  }

  private async changeDarkMode(enabled: boolean) {
    this.darkModeEnabled = enabled;
    // localStorage.setItem('_darkMode', enabled);
    console.log('TEMA CAMBIADO');
    document.body.classList.toggle('dark', enabled);
  }
}

export class WidthDetector {
  private resizeObservable: Observable<Event>;
  private resizeSubscription: Subscription;
  isLess: boolean;
  isMore: boolean;

  constructor(public width: number) {
    this.resizeObservable = fromEvent(window, 'resize');
    this.resizeSubscription = this.resizeObservable.subscribe(evt => {
      this.isLess = document.body.offsetWidth < this.width;
      this.isMore = !this.isLess;
      // console.log('event: ', evt);
    });
    this.isLess = document.body.offsetWidth < this.width;
    this.isMore = !this.isLess;
  }

  destroy() {
    this.resizeSubscription.unsubscribe();
  }
}
