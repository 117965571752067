import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Tools } from './tools';
import { File as NativeFile } from '@ionic-native/file/ngx';

export class LoggerConfig {
  public outputToBrowser = true;
  public outputToFile = true;
  public flushBufferInterval = 1000 * 5;
  public appFolder = 'cariapp';
}

@Injectable({
  providedIn: 'root'
})
export class LoggerService {
  public config: LoggerConfig;
  private fileOutputBuffer: string[] = [];
  private isWriting = false;
  public currentFileName = '';
  private http: HttpClient;
  private file: NativeFile;

  constructor(private injector: Injector) {
  }

  async setup(config: LoggerConfig): Promise<any> {
    this.http = this.injector.get(HttpClient);
    this.file = this.injector.get(NativeFile);
    this.config = config;
    if (!this.config.outputToFile) {
      setTimeout(async () => {
        await this.flushBuffer();
      }, this.config.flushBufferInterval);
    }
    this.info('Logger', 'Bitácora inicializada');

    this.config.outputToFile = false;
    return true;
  }

  info(loc: string, texto: string, objeto?: any) {
    this.internalWrite('[INFO]', loc, texto, objeto);
  }

  error(loc: string, texto: string, objeto?: any) {
    this.internalWrite('[ERROR]', loc, texto, objeto);
  }

  warn(loc: string, texto: string, objeto?: any) {
    this.internalWrite('[WARN]', loc, texto, objeto);
  }

  private internalWrite(tipo: string, loc: string, texto: string, objeto?: any) {
    let entry = '[' + (new Date().toLocaleTimeString()) + ']';
    entry += '[' + loc + ']';
    entry += tipo + ' ';
    entry += texto;
    try {
      if (objeto !== undefined) { entry += ' ' + JSON.stringify(objeto); }
    } catch (err) {
      console.log(err);
      this.error('Logger', 'No se pudo serializer objeto para error, de texto:' + texto);
    }
    entry += '\n';
    if (!this.config) {
      if (tipo === '[ERROR]') {
        console.error(entry);
      } else {
        console.log(entry);
      }
    } else {
      if (this.config.outputToBrowser) {
        if (tipo === '[ERROR]') {
          console.error(entry);
        } else {
          console.log(entry);
        }
      }
      if (this.config.outputToFile) {
        this.fileOutputBuffer.push(entry);
      }
    }
  }

  private async flushBuffer() {
    if (this.fileOutputBuffer.length) {
      try {
        await this.makeSureFolderExist('/logs');
        let textToWrite = '';
        // console.log('Writing to file ', textToWrite);
        this.fileOutputBuffer.forEach(entry => { textToWrite += entry; });
        this.fileOutputBuffer.length = 0;
        const filepath = this.config.appFolder + '/logs/' + Tools.Fecha_YYYY_MM_DD(new Date()) + '.txt';
        await this.appendTextToFile(filepath, textToWrite);
      } catch (err) {
        console.error(err);
      }
    }
    setTimeout(async () => {
      await this.flushBuffer();
    }, this.config.flushBufferInterval);
  }

  private makeSureFolderExist(dir: string): Promise<boolean> {
    const route = this.config.appFolder + dir;
    return new Promise<boolean>((resolve, reject) => {
      this.file.createDir(this.file.externalRootDirectory, route, true)
        .then(
          () => { resolve(true); },
          (err) => { console.error('REJ: makeSureFolderExist'); reject(err); }
        )
        .catch((err) => {
          console.error('CATCH: makeSureFolderExist');
          reject(err);
        });
    });
  }

  private appendTextToFile(filename: string, content: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.file.createFile(this.file.externalRootDirectory, filename, true)
        .then(
          () => {
            this.file.writeFile(this.file.externalRootDirectory, filename, content, { append: true, replace: false })
              .then(
                () => {
                  resolve();
                },
                (err) => {
                  console.error('REJ/write: appendTextToFile');
                  reject(err);
                })
              .catch((err) => {
                console.error('CATCH/write: appendTextToFile');
                reject(err);
              });
          },
          (err) => {
            console.error('REJ/create: appendTextToFile');
            reject(err);
          })
        .catch((err) => {
          console.error('CATCH/create: appendTextToFile');
          reject(err);
        });
    });
  }
}
