
export const ComponentRegistry: any = {};

/** Decoration that needs to be added into models for them to be used in the orm service  */
export function ExposedComponent(name: string) {
  // tslint:disable-next-line: only-arrow-functions
  return function <T extends new (...args: any[]) => {}>(constructor: T) {

    ComponentRegistry[name] = constructor;

     // console.log(name, constructor);

    /*params.ctor = constructor;
    constructor['_modelDescriptor'] = params.name;
    // constructor['_storeName'] = params.storeName;
    const mdl = new ModelDescriptor(params);
    mdl.name = params.name;
    // tslint:disable-next-line: no-string-literal
    constructor['_modelDescriptor'] = mdl;
    Object.assign(mdl, params);
    ModelRegistry[params.name] = mdl;*/
  };
}