// tslint:disable: align
import * as deepMerge from 'deepmerge';
import { UIThrowableError } from '../services/ui.service';
// import * as get from 'get-value';

const DEBUG_LOG = false;
const CompiledCache: { [name: string]: (ctx: Context, values: any, arg: any) => any; } = {};

export declare type ValueCalcFunc = (context?: Context, vals?: any, arg?: any) => any;

export declare type ValueBoolFunc = (context?: Context, vals?: any, arg?: any) => boolean;

/** Container for values and parameters to be used by components. Conditions can be evaluated in the context. */
export class Context {

  /** A list of values to use in conditionals and others */
  values: { [nameSpace: string]: { [valueName: string]: any; }; } = {};

  /** A list of values to use when filtering data */
  // queryParams: { [name: string]: any; } = {};

  constructor(
    /** Unique ID in the entire app assigned to this context. Only required for globally identifiable contexts. */
    public id: string,

    /** Contexts to use as base for this new context */
    contexts?: Context[],

    /** Used by the context service, type of context, i.e. 'client', 'user', etc. */
    public type?: string,

    /** Global contexts are added to all components. */
    public readonly isGlobal?: boolean
  ) {
    if (contexts) {
      contexts.forEach(ctx => {
        this.merge(ctx);
      });
    }
  }

  clone(customId?: string) {
    return new Context(customId || this.id, [this], this.type, this.isGlobal);
  }

  merge(ctx: Context) {
    // this.queryParams = deepMerge(this.queryParams, ctx.queryParams);
    this.values = deepMerge(this.values, ctx.values);
    this.type = ctx.type;
  }

  /*getValue(varPath: string, defaultVal?: any): any {
    const res = get(this.values, varPath);
    if (res === undefined) {
      return defaultVal;
    }
    return res;
  }*/

  getValues(nameSpace: string): any {
    if (this.values[nameSpace] === undefined) {
      return undefined;
    }
    return this.values[nameSpace];
  }

  setValue(nameSpace: string, varName: string, value: any) {
    if (this.values[nameSpace] === undefined) {
      this.values[nameSpace] = {};
    }
    this.values[nameSpace][varName] = value;
  }

  setValueObject(nameSpace: string, object: any) {
    if (this.values[nameSpace] === undefined) {
      this.values[nameSpace] = {};
    }
    this.values[nameSpace] = object;
  }

  ensureNamespace(nameSpace: string) {
    if (this.values[nameSpace] === undefined) {
      this.values[nameSpace] = {};
    }
  }

  /* setQueryParam(varName: string, value: any) {
    this.queryParams[varName] = value;
  }*/

  execute(expr: string | ValueBoolFunc | ValueCalcFunc, defaultValue?: any, arg?: any, debug?: boolean): any {
    if (!expr) {
      console.error('Invalid expression ', expr);
      return false;
    }
    let result = defaultValue;
    try {
      if (typeof expr === 'function') {
        result = expr(this, this.values, arg);
      }
      else if (typeof expr === 'string') {
        if (!CompiledCache[expr]) {
          // return Function('"use strict";return (' + this + ')')();
          // tslint:disable-next-line: no-eval
          CompiledCache[expr] = eval('(ctx, val, arg) => { ' + expr + ' }');
          // console.log(CompiledCache);
        }
        result = CompiledCache[expr](this, this.values, arg);
      }
    } catch (err) {
      console.error('Error en código ' + expr + ' contenido: ', err, ' contexto:', this);
    }
    if (debug || DEBUG_LOG) { console.log('Exec ', expr, ' => ', result); }
    return result;
  }

  printState() {
    const copy = JSON.parse(JSON.stringify(this));
    console.log(copy);
  }

  print() {
    console.log(this);
  }

  /** Buscar valor dentro de contexto usando rutas JS */
  findValue(path: string): any {
    const pathAr = path.split('.');
    let value = this.values;
    while (pathAr.length) {
      if (value[pathAr[0]]) {
        value = value[pathAr[0]];
        pathAr.splice(0, 1);
      } else {
        break;
      }
    }
    return value;
    // return get(this.values, path);
  }



  /*interpolateString(template: string): string {
    //  return this.execute(template, '', null, )
    console.log(template);
    return template.replace(/\{\{\s*([^}\s]+)\s*\}\}/g, (_, token) => this.execute(token, '', null, true));
  }*/

}
