// tslint:disable: variable-name
import { Model } from '../../../lib/data/model';
import { Form } from 'src/app/lib/forms/Form';
import { SelectModalCtrlArgs } from 'src/app/lib/forms/controls/selectmodal/selectmodal.args';
import { CrudSettings } from 'src/app/lib/data/CrudSettings';

@Model({
  name: 'Producto',
  primaryKey: 'id',
  restEndpoint: 'precios/producto/',
  singular: 'producto',
  plural: 'productos',
  singularArticle: 'el',
  pluralArticle: 'los',
  forms: {
    default: () => {
      return new Form({
        title: null,
        childControls: [
          { field: 'nombre', label: 'Nombre', type: 'textarea', max: 100, required: true },
          { field: 'medida', label: 'Presentación', type: 'selectModal', required: true, args: new SelectModalCtrlArgs({ model: 'Presentacion', titleProperty: 'nombre', allowAdd: true }) }
        ]
      });
    }
  },
  cruds: {
    default: new CrudSettings({
      labelProperty: 'nombre',
    })
  }
})
export class Producto {
  id: number;
  nombre: string;
  medida: number;
}
