import { Injectable } from '@angular/core';
import { ToastController, NavController, AlertController } from '@ionic/angular';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { NotificationMessage, NotificationType } from '../data/NotificationMessage';
import { FcmService } from './fcm.service';
import { LoggerService } from './logger.service';
import { VibrationService } from './vibration.service';
import { WebapiService } from './webapi.service';

export class NotificationServiceSettings {
  ignoreWebsocketMessages: boolean;
  requestUnreadURL: string;
}

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  notificationArrived: Subject<NotificationMessage> = new Subject<NotificationMessage>();
  // tslint:disable-next-line: jsdoc-format
  /** Ignores the distribution of messages in the app when ws_channel comes in the data and the
   * notification arrives in foreground mode.
   */
  settings: NotificationServiceSettings;
  unread = 0;

  constructor(
    private fcmServ: FcmService,
    private vibrationServ: VibrationService,
    private toastCtrl: ToastController,
    private navCtrl: NavController,
    private logServ: LoggerService,
    private alertCtrl: AlertController,
    private webapiServ: WebapiService,
  ) { }

  async setup(settings: NotificationServiceSettings) {
    this.settings = settings;
    this.fcmServ.notificationArrived.subscribe(async (msg) => await this.receiveNotification(msg));
    await this.updateNotificationCount();
  }

  private async receiveNotification(msg: NotificationMessage) {
    if (!environment.production) {
      console.log('NEW NOTIFICATION ', msg);
    }
    this.logServ.info('NotificationServ', 'Notification received', msg);
    await this.updateNotificationCount();

    const skipWsNotification = this.settings.ignoreWebsocketMessages && msg.data.ws_channel && !msg.wasTapped;

    if (!skipWsNotification) {
      this.notificationArrived.next(msg);
    }

    if (msg.cancelAlerts) { return; }

    if (msg.wasTapped) {

      // OS push notification

      if (msg.type === NotificationType.TOAST_URL ||
        msg.type === NotificationType.ALERT_URL) {
        // this.vibrationServ.vibrate();
        await this.navCtrl.navigateForward(msg.url);
      }

    } else {

      // On UI notification
      if (msg.data) {
        const msgData = msg.data;

        if (msgData.type === NotificationType.TOAST) {
          this.vibrationServ.vibrate();
          await this.showToast(msgData.title, msgData.body);
        }

        if (msgData.type === NotificationType.TOAST_URL) {
          this.vibrationServ.vibrate();
          await this.showToast(msgData.title, msgData.body, async () => {
            await this.navCtrl.navigateForward(msgData.url);
          });

        }

        if (msgData.type === NotificationType.ALERT_URL) {
          this.vibrationServ.vibrate();
          await this.showAlert(msgData.title, msgData.body, async () => {
            await this.navCtrl.navigateForward(msgData.url);
          });

        }
      }
    }
  }

  async showToast(title: string, msg: string, okPromise?: () => void) {
    const toast = await this.toastCtrl.create({
      header: title,
      message: msg,
      position: 'top',
      // duration: undefined,
      buttons: [{ text: 'OK', role: 'cancel' }]
    });
    if (okPromise) {
      toast.buttons = [{ text: 'Ver', role: 'ok' }, { text: 'Cerrar', role: 'cancel' }];
    }
    await toast.present();
    const data = await toast.onDidDismiss();
    if (data.role === 'ok') {
      await okPromise();
    }
  }

  async showAlert(title: string, msg: string, okPromise?: () => void) {
    const toast = await this.alertCtrl.create({
      header: title,
      message: msg,
      // duration: undefined,
      buttons: [{ text: 'OK', role: 'cancel' }]
    });
    // await toast.onDidDismiss();
    if (okPromise) {
      toast.buttons = [{ text: 'Ver', role: 'ok' }, { text: 'Cerrar', role: 'cancel' }];
    }
    await toast.present();
    const data = await toast.onDidDismiss();
    if (data.role === 'ok') {
      await okPromise();
    }
  }

  /** Distributes a notification as if it was received from the network */
  injectNotification(message: NotificationMessage) {
    this.receiveNotification(message).then(() => { }).catch((err) => console.error(err));
  }

  async updateNotificationCount() {
    this.unread = await this.getUnreadCount();
  }

  async getUnreadCount(): Promise<number> {
    try {
      const res = await this.webapiServ.get(this.settings.requestUnreadURL);
      if (res && res.count) {
        return res.count;
      }
    } catch (e) {

    }
    return 0;
  }
}
