import { Component, Injector, Input, OnInit } from '@angular/core';
import { NavController, MenuController } from '@ionic/angular';
import { Context } from '../../data/context';
import { ConfigService } from '../../services/config.service';
import { ContextService } from '../../services/context.service';
import { MainMenuService } from '../../services/main-menu.service';
import { MenuGroup, MenuOption } from '../menu';

@Component({
  selector: 'app-menu-compact',
  templateUrl: './menu-compact.component.html',
  styleUrls: ['./menu-compact.component.scss'],
})
export class MenuCompactComponent implements OnInit {
  @Input() context: Context;

  constructor(
    public mainMenuServ: MainMenuService,
    private injector: Injector,
    private navCtrl: NavController,
    private menuCtrl: MenuController,
    private contextServ: ContextService,
    public configServ: ConfigService) {
  }

  ngOnInit() {
    if (!this.context) {
      this.context = this.contextServ.createFromGlobalContext('menu');
    }
  }

  async openOption(opc: MenuOption) {
    // console.log(opc);
    if (opc.onClick) {
      await opc.onClick(this.injector);
      this.mainMenuServ.toggle();
    }
    if (opc.route) {
      await this.navCtrl.navigateRoot(opc.route);//, { replaceUrl: true, skipLocationChange: true });
      this.mainMenuServ.toggle();
    }
  }

  isOptionActive() {

  }

  isOptionHidden(opc: MenuOption): boolean {
    if (opc.hiddenByAuth) {
      return true;
    }
    if (opc.hideWhen) {
      const result = this.context.execute(opc.hideWhen);
      // console.log('isOptionHidden ' + opc.title, result);
      return result;
    }
    return false;
  }

  isOptionDisabled(opc: MenuOption): boolean {
    if (opc.disableWhen) {
      return this.context.execute(opc.disableWhen);
    }
    return false;
  }

  close(opc: MenuGroup) {
    if (opc.onClose) {
      if (opc.onClose(opc)) {
        return;
      }
    }
    const menu = this.mainMenuServ.settings.mainMenu.groups;
    const indx = menu.indexOf(opc);
    menu.splice(indx, 1);
  }

}
