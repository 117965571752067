import { Component, Input, OnInit } from '@angular/core';
import { FormComponent, ICompositeFormControl } from 'src/app/lib/components/form/form.component';
import { Context } from 'src/app/lib/data/context';
import { ModelDescriptor } from 'src/app/lib/data/model';
import { IRepository, IRepositoryBase } from 'src/app/lib/data/repository';
import { DataService } from 'src/app/lib/services/data.service';
import { LoggerService } from 'src/app/lib/services/logger.service';
import { UiService } from 'src/app/lib/services/ui.service';
import { Control } from '../../Control';
import { Form } from '../../Form';
import { AbsControlComponent } from '../AbsControlComponent';
import { ObjectCtrlArgs } from './args';

window['ObjectCtrlArgs'] = ObjectCtrlArgs;

@Component({
  selector: 'app-object',
  templateUrl: './object.component.html',
  styleUrls: ['./object.component.scss'],
})
export class ObjectComponent extends AbsControlComponent implements OnInit, ICompositeFormControl {
  @Input() control: Control;
  @Input() form: Form;
  args: ObjectCtrlArgs;
  helpText: string;
  isNew = true;

  childModel: ModelDescriptor;
  childForm: Form;
  childRepo: IRepositoryBase;
  childValue: any;

  childFormComponent: FormComponent;

  constructor(
    private dataServ: DataService,
    private uiServ: UiService,
    private loggerServ: LoggerService
  ) {
    super();
  }

  ngOnInit() {
    if (this.control.skip) {
      return;
    }

    this.setupAbsControl();
    this.args = this.control.args as ObjectCtrlArgs;
    if (!this.args) {
      this.uiServ.error('Argumentos de campo "' + this.control.field + '" no establecidos en metadata!', 'alert', 'Error de metadata');
      return;
    }

    // Create child values
    this.childModel = this.dataServ.getModel(this.args.model);
    this.childForm = this.childModel.createDefaultForm(); // TODO set this to configurable ?
    this.childForm.parent = this.control;
    this.childForm.hideTitle = true;

    // Value
    // const isActive = this.form.computeIsControlHidden(this.control);
    if (!this.control.ngControl.value) {
      this.control.ngControl.patchValue({});
      this.isNew = true;
    } else {
      this.isNew = false;
    }
    this.childValue = this.control.ngControl.value;
    // const value = this.control.ngControl.value || {};
    // const val = this.control.ngControl.value;
    // console.log(this.control.field, val);

    // Context
    const childContext = new Context('child_' + this.control.field);
    childContext.merge(this.form.context);
    // childContext.setValueObject('value', val);
    // childContext.setValueObject('parent', val);
    this.childForm.context = childContext;
  }

  childFormCreated(form: FormComponent) {
    // this.loggerServ.info('Form', 'Linked child form "' + this.control.field + '" to parent form.');
    this.form.ngFormGroup.setControl(this.control.field, form.ngForm);
    form.ngForm.setParent(this.form.ngFormGroup);

    // Append child form as a child
    this.control.childControls = [this.childForm];

    form.form.onControlValueChanged.subscribe((input) => {
      this.form.childChanged(input);
    });
  }

  collectValue() {
    if (this.control.skip) {
      return undefined;
    }
    if (this.control.notSaveWhen && this.form.context.execute(this.control.notSaveWhen)) {
      return undefined;
    }
    const values = {};
    for (const ctrl of this.childForm.childControls) {
      const ctrlValue = ctrl.ngComponent.collectValue();
      if (ctrlValue !== undefined) {
        values[ctrl.field] = ctrlValue;
      }
    }
    return values;
  }
}
