import { Component, OnInit, Input } from '@angular/core';
import { Form } from '../../Form';
import { Control } from '../../Control';
import { AbsControlComponent } from '../AbsControlComponent';
import { UiService } from 'src/app/lib/services/ui.service';

@Component({
  selector: 'app-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss'],
})
export class TextAreaComponent extends AbsControlComponent implements OnInit {
  @Input() control: Control;
  @Input() form: Form;
  autogrow = false;

  constructor(public uiServ: UiService) {
    super();
  }

  async ngOnInit() {
    // await this.autoGrowFix();
    this.setupAbsControl();
    setTimeout(() => {
      this.autogrow = true;
    }, 1000);
  }

  /* async autoGrowFix() {
    const ogValue = this.control.ngControl.value;
    await this.uiServ.wait(100);
    this.control.ngControl.setValue(' ');
    await this.uiServ.wait(100);
    this.control.ngControl.setValue(ogValue);
  }*/

  enableAutoGrow() {
    return this.control.ngControl.value && this.control.ngControl.value.length;
  }


}
