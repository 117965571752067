<ion-item #elementCtrl>
  <ion-label [class]="getLabelClass()" [position]="labelPosition">{{control.label}}</ion-label>
  <div class="readOnlyInput">
    <ion-input *ngIf="form.computeIsControlReadonly(control)" type="text" readonly="true">
      {{getReadonlyText()}}</ion-input>
  </div>
  <ionic-selectable #ionSelectableCtrl [hidden]="isLoading || form.computeIsControlReadonly(control)" [(ngModel)]="valueUI"
    [items]="selectItems"
    [isMultiple]="args.isMultiple"
    [itemValueField]="primaryKey"
    [itemTextField]="textField"
    [canSearch]="true"
    [canClear]="true"
    (onChange)="onChange($event)"
    closeButtonText="Cerrar"
    searchFailText="No hay resultados"
    addButtonText="Agregar" clearButtonText="Limpiar" searchPlaceholder="Buscar" (onSearch)="search($event)"
    headerColor="primary" closeButtonSlot="end" [canDeleteItem]="false" [canAddItem]="canAddItems()"
    [canSaveItem]="canEditItems()" (onAddItem)="onIoSelAdd($event)" (onSaveItem)="onIoSelEdit($event)"
    (onDeleteItem)="onIoSelDel($event)" [disabled]="form.computeIsControlDisabled(control)" class="customSelectStyles">
    <!-- [canDeleteItem]="args.allowEdit"  [(ngModel)]="control.valueUI" -->
    <ng-template ionicSelectableHeaderTemplate>
      <lib-header [title]="'Seleccione '+control.label" closable="true" (close)="close()" hideMenuButton="true">
      </lib-header>
    </ng-template>
    <ng-template ionicSelectableAddItemTemplate let-port="item" let-isAdd="isAdd">
      <lib-form [model]="valueModel" [repo]="valueRepo" [form]="valueForm" [value]="value"
        (saved)="onValueSaved($event, isAdd)" [isModal]="true" (canceled)="onFormCanceled()"
        (deleted)="onFormDeleted($event)">
      </lib-form>
    </ng-template>
  </ionic-selectable>
</ion-item>