import { Injectable } from '@angular/core';
import { FcmService } from 'src/app/lib/services/fcm.service';
import { Casos } from '../models/casos';
import { Observable, Subject } from 'rxjs';
import { IRepository } from 'src/app/lib/data/repository';
import { NavController, ToastController } from '@ionic/angular';
import { VibrationService } from 'src/app/lib/services/vibration.service';
import { IonicStorageRepository } from 'src/app/lib/data/ionic.repository';
import { ModelDescriptor } from 'src/app/lib/data/model';
import { NotificationMessage } from 'src/app/lib/data/NotificationMessage';

export class CasosState {
  id: number;
  status: 'none' | 'read' | 'notRead';
}

@Injectable({
  providedIn: 'root'
})
export class PreciosNotificationsService {
  onNewCaso: Observable<CasosState> = new Observable<CasosState>();
  onNewSeg: Subject<CasosState> = new Subject<CasosState>();
  casoStateRepo: IRepository<CasosState>;
  didSetup = false;

  constructor(
    private fcmServ: FcmService,
    private vibrationServ: VibrationService,
    private toastCtrl: ToastController,
    private navCtrl: NavController) {
    // this.fcmServ.notificationArrived.subscribe((msg) => this.receiveNotification(msg));
  }

  public async setup() {
    this.casoStateRepo = new IonicStorageRepository(new ModelDescriptor({ name: 'PreciosCasosState', primaryKey: 'id', ctor: CasosState }));
    await this.casoStateRepo.setup();
    this.didSetup = true;
  }

  public receiveNotification(msg: NotificationMessage) {
    console.log('NEW NOTIFICATION ON PRECIOS');
    /*if (msg.wasTapped) {
      if (msg.type === 'precios/caso') {
        this.navCtrl.navigateForward('precios');
      } else if (msg.type === 'precios/seguimiento') {
        {
          this.navCtrl.navigateForward('precios/caso/' + msg.data.casoid);
        }
      }
    } else {
      if (msg.type === 'precios/caso') {
        this.showToast('Nuevo caso asignado').catch((err) => { console.log(err); });
        this.vibrationServ.vibrate();
      }
      if (msg.type === 'precios/seguimiento') {
        this.showToast('Respuesta recibida para un caso').catch((err) => { console.log(err); });
        this.vibrationServ.vibrate();
      }
    }*/
  }

  public async syncCasos(casos: Casos[]) {
    if (!this.didSetup) { await this.setup(); }
    for (const caso of casos) {
      const state = await this.casoStateRepo.query({ id: caso.id } as CasosState);
      if (!state.length) {
        await this.casoStateRepo.upsert({ id: caso.id, status: 'none' });
      } else {
        caso._leidoStatus = state[0].status;
      }
    }
  }

  public async markAsRead(caso: Casos) {
    if (!this.didSetup) { await this.setup(); }
    await this.casoStateRepo.upsert({ id: caso.id, status: 'read' });
  }

  public async newMessage(caso: Casos) {
    await this.casoStateRepo.upsert({ id: caso.id, status: 'notRead' });
  }

  async showToast(msg: string) {
    const toast = await this.toastCtrl.create({
      message: msg,
      position: 'top',
      // duration: undefined,
      buttons: [{ text: 'OK', role: 'cancel' }]
    });
    await toast.present();
  }

}
