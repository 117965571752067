<ion-split-pane contentId="main" (ionSplitPaneVisible)="splitPaneVisibileChange($event)">
  <!--  the side menu  -->
  <ion-menu type="overlay" menuId="mainMenu" contentId="main" [disabled]="true">
    <ion-header>
      <ion-toolbar color="primary">
        <div class="menu-width-limit overToolbarContainer" style="width: 100%;">
          <img src="./assets/logos/cat-logo-mini.png" slot="start" class="header-icon" (click)="goHome()">
          <div class="header-text">
            <div class="header-title">CAT
              <span class="ver">{{version}}</span>
            </div>
            <div class="header-sub-title" [innerHtml]="getHeaderUserName()"></div>
          </div>
          <!--
          <div class="settingsButton" (click)="openOptions()">
            <ion-icon name="cog-outline"></ion-icon>
          </div>-->

        </div>
        <ion-buttons class="menu-width-limit" slot="secondary">
          <ion-button *ngIf="!webapiServ.isOnline">
            <ion-icon slot="icon-only" name="cloud-offline-outline"></ion-icon>
          </ion-button>
          <ion-button (click)="openNotifications()" id="cat3_header_notifications">
            <ion-icon slot="icon-only" name="notifications-outline"></ion-icon>
            <div class="badge" *ngIf="notificationServ.unread">{{notificationServ.unread}}</div>
          </ion-button>
          <ion-button (click)="openOptions()" id="cat3_header_options_button">
            <ion-icon slot="icon-only" name="settings-outline"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <app-menu-compact></app-menu-compact>
    </ion-content>
  </ion-menu>

  <!-- the main content -->
  <ion-router-outlet id="main" main></ion-router-outlet>
</ion-split-pane>