import { Component, OnInit, Injector, Input } from '@angular/core';
import { MainMenuService } from '../../services/main-menu.service';
import { MenuOption } from '../menu';
import { ConfigService } from '../../services/config.service';
import { NavController, MenuController } from '@ionic/angular';
import { ContextService } from '../../services/context.service';
import { Context } from '../../data/context';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {
  @Input() context: Context;

  constructor(
    public mainMenuServ: MainMenuService,
    private injector: Injector,
    private navCtrl: NavController,
    private menuCtrl: MenuController,
    private contextServ: ContextService,
    public configServ: ConfigService) {
  }

  ngOnInit() {
    if (!this.context) {
      this.context = this.contextServ.createFromGlobalContext('menu');
    }
  }

  async openOption(opc: MenuOption) {
    if (opc.onClick) {
      await opc.onClick(this.injector);
      this.mainMenuServ.toggle();
    }
    if (opc.route) {
      if (opc.opensForward) {
        this.navCtrl.navigateForward(opc.route);
      } else {
        this.navCtrl.navigateRoot(opc.route);
      }
      this.mainMenuServ.toggle();
    }
  }

  isOptionActive() {

  }

  isOptionHidden(opc: MenuOption): boolean {
    if (opc.hiddenByAuth) {
      return true;
    }
    if (opc.hideWhen) {
      const result = this.context.execute(opc.hideWhen);
      // console.log('isOptionHidden ' + opc.title, result);
      return result;
    }
    return false;
  }

  isOptionDisabled(opc: MenuOption): boolean {
    if (opc.disableWhen) {
      return this.context.execute(opc.disableWhen);
    }
    return false;
  }

}
