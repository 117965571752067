

export enum NotificationType {
  TOAST = 'TOAST',
  TOAST_URL = 'TOAST_URL',
  ALERT_URL = 'ALERT_URL',
  CUSTOM = 'CUSTOM'
}

export class NotificationMessage {
  type: NotificationType;
  tag?: string;
  title?: string;
  body?: string;
  id?: string;
  simulated?: boolean;
  data?: any = {};
  wasTapped?: boolean;
  origin?: NotificationOrigin;
  url?: string;
  cancelAlerts?: boolean;
}

export enum NotificationOrigin {
  ERROR, TEST, PUSH, WEBSOCKET
}
