import { AbstractInput, AbstractTest } from './validators/abstractValidator';
import { SelectModalCtrlArgs } from './controls/selectmodal/selectmodal.args';
import { FormControl, AbstractControl } from '@angular/forms';
import { AbsControlComponent } from './controls/AbsControlComponent';
import { Context, ValueBoolFunc, ValueCalcFunc } from '../data/context';

// tslint:disable-next-line: no-empty-interface
export interface IControlArgs { }

/** Control de un formulario */
export class Control {

  childControls?: Control[];

  /** This is the parent form */
  parent?: Control;

  public constructor(init?: Partial<Control>) {
    Object.assign(this, init);
  }

  /** Nombre del campo en el modelo */
  field: string;

  /** Texto a desplegar junto al control */
  label: string;

  /** Tipo de control */
  type: string;

  /** Texto de ayuda extra utilizado en algunos controles */
  help?: string;

  /** Minimum size/length for the value */
  min?: number;

  /** Maximum size/length for the value */
  max?: number;

  /** Block writing to control */
  readonly?: boolean;

  /** Custom arguments for the control */
  args?: IControlArgs;

  /** Prevents loading the control in the interface */
  skip?: boolean;

  // ----

  /** Si el campo se debe mostrar deshabilitado */
  disabled?: boolean;

  /** Si el campo no se debe mostrar */
  hidden?: boolean;

  /** When to disable this control */
  disableWhen?: string | ValueBoolFunc;

  /** Code to control readonly state */
  readonlyWhen?: string | ValueBoolFunc;

  /** When to hide this control */
  hiddenWhen?: string | ValueBoolFunc;
  debugHiddenWhen?: boolean;

  /** Conditions where this control should be required */
  requiredWhen?: string | ValueBoolFunc;

  /** When the control should not store a value */
  notSaveWhen?: string  | ValueBoolFunc;

  /** Replaces the value of the filed with the value from a context var */
  autoValue?: string | ValueCalcFunc;

  /** For sorting the position of the control */
  order?: number;

  // ****

  /** Reference to the current angular control editing the value of this control  */
  ngComponent?: any;

  /** If the control should have a value */
  required?: boolean;

  /** Angular abstract form control tied to this control */
  ngControl?: AbstractControl;

  /** Child control tells parent it changed its value */
  childChanged?: (control: Control) => void;

  /** Code to execute when the control changes */
  onChange?: string | ValueCalcFunc;

  /** Runtime visible state */
  __isVisible?: boolean;
  /** Runtime required state */
  __isRequired?: boolean;
  /** Runtime readonly state */
  __isReadOnly?: boolean;
  /** Runtime debugging of control */
  __debugEnabled?: boolean;
}

