import { Injectable } from '@angular/core';
import { ModelDescriptor } from '../data/model';
import { IRepositoryBase, IRepository } from '../data/repository';
import { LoggerService } from './logger.service';
import { Form } from '../forms/Form';
import { IPaginationHandler, PagedPaginationHandler, WebapiRepository } from '../data/webapi.repository';
import { WebapiService } from './webapi.service';
import { ModelCollection } from '../data/modelCollection';
import { ControlRegistryData } from '../forms/controls/ControlRegistryData';
// import { AuthService } from './auth.service';
import { RegisterCmd } from '../tools/registerCmd';
import { ModalController } from '@ionic/angular';
import { ContextService } from './context.service';
import { ComponentRegistry } from '../components/ComponentRegistry';

/** Service for app wide management of models descriptors, repositories, forms and control types. */
@Injectable({
  providedIn: 'root'
})
export class DataService {
  public static Singleton: DataService;
  modelRegistry: { [name: string]: ModelDescriptor; } = {};
  repoRegistry: { [name: string]: IRepositoryBase; } = {};
  formsRegistry: { [name: string]: () => Form; } = {};
  controlRegistry: { [name: string]: any; } = {};
  defaultPaginationHandler: IPaginationHandler;
  private serviceName = 'DataService';

  constructor(
    private logServ: LoggerService,
    // private authServ: AuthService,
    private modalCtrl: ModalController,
    private contextServ: ContextService,
    private webapiServ: WebapiService) {
    DataService.Singleton = this;
    /*this.authServ.onUserLoginChange.subscribe((state) => {
      this.clear();
    });*/
    RegisterCmd('dump_models', () => this.modelRegistry);
  }

  async setup(models: ModelCollection, controls: ControlRegistryData[]) {
    this.registerDefaultControls(controls);
    for (const model of models.models) {
      if (model._modelDescriptor) {
        const modelInfo = model._modelDescriptor as ModelDescriptor;
        await this.setModel(modelInfo);
      } else {
        this.logServ.error(this.serviceName, 'Model configured incorrectly');
      }
    }
  }

  clear() {
    this.modelRegistry = {};
    this.repoRegistry = {};
    this.formsRegistry = {};
    this.controlRegistry = {};
  }

  /** Returns a model descriptor by its global name */
  getModel(name: string): ModelDescriptor {
    if (!this.modelRegistry[name]) {
      throw Error('Model ' + name + ' not found');
    }
    return this.modelRegistry[name];
  }

  getModelFromClass(model: new () => any): ModelDescriptor {
    // tslint:disable-next-line: no-string-literal
    const modelInfo = model['_modelDescriptor'] as ModelDescriptor;
    if (!modelInfo) {
      this.logServ.error(this.serviceName, 'No ModelDescriptor found in the passed class');
      return null;
    }
    return this.getModel(modelInfo.name);
  }

  getRepo(name: string): IRepositoryBase {
    if (!this.repoRegistry[name]) {
      throw Error('Repository ' + name + ' not found');
    }
    return this.repoRegistry[name];
  }

  getModelRepo<T>(model: new () => T): IRepository<T> {
    // tslint:disable-next-line: no-string-literal
    const modelInfo = model['_modelDescriptor'] as ModelDescriptor;
    if (!modelInfo) {
      this.logServ.error(this.serviceName, 'No ModelDescriptor found in the passed class');
      return null;
    }
    return this.getModelRepoDesc(modelInfo);
  }

  getModelRepoDesc<T>(modelInfo: ModelDescriptor): IRepository<T> {
    const name = modelInfo.name;
    if (!this.repoRegistry[name]) {
      this.logServ.error(this.serviceName, 'Repository ' + name + ' not found');
    }
    return this.repoRegistry[name];
  }

  setRepo(name: string, repo: IRepositoryBase) {
    this.repoRegistry[name] = repo;
  }

  getForm(name: string): () => Form {
    if (!this.formsRegistry[name]) {
      throw Error('Form ' + name + ' not found');
    }
    return this.formsRegistry[name];
  }

  /** Creates a new instance of a named form */
  createForm(name: string): Form {
    if (!this.formsRegistry[name]) {
      throw Error('Form ' + name + ' not found');
    }
    return this.formsRegistry[name]();
  }

  /** Creates a new instance of a models form */
  createModelForm<T>(model: new () => T): Form {
    // tslint:disable-next-line: no-string-literal
    const modelInfo = model['_modelDescriptor'] as ModelDescriptor;
    if (!modelInfo) {
      this.logServ.error(this.serviceName, 'No ModelDescriptor found in the passed class');
      return null;
    }
    const name = modelInfo.name;
    if (!this.formsRegistry[name]) {
      this.logServ.error(this.serviceName, 'Repository ' + name + ' not found');
    }
    return this.formsRegistry[name]();
  }

  createModelFormNamed<T>(model: new () => T, formName: string): Form {
    // tslint:disable-next-line: no-string-literal
    const modelInfo = model['_modelDescriptor'] as ModelDescriptor;
    if (!modelInfo) {
      this.logServ.error(this.serviceName, 'No ModelDescriptor found in the passed class');
      return null;
    }
    const name = modelInfo.name + '/' + formName;
    if (!this.formsRegistry[name]) {
      this.logServ.error(this.serviceName, 'Repository ' + name + ' not found');
    }
    return this.formsRegistry[name]();
  }

  /** Sets a form with a global name */
  setForm(name: string, form: () => Form) {
    this.formsRegistry[name] = form;
  }

  hasModel(name: string): boolean {
    return this.modelRegistry[name] !== undefined;
  }

  hasForm(name: string): boolean {
    return this.formsRegistry[name] !== undefined;
  }

  /** Stores or merges a model configuration. Errors will be raised if the model descriptor is wrongly configured. */
  async setModel(modelInfo: ModelDescriptor) {
    if (this.modelRegistry[modelInfo.name]) {
      modelInfo = Object.assign(this.modelRegistry[modelInfo.name], modelInfo);
    } else {
      this.modelRegistry[modelInfo.name] = modelInfo;
    }

    if (!modelInfo.primaryKey) {
      this.logServ.error(this.serviceName, 'Primary key not set for model ' + modelInfo.name, modelInfo);
    }
    // console.log(modelInfo.name, modelInfo.restEndpoint);
    try {
      if (modelInfo.defaultRepo) {
        await modelInfo.defaultRepo.setup();
        this.repoRegistry[modelInfo.name] = modelInfo.defaultRepo;
      } else if (modelInfo.restEndpoint) {
        const pagination = modelInfo.defaultPagination !== undefined ? modelInfo.defaultPagination : this.defaultPaginationHandler;
        const modelRepository = new WebapiRepository(modelInfo, this.webapiServ, undefined, pagination);
        await modelRepository.setup();
        this.repoRegistry[modelInfo.name] = modelRepository;
        modelInfo.defaultRepo = modelRepository;
      }
      if (modelInfo.repos) {
        for (const repo in modelInfo.repos) {
          if (modelInfo.repos.hasOwnProperty(repo)) {
            await modelInfo.repos[repo].setup();
          }
        }
      }
    } catch (err) {
      this.logServ.error(this.serviceName, 'Error while setting up default repository for ' + modelInfo.name, modelInfo);
    }
    if (modelInfo.getDefaultForm()) {
      this.formsRegistry[modelInfo.name] = modelInfo.getDefaultForm();
    }
    if (modelInfo.forms) {
      for (const form in modelInfo.forms) {
        if (modelInfo.forms.hasOwnProperty(form)) {
          this.formsRegistry[modelInfo.name + '/' + form] = modelInfo.forms[form];
        }
      }
    }
  }

  overrideModel(modelName: string, callback: (model: ModelDescriptor) => ModelDescriptor | null) {
    if (this.modelRegistry[modelName]) {
      const ret = callback(this.modelRegistry[modelName]);
      if (ret) {
        // console.log(ret, this.modelRegistry[modelName]);
        this.setModel(ret).then(() => { }).catch((err) => { console.error(err); });
      }
    } else {
      console.warn('Overriding model ' + modelName + ' not found');
    }
  }

  getModelWithContext(contextType: string): ModelDescriptor[] {
    const ret: ModelDescriptor[] = [];
    for (const modelName in this.modelRegistry) {
      if (this.modelRegistry.hasOwnProperty(modelName)) {
        const model = this.modelRegistry[modelName];
        if (!model.contextTypes) { continue; }
        if (model.contextTypes.indexOf(contextType) >= 0) { ret.push(model); }
      }
    }
    return ret;
  }

  async edit(modelId: string, data: any) {
    const modelPtr = this.getModel(modelId);
    // const form = this.dataServ.getForm(modelId);

    const myForm = modelPtr.createDefaultForm();
    // myForm.hideTitle = true;
    const context = this.contextServ.createFromGlobalContext('modal.editor');

    /// console.log(this.settings);
    const modal = await this.modalCtrl.create({
      component: ComponentRegistry['EditorComponent'],
      componentProps: {
        model: modelPtr,
        form: myForm,
        // repo: this.repo,
        value: data,
        isModal: true,
        // title: modalTittle,
        showCancel: true,
        context
      }
    });
    await modal.present();
    myForm.editorComponentId = modal.id;
    const result = await modal.onDidDismiss();
    /*if (result.role === 'saved' || result.role === 'deleted') {
      await this.refresh();
      if (this.filterTerm) {
        this.filterByTerm(this.filterTerm);
      }
      await this.changed.emit();
    }*/
  }

  private registerDefaultControls(controls: ControlRegistryData[]) {
    for (const ctrl of controls) {
      this.controlRegistry[ctrl.name] = ctrl.componentClass;
    }
  }
}
