import { IRepositoryBase } from './repository';
import { ModelDescriptor } from './model';
import { Storage } from '@ionic/storage';
import { Drivers } from '@ionic/storage';

// https://github.com/ionic-team/ionic-storage

/** DataStore para almacenar datos localmente */
export class IonicStorageRepository implements IRepositoryBase {
  private ionicStore: Storage;

  constructor(public model: ModelDescriptor, public useSQLite = false) {
    let driverOrder;
    useSQLite = false;
    if (useSQLite) {
      // Not implemented yet
      // driverOrder = [CordovaSQLiteDriver._driver, Drivers.IndexedDB, Drivers.LocalStorage];
    } else {
      driverOrder = [Drivers.IndexedDB, Drivers.LocalStorage];
    }
    this.ionicStore = new Storage({
      name: model.name,
      storeName: model.name,
      driverOrder
    });
  }

  async clear(): Promise<void> {
    await this.ionicStore.clear();
  }

  async setup() {
    await this.ionicStore.create();
  }

  async query(filters?: { [name: string]: any; }): Promise<any[]> {
    // console.log(filters);
    if (filters && filters.hasOwnProperty(this.model.primaryKey) && Object.keys(filters).length === 1) {
      return await this.queryIDField(filters[this.model.primaryKey]);
    } else {
      return this.queryMultiField(filters);
    }
  }

  async queryIDField(id: any): Promise<any[]> {
    const res = await this.ionicStore.get(id);
    if (res) { return [res]; } else { return []; }
  }

  async queryMultiField(filters?: any): Promise<any[]> {
    const results = [];
    // console.log('QUERY FILTERS:', filters);
    await this.ionicStore.forEach((value, key, numb) => {
      let filterValue = false;
      if (filters) {
        for (const filter in filters) {
          if (filters.hasOwnProperty(filter)) {
            if (value[filter] !== filters[filter]) {
              filterValue = true;
              // console.log('not the same ' + filter, value[filter], filters[filter]);
              break;
            }
          }
        }
      }
      if (!filterValue) {
        const instance = new this.model.ctor();
        Object.assign(instance, value);
        // instance._meta.inLocal = true;
        results.push(instance);
        // console.log(value);
      }
      // else console.log('entity not passed filter', value, filters);
    });
    return results;
  }

  async upsert(objToUpsert: any): Promise<any> {
    const objectId = objToUpsert[this.model.primaryKey];
    // console.log(objToUpsert);
    if (objectId === undefined) {
      throw new Error('No ID set in object to upsert');
    }
    await this.ionicStore.set(objectId, objToUpsert);
    // objToUpsert._meta.inLocal = true;
    return objToUpsert;
  }

  async delete(object: any): Promise<any> {
    const objectId = object[this.model.primaryKey];
    await this.ionicStore.remove(objectId);
    return object;
  }
}

/*


/** DataStore para almacenar datos localmente
export class IonicStorageDataStore implements IRepositoryBase {
  public model: ModelDescriptor;
  private ionicStoreLocalIds: Storage;
  private ionicStore: Storage;
  private ctr: new () => any;

  constructor(ctr: new () => any, modelName: string, modelInfo: ModelInfo) {
    this.ctr = ctr;
    this.ionicStore = new Storage({
      name: modelName,
      storeName: modelName,
      driverOrder: ['indexeddb', 'localstorage']
      // driverOrder: [/*'sqlite',/ 'indexeddb' /*, 'localstorage'/]
    });
    /* this.ionicStoreLocalIds = new Storage({
      name: modelName + '_localIds',
      storeName: modelName + '_localIds',
      driverOrder: ['indexeddb', 'localstorage']
    });*
  }

  clear(): Promise<void> {
    throw new Error('Method not implemented.');
  }

  async setup() {
    await this.ionicStore.ready();
    // await this.ionicStoreLocalIds.ready();
  }

  async query(filters?: any): Promise<any[]> {
    const results = [];
    // console.log('QUERY FILTERS:', filters);
    await this.ionicStore.forEach((value, key, numb) => {
      let filterValue = false;
      if (filters) {
        for (const filter in filters) {
          if (filters.hasOwnProperty(filter)) {
            if (value[filter] !== filters[filter]) {
              filterValue = true;
              // console.log('not the same ' + filter, value[filter], filters[filter]);
              break;
            }
          }
        }
      }
      if (!filterValue) {
        const instance = new this.ctr();
        Object.assign(instance, value);
        instance._meta.inLocal = true;
        results.push(instance);
        // console.log(value);
      }
      // else console.log('entity not passed filter', value, filters);
    });
    return results;
  }

  async upsert(objToUpsert: any): Promise<any> {
    const objectId = objToUpsert.getStoreId();
    console.log(objToUpsert);
    await this.ionicStore.set(objectId, objToUpsert);
    objToUpsert._meta.inLocal = true;
    return objToUpsert;
  }

  async delete(object: any): Promise<any> {
    const objectId = object.getStoreId();
    await this.ionicStore.remove(objectId);
    return object;
  }
}*/
