
import { FormComponent } from 'src/app/lib/components/form/form.component';
import { Model } from 'src/app/lib/data/model';
import { Control } from 'src/app/lib/forms/Control';
import { Form, FormEvent } from 'src/app/lib/forms/Form';

@Model({
  name: 'sistema_Notificacion',
  restEndpoint: 'sistema/notificaciones/',
  primaryKey: 'not-set',
  defaultPagination: null,
  onFormEvent: async (eventType: FormEvent, form: Form, ctrl: Control, event: Event, formCompo: FormComponent): Promise<boolean> => {
    return await Notificacion.HandleFormEvents(eventType, form, ctrl, event, formCompo);
  }
})
export class Notificacion {

  static async HandleFormEvents(eventType: FormEvent, form: Form, ctrl: Control, event: Event, formCompo: FormComponent): Promise<boolean> {
    if (eventType === FormEvent.OnCreate) {
      console.log(formCompo);
      if (!formCompo.isNew) {
        form.disableDelete = true;
        form.disableSave = true;
        form.actions = [
          // OPCION DE ARCHIVAR
          {
            text: 'Archivar',
            onClick: async (component, cEvent) => {
              await formCompo.uiServ.loadingMessage('Archivando...');
              try {
                const url = 'sistema/notificaciones/' + form.getValue('id') + '/leido/';
                await formCompo.webapi.post(url);
              } catch (e) {
                formCompo.uiServ.error(e);
              }
              await formCompo.uiServ.clearLoadingMessage();
              await formCompo.cancel();
            }
          }];
      }
    }
    return false;
  }

}
