import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { UiService } from 'src/app/lib/services/ui.service';
import { AuthService } from 'src/app/lib/services/auth.service';
import { LoggerService } from 'src/app/lib/services/logger.service';
import { ConfigService } from 'src/app/lib/services/config.service';
import { IonSelect } from '@ionic/angular';
import { environment } from 'src/environments/environment';

export enum LoginComponentState {
  Loading = 'Loading',
  InputCredentials = 'InputCredentials',
  AskResetPassword = 'AskResetPassword',
  AskConfirmResetCode = 'AskConfirmResetCode'
}

@Component({
  selector: 'lib-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  @Input() state: LoginComponentState = LoginComponentState.Loading;
  @Input() title = 'App';
  @Input() testingCredentials: [];
  @ViewChild('hiddenSelect') hiddenSelect: IonSelect;
  usuario = '';
  password = '';
  resetUser = '';
  resetCode = '';
  resetNewPass = '';
  resetNewPassConfirm = '';
  testingUserSelected;

  constructor(
    public uiServ: UiService,
    public authServ: AuthService,
    public loggerServ: LoggerService,
    public configServ: ConfigService,

  ) { }

  ngOnInit() { }


  async loginButtonClick() {
    await this.uiServ.loadingMessage('Iniciando sesión...');
    try {
      await this.authServ.login(this.usuario, this.password);
    } catch (err) {
      this.loggerServ.error('Init', 'No se pudo iniciar sesión', err);
      console.error(err);
      this.uiServ.error('No se pudo iniciar sesión con las credenciales proporcionadas');
    }
    await this.uiServ.clearLoadingMessage();
  }


  resetCancel() {
    this.state = LoginComponentState.InputCredentials;
  }

  async resetPassword() {
    this.state = LoginComponentState.AskResetPassword;
  }

  async resetSendCode() {
    try {
      await this.uiServ.loadingMessage('Enviando código');
      const code = await this.authServ.resetPassSendCode(this.resetUser);
      await this.uiServ.clearLoadingMessage();
      await this.uiServ.ok('Se ha enviando un <b>código</b> al correo ingresado. Utilícelo para restablecer la contraseña.', 'Correcto');
      this.state = LoginComponentState.AskConfirmResetCode;
    } catch (err) {
      this.uiServ.error(err);
    }
  }

  async resetGoToConfirm() {
    this.state = LoginComponentState.AskConfirmResetCode;
  }

  async resetConfirm() {
    if (this.resetNewPassConfirm !== this.resetNewPass) {
      this.uiServ.alert('La nueva contraseña no coincide con la confirmación.');
      return;
    }
    try {
      await this.uiServ.loadingMessage('Restableciendo contraseña');
      const code = await this.authServ.restPassConfirm(this.resetUser, this.resetCode, this.resetNewPass);
      await this.uiServ.clearLoadingMessage();
      await this.uiServ.ok('Correcto, su nueva contraseña se a establecido', 'Correcto');
      this.state = LoginComponentState.InputCredentials;
    } catch (err) {
      if (err.error && err.error.message && err.error.message.indexOf && err.error.message.indexOf('incorrecto') > -1) {
        this.uiServ.alert('Código ingresado no es correcto');
      } else {
        this.uiServ.error(err);
      }
    }
  }

  testingUserChanged() {
    console.log(this.testingUserSelected);
    if (this.testingUserSelected) {
      this.usuario = this.testingUserSelected.name;
      this.password = this.testingUserSelected.pass;
    }
  }

  openTestingMenu() {
    if (environment.production) {
      return;
    }
    console.log(this.hiddenSelect);
    this.hiddenSelect.open(null);
  }
}
