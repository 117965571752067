// tslint:disable: variable-name

export class BaseUserGroup {
  id: number;
  name: string;
}

export class BaseUser {
  id: number;
  username: string;
  first_name: string;
  last_name: string;
  email: string;
  is_active?= true;
  user_permissions?: [];
  groups: BaseUserGroup[];
  permissions: BaseUserGroup[];
  pagina_inicial: string;
  password_invalido: boolean;

  hasGroup(name: string): boolean {
    if (!this.groups) {
      return false;
    }
    return (this.groups as BaseUserGroup[]).find(itr => itr.name === name) !== undefined;
  }

  notHasGroup(name: string): boolean {
    if (!this.groups) {
      return false;
    }
    return !this.hasGroup(name);
  }

  get fullName(): string { return this.first_name + ' ' + this.last_name; }

  hasPermission(name: string): boolean {
    if (!this.permissions) {
      return false;
    }
    return (this.permissions as BaseUserGroup[]).find(itr => itr.name === name) !== undefined;
  }

  hasAnyGroup(names: string[]): boolean {
    for (const name of names) {
      if (this.hasGroup(name)) {
        return true;
      }
    }
    return false;
  }

  hasAnyPermission(names: string[]): boolean {
    for (const name of names) {
      if (this.hasPermission(name)) {
        return true;
      }
    }
    return false;
  }
}

