// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // webapiURL: 'http://pruebas-cat3.grupoceres.com.mx/',
  // webapiURL: 'http://localhost:9008/',
  webapiURL: 'https://pruebas.ceresagrotec.com.mx/api/',
  // webapiURL: 'http://192.168.0.5:9008/',
  // ceresAuthURL: 'https://auth.grupoceres.com.mx',
  // ceresAuthAppName: 'com.grupoceres.cat.pruebas',
  // websocketsRoot: null, // disable web sockets
  ceresAuthURL: null,
  ceresAuthAppName: null,
  websocketsRoot: 'wss://pruebas.ceresagrotec.com.mx/ws/',
  testingCredentials: [
    { name: 'vendedor1@seferssa.com', pass: 'rewq4321' },
    { name: 'gerente.costos@seferssa.com', pass: 'rewq4321' },
    { name: 'gerente.medio@seferssa.com', pass: 'rewq4321' },
    { name: 'gerente@seferssa.com', pass: 'rewq4321' },
  ]
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
