import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PlatformService {
  get isNative() { return window['cordova'] !== undefined; }

  constructor() { }
}
