import { Component, Input, OnInit } from '@angular/core';
import { Control } from '../../Control';
import { Form } from '../../Form';
import { AbsControlComponent } from '../AbsControlComponent';

@Component({
  selector: 'app-rich-text',
  templateUrl: './rich-text.component.html',
  styleUrls: ['./rich-text.component.scss'],
})
export class RichTextComponent extends AbsControlComponent implements OnInit {
  @Input() control: Control;
  @Input() form: Form;

  constructor() {
    super();
   }

  ngOnInit() {}

}
