import { Control } from '../Control';
import { Form } from '../Form';
import { AbstractValidator, AbstractInput, AbstractTest, TestNotEmpty } from '../validators/abstractValidator';
import { OnInit } from '@angular/core';
import { AbstractControl, FormControl, ValidatorFn, Validators } from '@angular/forms';

export abstract class AbsControlComponent {
  control: Control = {} as any;
  form: Form;
  labelPosition = 'stacked';

  constructor() {
  }

  setupAbsControl(validators?: ValidatorFn[]) {
    // this.createValidations();
    if (this.control) {
      this.control.ngComponent = this;
    }

    const toAdd: ValidatorFn[] = [];
    if (validators) {
      toAdd.push(...validators);
    }

    // # Normal required
    if (this.control.required) {
      const validator = (control: AbstractControl): { [key: string]: any } | null => {
        /*console.log(this.control.field);
        if (this.control.field === 'linea_negocio') {
          console.log('BREAK');
          console.log('BREAK2');
        }*/
        if (!this.isVisible()) {
          // console.log(this.control, 'is hidden');
          return null;
        }
        const currentValue = this.control.ngControl.value;
        if (currentValue === null || currentValue === undefined || currentValue === '') {
          // console.log(this.control.field, currentValue, 'is invalid');
          return { required: true };
        }
        return null;
      };
      toAdd.push(validator);
      this.control.__isRequired = true;
      // Add conditional requirement
    }

    // # Conditional required
    if (this.control.requiredWhen) {
      const validator = (control: AbstractControl): { [key: string]: any } | null => {
        if (!this.isVisible()) {
          // console.log(this.control, 'is hidden');
          return null;
        }
        if (!this.form.computeIsControlRequired(this.control)) {
          return null;
        }
        const currentValue = this.control.ngControl.value;
        if (currentValue === null || currentValue === undefined) {
          return { required: true };
        }
        return null;
      };
      toAdd.push(validator);
    }
    this.control.ngControl?.setValidators(toAdd);
    // console.log(this.control.field, toAdd);
  }

  isVisible() {
    const value = this.isVisibleInHierarchy(this.control);
    // console.log(this.control.field, value);
    return value;
  }

  private isVisibleInHierarchy(controlComponent: Control): boolean {
    if (!controlComponent.__isVisible) {
      return false;
    }
    if (controlComponent.parent) {
      return this.isVisibleInHierarchy(controlComponent.parent);
    }
    return true;
  }

  getValidatorErrorMessage(validator: string) {
    return null;
  }

  collectValue(): any {
    if (this.control.notSaveWhen && this.form.context.execute(this.control.notSaveWhen)) {
      return undefined;
    }
    return this.control.ngControl.value;
  }

  clear() {
    this.control.ngControl.setValue(undefined);
    this.modified(null);
  }

  modified(evt) {
    // Rerun validators
    /*this.control.validator.validate(); */
    // console.log(this.control);
    // console.log(this.form.context);

    // notify change
    if (this.control.parent && this.control.parent.childChanged) {
      this.control.parent.childChanged(this.control);
    }

    if (this.control.onChange) {
      // console.log('SELECT CHANGED CALLED,', this.control.field, evt);
      this.form.context.execute(this.control.onChange, null, this.control.ngControl.value);
    }
  }

  createNumberFieldRequirements(): ValidatorFn[] {
    if (!this.control) { return; }
    const valid = [];
    if (this.control.max !== undefined) {
      valid.push(Validators.max(this.control.max));
    }
    if (this.control.min !== undefined) {
      valid.push(Validators.min(this.control.min));
    }
    return valid;
  }

  createTextFieldRequirements(): ValidatorFn[] {
    if (!this.control) { return; }
    const valid = [];
    if (this.control.max !== undefined) {
      valid.push(Validators.maxLength(this.control.max));
    }
    if (this.control.min !== undefined) {
      valid.push(Validators.minLength(this.control.min));
    }
    return valid;
  }

  getLabelClass() {
    // console.log(this.control.field, this.control.isRequired);
    let cls = 'ion-text-wrap';
    if (this.control.__isRequired) {
      cls += ' requiredFieldFG';
      // console.log(this.control.field, cls);
    }
    // cls += ' requiredFieldFG';
    return cls;
  }
}

export function SetupTestingForControl(ctrl: AbsControlComponent) {
  ctrl.control = new Control(
    {
      ngControl: {
        value: null,
        setValue: (value) => { },
        setValidators: (vals) => { }
      } as any

    });
  ctrl.form = new Form();
}
