<div class="container" *ngIf="configServ.initialized">
  <ion-list *ngIf="mainMenuServ.settings.mainMenu.options">
    <ion-item *ngFor="let item of mainMenuServ.settings.mainMenu.options" button (click)="openOption(item)">
      <ion-icon slot="start" [name]="item.icon"></ion-icon>
      <ion-label>{{item.title}}</ion-label>
    </ion-item>
  </ion-list>

  <ion-card *ngFor="let grp of mainMenuServ.settings.mainMenu.groups">
    <ion-card-header>
      <ion-card-title>
        {{grp.title}}
      </ion-card-title>
    </ion-card-header>
    <ion-list>
      <ion-item *ngFor="let item of grp.childOptions" button (click)="openOption(item)" [hidden]="isOptionHidden(item)" [disabled]="isOptionDisabled(item)">
        <ion-icon slot="start" [name]="item.icon"></ion-icon>
        <ion-label>{{item.title}}</ion-label>
      </ion-item>
    </ion-list>
  </ion-card>
</div>

<div class="bottom" *ngIf="configServ.initialized && mainMenuServ.settings.mainMenu.bottomOptions">
  <ion-item *ngFor="let item of mainMenuServ.settings.mainMenu.bottomOptions" button (click)="openOption(item)">
    <ion-icon slot="start" [name]="item.icon"></ion-icon>
    <ion-label>{{item.title}}</ion-label>
  </ion-item>
</div>