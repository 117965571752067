import { Injectable } from '@angular/core';
import { AsyncSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EventsService {
  onInit: Subject<void> = new Subject();

  constructor() { }
}
