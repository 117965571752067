import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Context } from '../data/context';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class ContextService {

  readonly contexts: { [name: string]: Context; } = {};

  private globals: Context[] = [];

  constructor(
    private authService: AuthService,
    // private configService: ConfigService,
    // private loggerService: LoggerService,
    // private uiServ: UiService,
  ) { }

  async setup() {
    const userContext = new Context('user', null, 'user', true);
    // userContext.setValue('user', 'id', this.authService.userId);
    userContext.setValueObject('user', this.authService.user);
    this.addContext(userContext);
    // console.log(this.globals);
  }

  findContext(id: string) {
    return this.contexts[id];
  }

  findContextsByType(type: string): Context[] {
    const ret = [];
    for (const key in this.contexts) {
      if (Object.prototype.hasOwnProperty.call(this.contexts, key)) {
        const ctx = this.contexts[key];
        if (ctx.type === type) { ret.push(ctx); }
      }
    }
    return ret;
  }

  addContext(ctx: Context) {
    if (this.contexts[ctx.id]) {
      console.error('Context ', ctx.id, ' already defined!');
    }
    this.contexts[ctx.id] = ctx;
    if (ctx.isGlobal) {
      this.globals.push(ctx);
    }
  }

  removeContext(id: string) {
    if (!this.contexts[id]) {
      console.error('Context ', id, ' already defined!');
    }
    if (this.contexts[id].isGlobal) {
      this.globals.splice(this.globals.findIndex(iter => iter === this.contexts[id]), 1);
    }
    delete this.contexts[id];

  }

  getGlobalContexts(): Context[] {
    return this.globals;
  }

  hasContext(id: string) {
    return this.contexts[id];
  }

  createFromGlobalContext(id: string): Context {
    return new Context(id, this.globals);
  }

  createContextFromParams(router: ActivatedRoute, id: string): Context {
    return this.readContextsFromNavParams(router, new Context(id));
  }

  readContextsFromNavParams(router: ActivatedRoute, targetContext: Context): Context {
    if (router.snapshot.paramMap.has('contexts')) {
      const rawContextsIds = router.snapshot.paramMap.get('contexts').split('~');
      // console.log(rawContextsIds);
      const allQueryParams = {};
      rawContextsIds.forEach(ctxId => {
        const ctx = this.findContext(ctxId);
        if (!ctx) { throw Error('Contexto ' + ctxId + ' no encontrado!'); }
        targetContext.merge(ctx);
        // console.log('Added context ', ctx, ' for ', ctxId);
      });
    }
    return targetContext;
  }
}
