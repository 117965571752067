import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ConfigService } from '../../services/config.service';
import { MenuController } from '@ionic/angular';
import { MainMenuService } from '../../services/main-menu.service';

@Component({
  selector: 'lib-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Input() title: string;
  @Input() closable: boolean;
  @Input() hideMenuButton: boolean;
  @Output() close = new EventEmitter<any>();
  menuName: string;

  constructor(
    private mainMenuServ: MainMenuService,
    private configServ: ConfigService,
    private menuCtrl: MenuController) { }

  ngOnInit() { }

  getTitle(): string {
    if (this.title) {
      return this.title;
    } else {
      return this.mainMenuServ.settings.appTitle;
    }
  }

  headerButtonAvailable(): boolean {
    if (this.mainMenuServ.splitIsActive) { return false; }
    return this.mainMenuServ.settings.mainMenuInHeader;
  }

  openMenu() {
    // console.log(this.menuName ? this.menuName : this.mainMenuServ.settings.mainMenuName);
    this.menuCtrl.toggle(this.menuName ? this.menuName : this.mainMenuServ.settings.mainMenuName);
  }

  doClose() {
    this.close.emit();
  }
}
