import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';

export const DayNamesShortEsp = ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab'];
export const MonthNamesShortEsp = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'];
export const MonthNamesEsp = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];

@Injectable({
  providedIn: 'root'
})
export class DateService {
  timeZone = 'America/Mazatlan';
  utcOffset = 'Z'; // or '+00:00'
  isoFormat = 'yyyy-MM-ddTHH:mm:ss.SSSZZZZZ';

  constructor(private datePipe: DatePipe) { }

  /** Uses ISO8601: yyyy-MM-ddTHH:mm:ss.SSSZ */
  toDatetimeUTC(date: Date | string, format?: string): string {
    if (!format) { format = this.isoFormat; }
    return this.datePipe.transform(date, format, 'UTC'); // + this.utcOffset;
  }

  toDateUTC(date: Date): string {
    return this.datePipe.transform(date, 'yyyy-MM-dd', 'UTC') + 'Z';
  }

  fromUTC(date: string): Date {
    return new Date(date);
  }
}
