// tslint:disable: variable-name
import { Model } from '../../../lib/data/model';
import { Form } from 'src/app/lib/forms/Form';
import { CrudSettings } from 'src/app/lib/data/CrudSettings';

@Model({
  name: 'Sucursal',
  primaryKey: 'id',
  restEndpoint: 'precios/sucursal/',
  singular: 'sucursal',
  plural: 'sucursales',
  singularArticle: 'la',
  pluralArticle: 'las',
  forms: {
    default: () => {
      return new Form({
        title: 'Sucursal',
        childControls: [
          { field: 'nombre', label: 'Nombre', type: 'text', max: 50 },
        ]
      });
    }
  },
  cruds: {
    default: new CrudSettings({
      labelProperty: 'nombre',
    })
  }
})
export class Sucursal {
  id: number;
  nombre: string;
}