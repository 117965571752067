<ion-item>
  <ion-label [class]="getLabelClass()" [position]="labelPosition">{{control.label}}</ion-label>
  <ion-textarea
    [formControl]="control.ngControl"
    [maxlength]="control.max"
    (ionChange)="modified($event)"
    [autoGrow]="autogrow"
    rows="1"
    [readonly]="form.computeIsControlReadonly(control)"
    [disabled]="form.disabled || control.disabled">
  </ion-textarea>
</ion-item>