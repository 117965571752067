import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class InitGuard implements CanActivate {

  constructor(private configServ: ConfigService, private router: Router) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    // return this.configServ.initialized;
    if (this.configServ.initialized) {
      return true;
    } else {
      let queryParms = {};
      /*if (state.url.indexOf('?return=') < 0) {

      } else {

      }*/
      queryParms = { return: state.url };
      this.router.navigate(['/init'], {
        queryParams: queryParms
      });
      return false;
    }
  }

}
