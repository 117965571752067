import { IControlArgs } from '../../Control';

export class ObjectCtrlArgs implements IControlArgs {
  suffix: string;
  model: string;

  constructor(other?: Partial<ObjectCtrlArgs>) {
    if (other) {
      Object.assign(this, other);
    }
  }
}
