import { IControlArgs } from '../../Control';

export class SelectModalCtrlArgs implements IControlArgs {
  model: string;
  titleProperty: string;
  repo?: string;
  options?: { key: any, value: string }[];
  isMultiple?: boolean;
  disableAdd?: boolean;
  allowAdd?: boolean;
  allowEdit?: boolean;
  allowAddWhen?: string;
  allowEditWhen?: string;
  filter?: string;
  storeValueInContext?: boolean;

  onItemsUpdated?: string;

  constructor(other?: Partial<SelectModalCtrlArgs>) {
    if (other) {
      Object.assign(this, other);
    }
  }
}

window['SelectModalCtrlArgs'] = SelectModalCtrlArgs;
