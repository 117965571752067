import { Component, Input, OnInit } from '@angular/core';
import { Control } from '../../Control';
import { Form } from '../../Form';
import { AbsControlComponent } from '../AbsControlComponent';
import { NumberCtrlArgs } from '../number/args';

@Component({
  selector: 'app-money',
  templateUrl: './money.component.html',
  styleUrls: ['./money.component.scss'],
})
export class MoneyComponent extends AbsControlComponent implements OnInit {
  @Input() control: Control;
  @Input() form: Form;
  args: NumberCtrlArgs;
  hasFocus = false;

  constructor() {
    super();
  }

  ngOnInit() {
    this.setupAbsControl();
    this.args = this.control.args as NumberCtrlArgs;
    if (!this.args) {
      this.args = new NumberCtrlArgs();
    }
  }

  focusChange(state: boolean) {
    this.hasFocus = state;
  }

}
