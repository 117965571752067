// tslint:disable: variable-name
import { Model } from '../../../lib/data/model';
import { Form } from 'src/app/lib/forms/Form';
import { CrudSettings } from 'src/app/lib/data/CrudSettings';

@Model({
  name: 'Especificacion',
  primaryKey: 'id',
  restEndpoint: 'precios/especificaciones/',
  singular: 'especificación',
  plural: 'especificaciones',
  singularArticle: 'la',
  pluralArticle: 'las',
  forms: {
    default: () => {
      return new Form({
        title: 'Especificación',
        childControls: [
          { field: 'descripcion', label: 'Descripción', type: 'textarea', max: 400, required: true },
          { field: 'vigente', label: 'Vigente', type: 'checkbox' },
        ]
      });
    }
  },
  cruds: {
    default: new CrudSettings({
      labelProperty: 'descripcion',
    })
  }
})
export class Especificacion {
  id: number;
  descripcion: string;
  vigente = true;
}