import { IPaginationInfo, IRepositoryBase } from './repository';
import { ModelDescriptor } from './model';
import { WebapiService } from '../services/webapi.service';
import { Tools } from '../services/tools';

/** Repositorio para acceder a datos de un backend, NO arroja errores por códigos 404. */
export class WebapiRepository implements IRepositoryBase {
  url: string;
  pagination: IPaginationInfo;
  customUrl?: string; // For overriding the normal generated URL

  constructor(
    public model: ModelDescriptor,
    public webapi: WebapiService,
    endpoint?: string,
    public paginationHandler?: IPaginationHandler) {
    if (endpoint) {
      this.url = endpoint;
    } else {
      this.url = this.model.restEndpoint;
    }
  }

  clear(): Promise<void> {
    throw new Error('El webapi no se puede vaciar.');
  }

  async setup() {
    // Nothing to setup
  }

  async query(filters?: { [name: string]: any; }, repoParams?: any): Promise<any[]> {
    let url = this.url;
    let addedParams = false;
    let pagHandler = this.paginationHandler;

    if (filters) {
      if (filters.hasOwnProperty(this.model.primaryKey) && Object.keys(filters).length === 1) {
        // ID
        url += filters[this.model.primaryKey] + '/';
        pagHandler = null;
      } else {
        // Multi filters
        for (const fieldName in filters) {
          if (!filters.hasOwnProperty(fieldName)) { continue; }
          if (!addedParams) {
            url += '?';
            addedParams = true;
          }
          url += fieldName + '=' + filters[fieldName] + '&';
        }
        url = url.substring(0, url.length - 1);
      }
    }

    if (pagHandler) {
      url = pagHandler.prepareQuery(url, repoParams);
    }

    let results: any[];
    // console.log('CONSULTANDO:', url);
    try {
      results = await this.webapi.get(url);

      if (pagHandler) {
        const pagResults = pagHandler.handlePagination(results);
        results = pagResults.results;
        this.pagination = pagResults.info;
      }

      if (!Array.isArray(results)) {
        results = [results];
      }

    } catch (error) {
      if (error && error.status === 404) {
        results = [];
      } else {
        throw error;
      }
    }
    const returnedResults: any[] = [];
    for (const obj of results) {
      // const parseObj = new this.ctr();Object.assign(parseObj, obj);
      const parseObj = Tools.cloneAs(this.model.ctor, obj); // new this.ctr();Object.assign(parseObj, obj);
      parseObj._inBackend = true;
      returnedResults.push(parseObj);
    }
    return returnedResults;
  }

  async upsert(objToUpsert: any | any[], repoParams?: any): Promise<any> {
    const objectId = objToUpsert[this.model.primaryKey]; // .getStoreId(); // DataService.getObjectId(objToUpsert);
    let results = objToUpsert;
    if (objectId !== undefined) {
      const url = this.customUrl ? this.customUrl : (this.url + objectId + '/');
      results = await this.webapi.patch(url, objToUpsert);
    } else {
      const url = this.customUrl ? this.customUrl : this.url;
      results = await this.webapi.post(url, objToUpsert);
    }
    Tools.assignCleanToAs(this.model.ctor, objToUpsert, results);
    objToUpsert.inBackend = true;
    return objToUpsert;
  }

  async delete(object: any, repoParams?: any): Promise<any> {
    const objectId = object[this.model.primaryKey]; // object.getStoreId();
    const res = await this.webapi.del(this.url, objectId);
    return object;
  }

}

export interface IPaginationHandler {
  handlePagination(rawResults: any): { results: [], info: IPaginationInfo };
  prepareQuery(url: string, args?: any): string;
}

export class LimitPaginationHandler implements IPaginationHandler {
  prepareQuery(url: string, args?: any): string {
    let newUrl = url;
    if (args && (args.limit || args.offset)) {
      const newArgs = [];
      if (args.limit) {
        newArgs.push('limit=' + args.limit);
      }
      if (args.offset) {
        newArgs.push('offset=' + args.offset);
      }
      if (newArgs.length) {
        if (newUrl.indexOf('?') < 0) {
          newUrl += '?' + newArgs.join('&');
        } else {
          newUrl += '&' + newArgs.join('&');
        }
      }
    }
    return newUrl;
  }
  handlePagination(rawResults: any): { results: [], info: IPaginationInfo } {
    if (!rawResults) {
      return null;
    }
    return {
      results: rawResults.results,
      info: {
        count: rawResults.count,
        next: rawResults.next,
        previous: rawResults.previous
      }
    };

  }
}

export class PagedPaginationHandler implements IPaginationHandler {
  prepareQuery(url: string, args?: any): string {
    let newUrl = url;
    if (args && args.pagination) {

    }
    return newUrl;
  }
  handlePagination(rawResults: any): { results: [], info: IPaginationInfo } {
    if (!rawResults) {
      return null;
    }
    return {
      results: rawResults.results,
      info: {
        count: rawResults.count,
        next: rawResults.next,
        previous: rawResults.previous
      }
    };

  }
}

