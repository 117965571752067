// tslint:disable: no-string-literal
// import { Control } from '../forms/Control';
import { Subject } from 'rxjs';
import { Control } from '../forms/Control';
import { Form, FormCreatorFunc, FormEvent } from '../forms/Form';
import { Menu } from '../menu/menu';
import { CrudSettings, CurdLabelComponent } from './CrudSettings';
import { Titles } from './titles';
import { IRepositoryBase } from './repository';
import { RepositorySetting } from '../services/openapi.models';
import { IPaginationHandler } from './webapi.repository';
import { FormComponent } from '../components/form/form.component';


/** Arguments used for creating the description of a model using the Model class attribute */
export class ModelArgs {

  /** Unique name of the model in the app */
  name: string;

  /** Name of the field that will be used to identify instances of this model */
  primaryKey: string;

  ctor?: new () => any;

  /** Setting a restful endpoint will generate default WebapiRepository if none is set */
  restEndpoint?: string;

  /** The default repository used for storing and retrieving instances of this model */
  defaultRepo?: IRepositoryBase;

  /** Default pagination handler used in the default repository */
  defaultPagination?: IPaginationHandler;

  /** Name of the fields that repositories using this model should try to generate */
  indexes?: string[];

  /** Singular name for instances of this model */
  singular?: string;

  /** Plural name for instances of this model */
  plural?: string;

  singularArticle?: string;

  pluralArticle?: string;

  /** Forms that will be used to edit instances of this model */
  forms?: { [name: string]: FormCreatorFunc; }; // [{ name: string; create: () => Form; }];

  /** CRUD settings for this model */
  cruds?: { [name: string]: CrudSettings; };

  /** Name of contexts where this model can be used */
  contextTypes?: string[];

  /** Local asset URL with an image representing this model */
  icon?: string;

  /** Configurable components of a the label used in CRUDS */
  crudLabelSettings?: CurdLabelComponent[];

  /** Custom titles to be used in programmatic components */
  titles?: Titles = new Titles(null);

  /** Main menu options tied to this model */
  menuOptions?: Menu;

  /** Declared repositories for this model */
  repos?: { [name: string]: IRepositoryBase; } = {};

  /** Identifies this model as transactional */
  transactional?: boolean;


  /** Event fired by a form of this model */
  // onFormEvent?: (eventType: FormEvent, form: Form, ctrl: Control, event: Event) => Promise<boolean>;
  onFormEvent?: (eventType: FormEvent, form: Form, ctrl: Control, event: Event, formCompo: FormComponent) => Promise<boolean>;
}

/** Describes the content of a model */
export class ModelDescriptor extends ModelArgs {

  onFormCreated = new Subject<Form>();
  onDataSaved = new Subject<any>();

  /** Repositories defined in metadata */
  repositories: RepositorySetting[];

  constructor(other: Partial<ModelArgs>) {
    super();
    if (other) {
      Object.assign(this, other);
    }
    if (!this.titles) {
      this.titles = new Titles(null);
    }
  }

  /** Form that will be used to edit instances of this model */
  createDefaultForm(): Form {
    return this.instantiateForm('default');
  }

  getRepo(name: string): IRepositoryBase {
    if (!this.repos) {
      console.log('Repository ' + name + ' not found in model ' + this.name);
      return this.defaultRepo;
    }
    if (!this.repos[name]) {
      console.log('Repository ' + name + ' not found in model ' + this.name);
      return this.defaultRepo;
    }
    return this.repos[name];
  }

  getDefaultForm(): () => Form {
    return this.getForm('default');
  }

  getForm(name: string) {
    if (!this.forms) { return null; }
    return this.forms[name];
  }

  instantiateForm(name?: string): Form {
    if (!name) { name = 'default'; }
    if (!this.forms || !this.forms[name]) {
      throw new Error('No existe formulario ' + name + ' para modelo ' + this.name);
    }
    const form = this.forms[name]();
    return form;
  }

  overrideForm(name: string, callback: (form: Form) => () => Form) {
    const frmName = name != null ? name : 'default';
    // console.log(frmName);
    const frm = this.getForm(frmName);
    this.forms[frmName] = () => {
      const newForm = callback(frm())();
      newForm.formName = newForm.formName + '_override';
      return newForm;
    };
  }

  createForm(name: string, callback: (form: Form) => () => Form) {
    const frmName = 'default';
    // console.log(frmName);
    const frm = this.getForm(frmName);
    this.forms[name] = callback(frm());
  }

  getSingularWithArticle() {
    if (this.singularArticle) {
      return this.singularArticle + ' ' + this.singular;
    } else {
      return this.singular;
    }
  }

  getPluralWithArticle() {
    if (this.pluralArticle) {
      return this.pluralArticle + ' ' + this.plural;
    } else {
      return this.plural;
    }
  }

  isInstanceSaved(instance: any): boolean {
    return instance[this.primaryKey];
  }

  isInstanceNotSaved(instance: any): boolean {
    return !instance[this.primaryKey];
  }
}

/** This is an internal global registry of all decorated models in the application */
const ModelRegistry: { [name: string]: ModelDescriptor; } = {};

/** Decoration that needs to be added into models for them to be used in the orm service  */
export function Model(params: ModelArgs) {
  // tslint:disable-next-line: only-arrow-functions
  return function <T extends new (...args: any[]) => {}>(constructor: T) {
    // console.log(params, constructor);
    params.ctor = constructor;

    constructor['_modelDescriptor'] = params.name;
    // constructor['_storeName'] = params.storeName;
    const mdl = new ModelDescriptor(params);
    mdl.name = params.name;
    // tslint:disable-next-line: no-string-literal
    constructor['_modelDescriptor'] = mdl;
    Object.assign(mdl, params);
    ModelRegistry[params.name] = mdl;
  };
}



