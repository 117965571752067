// tslint:disable: variable-name
import { Model } from '../../../lib/data/model';
import { Form } from 'src/app/lib/forms/Form';
import { CrudSettings } from 'src/app/lib/data/CrudSettings';

@Model({
  name: 'Presentacion',
  primaryKey: 'id',
  restEndpoint: 'precios/presentacion/',
  singular: 'presentación',
  plural: 'presentaciones',
  singularArticle: 'la',
  pluralArticle: 'las',
  forms: {
    default: () => {
      return new Form({
        title: 'Presentación',
        childControls: [
          { field: 'nombre', label: 'Singular', type: 'text', max: 50, required: true },
          { field: 'nombre_plural', label: 'Plural', type: 'text', max: 50, required: true },
        ]
      });
    }
  },
  cruds: {
    default: new CrudSettings({
      labelProperty: 'nombre_plural',
    })
  }
})
export class Presentacion {
  id: number;
  nombre: string;
  nombre_plural: string;
}
