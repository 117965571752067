<div class="container menu-width-limit" *ngIf="configServ.initialized">
  <ion-list *ngIf="mainMenuServ.settings.mainMenu.options">
    <ion-item *ngFor="let item of mainMenuServ.settings.mainMenu.options" button (click)="openOption(item)">
      <ion-icon slot="start" [name]="item.icon"></ion-icon>
      <ion-label>{{item.title}}</ion-label>
    </ion-item>
  </ion-list>

  <ion-card *ngFor="let grp of mainMenuServ.settings.mainMenu.groups" [hidden]="grp.hiddenByAuth">
    <ion-card-header (click)="mainMenuServ.toggleMenuGroup(grp)">
      <ion-card-title>
        {{grp.title}} <span *ngIf="grp.collapsed">({{grp.childOptions.length}})</span>
      </ion-card-title>
      <ion-button *ngIf="grp.closable" (click)="close(grp)" class="closeButton" fill="clear">
        <ion-icon name="close-outline" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-card-header>
    <ion-row [hidden]="grp.collapsed">
      <ion-col size-xs="4" size-xl="3" *ngFor="let item of grp.childOptions" [hidden]="isOptionHidden(item)">
        <div (click)="openOption(item)" [class]="'button ' + (item.isActive?'activeOption':'')">
          <div class="icon">
            <img [src]="item.icon">
          </div>
          <div class="iconText">
          {{item.title}}
          </div>
        </div>
      </ion-col>
    </ion-row>
  </ion-card>
</div>

<div class="bottom" *ngIf="configServ.initialized && mainMenuServ.settings.mainMenu.bottomOptions">
  <div class="">
    <ion-item *ngFor="let item of mainMenuServ.settings.mainMenu.bottomOptions" button (click)="openOption(item)">
      <ion-icon slot="start" [name]="item.icon"></ion-icon>
      <ion-label>{{item.title}}</ion-label>
    </ion-item>
  </div>
</div>