import { Injectable } from '@angular/core';
import { Vibration } from '@ionic-native/vibration/ngx';
import { ConfigService } from './config.service';
import { PlatformService } from './platform.service';

/**
 * https://ionicframework.com/docs/native/vibration
 * Installation:
 * > ionic cordova plugin add cordova-plugin-vibration
 * > npm install @ionic-native/vibration
 * - Add 'Vibration' in the app.module.ts providers section !
 */

@Injectable({
  providedIn: 'root'
})
export class VibrationService {

  constructor(
    private vibrationPlugin: Vibration,
    private platformServ: PlatformService) { }

  public vibrate() {
    if (!this.platformServ.isNative) { return; }
    this.vibrationPlugin.vibrate(1000);
  }
}
