// tslint:disable: variable-name

import { Model } from 'src/app/lib/data/model';
import { PagedPaginationHandler } from 'src/app/lib/data/webapi.repository';

export class CimquitoCliente {
  cliente: number;
  nombre_completo: string;
}

@Model({
  name: 'Visitas_cimquito',
  restEndpoint: 'visitas/cimquito/',
  primaryKey: 'not-set',
  defaultPagination: null
})
export class Cimquito {
  last_login: string;
  importes_generados: string;
  ventas_generadas: number;
  visitas_totales_ppr: number;
  folios_ppr_visitados: number;
  total_visitas: any[];
  clientes_habituales: CimquitoCliente[];
}
