// tslint:disable: align

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { AlertController, NavController } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import { Observable, timer, throwError } from 'rxjs';
import { retry, delayWhen, tap, retryWhen, mergeMap, finalize } from 'rxjs/operators';
import { Tools } from './tools';

/** Tipo de request que se desea hacer al webapi */
export enum RequestType { POST, DELETE, GET, PUT, PATCH }

/** Opciones adicionales que se pueden utilizar para un request */
export class WebapiCallOptions {
  noToken?= false;
  fullUrl?= false;
  // timeout ?= 15000;
  retries?= 3;
  ignoreOfflineBlock?= false;
}

const networkRetryStrategy = ({
  maxRetryAttempts = 4,
  scalingDuration = 1000,
  excludedStatusCodes = [400, 401, 404, 406, 500]
}: {
  maxRetryAttempts?: number,
  scalingDuration?: number,
  excludedStatusCodes?: number[]
} = {}) => (attempts: Observable<any>) => {
  return attempts.pipe(
    mergeMap((error, i) => {
      const retryAttempt = i + 1;
      // if maximum number of retries have been met
      // or response is a status code we don't wish to retry, throw error
      if (
        retryAttempt > maxRetryAttempts ||
        excludedStatusCodes.find(e => e === error.status)
      ) {
        return throwError(error);
      }
      console.log(
        `Attempt ${retryAttempt}: retrying in ${retryAttempt * scalingDuration}ms`
      );
      // retry after 1s, 2s, etc...
      return timer(retryAttempt * scalingDuration);
    }),
    finalize(() => console.log('Network call retrying done.'))
  );
};

@Injectable({
  providedIn: 'root'
})
export class WebapiService {
  token = '';
  webapiUrl = '';
  isOnline = true;
  private timeout = 15000;
  private logActivity = false;
  private offline = false;

  constructor(
    public httpClient: HttpClient) {
  }

  async setup(url: string) {
    this.webapiUrl = url;

  }

  setToken(token: string) {
    this.token = token;
  }

  get(endpoint: string, params?: any, opts?: WebapiCallOptions): Promise<any> {
    return this.doRequest(RequestType.GET, endpoint, params, opts);
  }

  post(endpoint: string, params?: any, opts?: WebapiCallOptions): Promise<any> {
    return this.doRequest(RequestType.POST, endpoint, params, opts);
  }

  patch(endpoint: string, params?: any, opts?: WebapiCallOptions): Promise<any> {
    return this.doRequest(RequestType.PATCH, endpoint, params, opts);
  }

  del(endpoint: string, id: any, opts?: WebapiCallOptions): Promise<any> {
    return this.doRequest(RequestType.DELETE, endpoint + id + '/', null, opts);
  }

  put(endpoint: string, params?: any, opts?: WebapiCallOptions): Promise<any> {
    return this.doRequest(RequestType.PUT, endpoint, params, opts);
  }

  private doRequest(type: RequestType, endpoint: string, params: any, opts: WebapiCallOptions): Promise<any> {
    if (this.logActivity) {
      console.log('Net activity ' + type.toString() + ' to ' + endpoint);
    }

    if (!opts) { opts = new WebapiCallOptions(); }

    let isWebapi = true;
    let apiurl = this.webapiUrl;

    let header = new HttpHeaders({ 'Content-Type': 'application/json' });
    if (type === RequestType.GET) {
      header = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    }
    if (this.offline && !opts.ignoreOfflineBlock) {
      throw new HttpErrorResponse({ status: 0, statusText: 'OFFLINE-MODE-ENABLED', error: 'OFFLINE-MODE-ENABLED' });
    }

    // Aplicar opciones
    if (opts.fullUrl) {
      // Usar url completa que viene desde el endpoint
      apiurl = '';
      isWebapi = false;
    }

    if (this.token && this.token.length > 0 && isWebapi) {
      // if (opts && !opts.noToken) {
      // } else {
      header = header.append('Authorization', this.token);
      // }
    }
    // console.log('TOKEN:', this.token);


    // Agregar CSRFToken si django lo puso en la cookie
    /*const csrftoken = this.getCookie('csrftoken');
    if (csrftoken) {
      header = header.append('X-CSRFToken', csrftoken);
    }*/

    /*if (endpoint.toLowerCase().indexOf('http') >= 0) {
      apiurl = '';
    }*/

    let request: Observable<any>;
    switch (type) {
      case RequestType.GET: {
        const httpparams = new HttpParams({ fromObject: params });
        request = this.httpClient.get(apiurl + endpoint, { headers: header, params: httpparams });
      } break;
      case RequestType.POST: {
        request = this.httpClient.post(apiurl + endpoint, params, { headers: header });
      } break;
      case RequestType.PATCH: {
        request = this.httpClient.patch(apiurl + endpoint, params, { headers: header });
      } break;
      case RequestType.DELETE: {
        request = this.httpClient.delete(apiurl + endpoint, { headers: header });
      } break;
      case RequestType.PUT: {
        request = this.httpClient.put(apiurl + endpoint, params, { headers: header });
      } break;
    }

    /*let retries = 3;
    if (opts) {
      retries = opts.retries;
    }*/
    // return;
    return request.pipe(retryWhen(networkRetryStrategy({ maxRetryAttempts: opts.retries }))).toPromise();
    /*
    return request.pipe(retryWhen(errors =>
      errors.pipe(
        // log error message
        // console.log('ERROR EN PIPE ',val)
        tap(val => console.log('Value ${val} was too high!', val)),
        // restart in 6 seconds
        delayWhen(val => timer(val * 1000))
      )
    )).toPromise();
    */
  }


  public async getObject<T>(endpoint: string, typeClass?: new () => T, params?: any, opts?: WebapiCallOptions): Promise<T> {
    if (!typeClass) {
      typeClass =  AnonymousClass as any; // To handle T as interface
    }
    let result = await this.doRequest(RequestType.GET, endpoint, params, opts);
    if (result) {
      if (result.results) {
        result = result.results; // Handle pagination
      }
      if (Array.isArray(result) || result.results) {
        const res: T[] = [];
        for (const obj in result) {
          if (result.hasOwnProperty(obj)) {
            const p = Tools.cloneAs(typeClass, result[obj]); //  Object.assign(new typeClass(), result[obj]);
            // res.push(p);
            return p;
          }
        }
      } else {
        const p = Tools.cloneAs(typeClass, result); // Object.assign(new typeClass(), result);
        return p;
      }
    } else {
      return null;
    }
  }

  public async getObjects<T>(endpoint: string, typeClass?: new () => T, params?: any, opts?: WebapiCallOptions): Promise<T[]> {
    if (!typeClass) {
      typeClass = AnonymousClass as any; // To handle T as interface
    }
    let result = await this.doRequest(RequestType.GET, endpoint, params, opts);
    const res: T[] = [];
    if (result) {
      if (result.results) {
        result = result.results; // Handle pagination
      }
      if (Array.isArray(result)) {
        for (const obj in result) {
          if (result.hasOwnProperty(obj)) {
            const p = Tools.cloneAs(typeClass, result[obj]); // Object.assign(new typeClass(), result[obj]);
            res.push(p);
          }
        }
      } else {
        const p = Tools.cloneAs(typeClass, result); // // const p = Object.assign(new typeClass(), result);
        res.push(p);
      }
    }
    return res;
  }
}

class AnonymousClass { }
