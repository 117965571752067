// tslint:disable: prefer-for-of
import { Component, Input, ComponentFactoryResolver, ViewChildren, ViewContainerRef, QueryList, OnInit, Output, EventEmitter } from '@angular/core';
import { DataService } from '../../services/data.service';
import { ModelDescriptor } from '../../data/model';
import { LoggerService } from '../../services/logger.service';
import { ConfigService } from '../../services/config.service';
import { IRepositoryBase } from '../../data/repository';
import { UiService } from '../../services/ui.service';
import { Tools } from '../../services/tools';
import { Form, FormEvent } from '../../forms/Form';
import { WebapiService } from '../../services/webapi.service';
import { FormGroup, FormControl, AbstractControl, Validators } from '@angular/forms';
import { Control } from '../../forms/Control';
import { ContextService } from '../../services/context.service';
import { ServerCodeService } from '../../services/server-code.service';

const DEBUG_LOG = false;

export interface ICompositeFormControl {
  childFormCreated(form: FormComponent);
}
function instanceOfICompositeFormControl(object: any): object is ICompositeFormControl {
  return object && 'childFormCreated' in object;
}

@Component({
  selector: 'lib-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
})
export class FormComponent implements OnInit {
  @ViewChildren('dynamic', { read: ViewContainerRef }) public widgetTargets: QueryList<ViewContainerRef>;
  @Input() model: ModelDescriptor;
  @Input() modelName: string;
  @Input() form: Form;
  @Input() formName: string;
  @Input() value: any;
  @Input() editAsCopy = true;
  @Input() repo: IRepositoryBase;
  @Input() repoName: string;
  @Input() isModal = false;
  @Input() syncAfterSave = true;
  @Input() readonly = false;
  @Input() parentForm: FormGroup;
  @Input() parentControl: Control;
  @Output() saved = new EventEmitter(true);
  @Output() deleted = new EventEmitter(true);
  @Output() canceled = new EventEmitter(true);
  @Input() isNew = false;
  isLoading = true;
  isDirty = false;
  isSaved = false;
  ngForm: FormGroup;
  // context: Context;

  constructor(
    public dataServ: DataService,
    public loggerServ: LoggerService,
    public configServ: ConfigService,
    public uiServ: UiService,
    public webapi: WebapiService,
    public contextServ: ContextService,
    public serverCode: ServerCodeService,
    public resolver: ComponentFactoryResolver) {
  }

  async ngOnInit() {

    //console.log(this.isNew);
    this.isLoading = true;

    // # Get model info
    if (!this.model && this.modelName) {
      this.model = this.dataServ.getModel(this.modelName);
    }
    if (!this.model) {
      // console.log('Model not configured correctly. Missing model property in form.component.');
      this.loggerServ.error('Form', 'Model not configured correctly. Missing model property in form.component.');
      return;
    }

    // # Get Form
    if (!this.form && this.formName) {
      this.form = this.dataServ.createForm(this.formName);
    }
    if (!this.form) {
      this.form = this.model.createDefaultForm();
    }
    if (!this.form) {
      this.loggerServ.error('Form', 'Form not found for model ' + this.model.name);
      return;
    }
    this.form.childControls = this.form.childControls.sort((a, b) => (a.order || 0) - (b.order || 0));

    // # Get repo
    if (!this.repo) {
      if (this.repoName) {
        this.repo = this.dataServ.getRepo(this.repoName);
      } else {
        this.repo = this.model.defaultRepo;
      }
      if (!this.repo) {
        this.loggerServ.error('Form', 'Repository not set for ' + this.model.name);
      }
    }

    // # Prepare value

    if (!this.value) {
      if (this.isNew === undefined) {
        this.isNew = true;
      }
      this.isSaved = false;
      this.value = new this.model.ctor();
    } else {
      if (this.value.id !== undefined) {
        this.isSaved = true;
      }
    }


    // # Setup contexts
    if (!this.form.context) {
      this.form.context = this.contextServ.createFromGlobalContext('form');
    }
    this.form.context.setValueObject('form', this.createContextAPI());
    if (this.parentControl) {
      const parentContext = (this.parentControl.parent as Form).context;
      this.form.context.setValueObject('parent', parentContext.values);
    }
    // this.form.context.setValue('form', 'isNew', this.isNew);

    /*for (const control of this.form.controls) {
      control.value = this.value[control.field];
    }*/
    // this.value.cliente = 'asdf';

    this.setupNgForm();

    // this.form.onChildChanged
    // console.log(this.form);

    this.isLoading = false;

    // # Resolve components for controls (after redrawing the list of containers)
    await this.uiServ.wait(10);

    this.loadControls();

    await this.uiServ.wait(10);
    this.setAutoValues();

    console.log('form initial state: ', this.form.ngFormGroup.value);

    this.form.ngComponent = this;
    this.form.formComponent = this;

    // this.form.context.setValueObject('form', this.form.ngComponent);
    // # Call external events
    if (this.model.onFormEvent) {
      await this.model.onFormEvent(FormEvent.OnCreate, this.form, null, null, this);
    }
    this.serverCode.execute(this.serverCode.FormComponentInitializedGrp, this.model.name, [this]);

    this.model.onFormCreated.next(this.form);
  }

  private loadControls() {
    const viewContainerRefs = this.widgetTargets.toArray();
    for (let i = 0; i < this.form.childControls.length; i++) {
      const control = this.form.childControls[i];
      // if (control.hidden) { continue; }
      // const factoryClass = FormControlComponentRegistry[control.type];
      const factoryClass = this.dataServ.controlRegistry[control.type];
      if (!factoryClass) {
        this.loggerServ.error('Form', 'Type of control not found ' + control.type);
        this.uiServ.error('Control "' + control.type + '" no encontrado para campo "' + control.field + '"', 'toast');
        continue;
      }
      const factory = this.resolver.resolveComponentFactory(factoryClass);
      const compRef = viewContainerRefs[i].createComponent(factory);
      control.ngComponent = compRef.instance;
      control.ngComponent.form = this.form;
      control.ngComponent.control = control;
    }

    this.form.onLoad.next(this.form);
  }

  async save() {
    // const cpy = JSON.parse(JSON.stringify(this.ngForm.value)); console.log('Before save: ', cpy);

    // # Update validation
    // this.ngForm.updateValueAndValidity();
    /* this.ngForm.updateValueAndValidity();
    this.updateControls();
    this.uiServ.wait(1);
    console.log(this.form);*/

    // console.log(this.ngForm.value);return;

    const allInvalidFields = this.getInvalidFields();
    if (allInvalidFields.length) {
      // this.ngForm.markAsTouched();
      console.log(allInvalidFields);
      let txt = 'No es posible guardar, ya que los siguientes campos no son validos:<b><br>';
      allInvalidFields.forEach(fieldName => {
        txt += '-' + fieldName + '<br>';
      });
      txt += '</b><br>Por favor corrija los campos marcados con rojo.';
      // console.error('Los siguientes campos no pasaron validación: ' + allInvalidFields.join(', '));
      // this.ngForm.markAllAsTouched();
      // this.uiServ.alert('Existen <b>' + allInvalidFields.length + '</b> campo(s) incorrectos. Por favor, verifique el contenido de los campos subrayados en rojo.');
      this.uiServ.alert(txt);
      // console.log(this.ngForm);
      return;
    }

    // # Server code form validation
    if (!this.serverCodeValidation()) {
      return;
    }
    if (this.form.onValidate) {
      try {
        const res = await this.form.onValidate(this.form);
        console.log(res);
        if (res) {
          this.uiServ.error(res);
          return;
        }
      } catch (err) {
        this.loggerServ.error('FORM_COMPONENT', 'Error en código de validación', err);
      }
    }

    const objectToSave = {};
    // const objectToSave = this.form.ngFormGroup.value;
    for (const ctrl of this.form.childControls) {
      const ctrlValue = ctrl.ngComponent.collectValue();
      if (ctrlValue !== undefined) {
        objectToSave[ctrl.field] = ctrlValue;
      }
    }
    // const objectToSave = objToSave; //Tools.clone(this.ngForm.value);
    console.log('Before save: ', objectToSave);
    /*for (let i = 0; i < this.form.controls.length; i++) {
      const control = this.form.controls[i];
      objectToSave[control.field] = control.ngControl.value;
    }*/

    await this.uiServ.loadingMessage('Guardando...');
    let saved = false;
    let results: any;
    try {
      results = await this.repo.upsert(objectToSave);
      saved = true;
    } catch (err) {
      await this.uiServ.error(err);
    } finally {
      await this.uiServ.clearLoadingMessage();
    }

    /*try {
      if (saved) {
        this.ngForm.patchValue(results);
      }
    } catch (err) {
      console.error(err);
    }*/

    try {
      if (saved) {
        this.saved.emit(results);
      }
    } catch (err) {
      this.uiServ.error(err, 'toast');
    }

    try {
      if (saved && this.form.onSaved) {
        this.form.onSaved();
      }
    } catch (err) {
      this.uiServ.error(err, 'toast');
    }

    try {
      if (saved && this.syncAfterSave) {
        // TODO clone back changes to original object
        Object.assign(results, this.value);
      }
      this.model.onDataSaved.next(results);
    } catch (err) {
      console.error(err);
    }
  }

  /** Updates the controls validity and isVisible state */
  updateControls() {
    this.form.childControls.forEach(ctrl => {
      if (ctrl.ngControl) {
        ctrl.ngControl.updateValueAndValidity();
      }

      if (ctrl.hiddenWhen) {
        this.form.computeIsControlHidden(ctrl);
      } else {
        ctrl.__isVisible = ctrl.hidden;
      }

      this.form.computeIsControlReadonly(ctrl);

      if (ctrl.childControls) {
        ctrl.childControls.forEach(childCtrl => {

          if (childCtrl.ngComponent instanceof FormComponent) {
            const childFormAbs = childCtrl.ngComponent as FormComponent;
            childFormAbs.updateControls();
          }

        });
      }
    });
  }

  async delete() {
    const text = '¿Está seguro que desea eliminar ' + this.model.getSingularWithArticle() + '?';
    if (!(await this.uiServ.preguntaAlerta(text))) {
      return;
    }
    await this.uiServ.loadingMessage('Borrando...');
    let okResult = false;
    try {
      await this.repo.delete(this.value);
      await this.uiServ.ok('Se ha eliminado ' + this.model.getSingularWithArticle() + ' correctamente');
      okResult = true;
    } catch (err) {
      this.uiServ.error(err);
    }

    try {
      if (okResult) {
        this.deleted.emit();
      }
    } catch (err) {
      this.uiServ.error(err, 'toast');
    }

    try {
      if (okResult && this.form.onDeleted) {
        this.form.onDeleted();
      }
    } catch (err) {
      this.uiServ.error(err, 'toast');
    }
  }

  async cancel() {
    this.canceled.emit();
  }

  execAsync(func: () => Promise<void>) {
    func().then().catch((err) => {
      console.log('Error in async exec:', err);
    });
  }

  private setupNgForm() {
    this.form.ngFormGroup = this.ngForm = new FormGroup({});

    // Nested form link
    if (this.parentControl && instanceOfICompositeFormControl(this.parentControl.ngComponent)) {
      this.parentControl.ngComponent.childFormCreated(this);
    }
    /*const parentForm = this.form.parent;
    if (parentForm && this.parentControl) {
      this.loggerServ.info('Form', 'Linked child form "' + this.parentControl.field + '" to parent');
      // this.ngForm.setParent(this.form.parent.ngForm);
      parentForm.ngForm.setControl(this.parentControl.field, this.ngForm);
    }*/

    // Create child controls
    // console.log(this.form.controls);
    for (const child of this.form.childControls) {
      child.parent = this.form;
      this.setupNgFormsChild(this.ngForm, child, this.form, this.value[child.field]);
    }

    // Connect change event
    this.form.childChanged = (input) => {
      /* if (this.form.onValueChange) {
        this.form.onValueChange(this, this.form, input);
      }*/
      this.form.onControlValueChanged.next(input);
      this.form.context.setValueObject('formValue', this.form.ngFormGroup.value);
      // console.log('Input changed ', input.field, this.ngForm);
    };

    // update context
    this.form.context.setValueObject('formValue', this.form.ngFormGroup.value);
    // this.form.context.setValue('form', 'ctrl', this.form.ngControl);
    // this.form.context.setValue('form', 'gpo', this.form.ngFormGroup);
    if (this.parentForm) {
      this.form.context.setValueObject('parentFormValue', this.parentForm.value);
    }
    // this.setupNgFormsChild(this.ngForm, this.form, this.value, true);
    // console.log(this.ngForm);
  }

  private setupNgFormsChild(parent: FormGroup, control: Control, parentControl?: Control, value?: any) {
    const input = new FormControl();
    input.setValue(value, { emitEvent: false });
    // console.log('Name ' + control.field, 'Value', value, 'required:', control.required);
    if (control.disabled) { input.disable(); }
    parent.addControl(control.field, input);
    control.parent = this.form;
    // console.log(control.field, control.required);
    /*if (control.required) {
      input.setValidators(Validators.required);
    }*/
    control.ngControl = input;
  }

  setAutoValues() {
    if (!this.isNew) { return; }
    for (const ctrl of this.form.childControls) {
      if (ctrl.autoValue) {
        const val = this.form.context.execute(ctrl.autoValue);
        // ctrl.ngControl.patchValue(val);
        const vals = {}; vals[ctrl.field] = val;
        // this.form.ngFormGroup.patchValue(vals);
        ctrl.ngControl.patchValue(val);
        // ctrl.ngControl.setValue(val);
        // ctrl.ngControl.value = val;
        console.log('Auto value ', ctrl.field, ' set to ', ctrl.ngControl.value);
        /*if (!DEBUG_LOG) {
          console.log('Auto value ', ctrl.field, ' set to ', val);
        }*/
      }
    }
  }

  getInvalidFields_old(form: FormGroup, depth: number, name?: string): string[] {
    // console.log('Revisando invalidos de ', (name ? name : 'raiz'), 'en nivel', depth, 'con', Object.keys(form.controls).length, 'controles');
    if (depth > 8) {
      throw Error('Limite de anidación detectado');
    }
    const invalidFields: string[] = [];
    const formControls = form.controls;
    // tslint:disable-next-line: forin
    for (const ctrlName in formControls) {
      if (!formControls.hasOwnProperty(ctrlName)) { return; }

      const ctrl = formControls[ctrlName];
      if (ctrl instanceof FormGroup) {
        const invalidChildFields = this.getInvalidFields_old(ctrl, depth + 1, ctrlName);
        if (invalidChildFields.length) {
          invalidFields.push(...invalidChildFields);
        }
      } else if (ctrl.invalid) {
        invalidFields.push((name ? name + '.' : '') + ctrlName);
      }
    }
    return invalidFields;
  }

  getInvalidFields(): string[] {
    return this.getInvalidFieldsRecursive(this.form, 0);
  }

  private getInvalidFieldsRecursive(form: Control, depth: number, name?: string): string[] {
    // console.log('Revisando invalidos de ', (name ? name : 'raiz'), 'en nivel', depth, 'con', Object.keys(form.childControls).length, 'controles');
    if (depth > 8) {
      throw Error('Limite de anidación detectado');
    }
    const invalidFields: string[] = [];
    const formControls = form.childControls;
    // tslint:disable-next-line: forin
    for (const ctrl of formControls) {
      if (ctrl.childControls) {
        ctrl.childControls.forEach(childCtrl => {
          const invalidChildFields = this.getInvalidFieldsRecursive(childCtrl, depth + 1, ctrl.label);
          if (invalidChildFields.length) {
            invalidFields.push(...invalidChildFields);
          }
        });
      }
      ctrl.ngControl.markAsTouched({ onlySelf: true });
      ctrl.ngControl.updateValueAndValidity({ onlySelf: true, emitEvent: false });
      if (ctrl.ngControl && ctrl.ngControl.invalid) {
        // invalidFields.push((name ? name + '.' : '') + ctrl.label);
        invalidFields.push(ctrl.label);
      }
      // console.log(ctrl.label, ctrl.ngControl.invalid, ctrl.ngControl.value);
    }
    return invalidFields;
  }

  canSave() {
    if (this.form.disableSave) {
      return false;
    }
    if (this.form.disableChange && this.isSaved) {
      return false;
    }
    return true;
  }

  createContextAPI() {
    let parentApi = undefined;
    if (this.parentControl) {
      // TODO add parent form api access
    }
    return {
      isNew: () => this.isNew,
      get: () => this.form,
      ctrl: (name: string) => this.form.getControl(name),
      comp: (name: string) => this.form.getControl(name).ngComponent,
      value: (name: string) => this.form.getValue(name),
    };
  }

  serverCodeValidation() {
    const res = this.serverCode.execute(
      this.serverCode.FormValidateBeforeSaveGrp,
      this.model.name,
      [this.form, this]);
    if (res) {
      this.uiServ.error(res);
      return false;
    }
    return true;
  }
}
