import { formatDate } from '@angular/common';
// import * as deepmerge from 'deepmerge';

export function getPropFromObjects(prop: string, object: {}) {
  if (!object) {
    return null;
  }
  if (typeof object === 'object') {
    if (object[prop]) {
      return object[prop];
    }
    // tslint:disable-next-line: forin
    for (const val in object) {
      const childVal = getPropFromObjects(prop, object[val]);
      if (childVal) {
        return childVal;
      }
    }
  }
  return null;
}

export class AsyncValue<T> {
  value: T;
  private waitingForValue: (() => void)[] = [];

  getValue(): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      if (this.value) {
        resolve(this.value);
      } else {
        this.waitingForValue.push(() => {
          resolve(this.value);
        });
      }
    });
  }

  getValueTimeout(ms: number): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      if (this.value) {
        resolve(this.value);
      } else {
        this.waitingForValue.push(() => {
          resolve(this.value);
        });
        setTimeout(() => {
          // this.waitingForValue.re
          reject();
        }, ms);
      }
    });
  }

  setValue(value: T) {
    this.value = value;
    for (const waiter of this.waitingForValue) {
      waiter();
    }
    this.waitingForValue = [];
  }
}

export const Tools = {
  dateMMDDYYYYtoDate(date: string): Date {
    if (!date || !date.length || date.length !== 10) { return null; }
    let split: string[] = date.split('/');
    if (split.length !== 3) { split = date.split('-'); }
    if (split.length !== 3) { return null; }
    return new Date(parseInt(split[0], 10), parseInt(split[1], 10), parseInt(split[2], 10));
  },


  // YYYY_MM_DD
  /*dateDjangoToDate(date: string): Date {
    if (!date || !date.length || date.length !== 10) { return null; }
    let split: string[] = date.split('-');
    if (split.length !== 3) { split = date.split('-'); }
    if (split.length !== 3) { return null; }
    return new Date(parseInt(split[0], 10), parseInt(split[1], 10), parseInt(split[2], 10));
  },*/

  expandArrayToFit<T>(length: number, array: T[], newValueCtr: () => T) {
    while (length > array.length) {
      array.push(newValueCtr());
    }
  },

  // ISO 8601 date CCYY-MM-DDThh:mm:ssTZD
  Fecha_YYYY_MM_DD(tdate?: Date): string {
    let date: Date;
    if (tdate) {
      date = new Date(tdate);
    } else {
      date = new Date();
    }
    let day: any = date.getDate();
    if (day < 10) { day = '0' + day; }
    let mnth: any = date.getMonth() + 1;
    if (mnth < 10) { mnth = '0' + mnth; }
    return date.getFullYear() + '-' + mnth + '-' + day;
  },

  isValidDjangoDate(date: string): boolean {
    if (!date || !date.length || date.length !== 10) { return false; }
    let split: string[] = date.split('-');
    if (split.length !== 3) { split = date.split('-'); }
    if (split.length !== 3) { return false; }
    return true;
  },

  replaceAll(word: string, terms: string[], withd: string = ''): string {
    let replaced = word;
    for (const term of terms) {
      const regex = new RegExp(term, 'g');
      replaced = replaced.replace(regex, withd);
    }
    return replaced;
  },

  cleanEmail(email: string): string {

    // value = value.trim().toLowerCase().replace(/[^\w@.]/gi, '');
    // value = Tools.replaceAll(value.trim().toLowerCase(), this.aLimpiar, '');
    return email.trim().toLowerCase().replace(/[^a-zA-Z0-9_\/\-@.]/g, ''); ///.replace(/=/g, '').replace(/?/g, '');
  },

  bytesToSize(bytes, decimals = 2): string {
    if (bytes === 0) { return '0 Bytes'; }

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  },

  remove<T>(array: T[], value: T) {
    const index = array.indexOf(value);
    if (index > -1) {
      array.splice(index, 1);
    }
  },

  dateLong(date: string | number | Date): string {
    return formatDate(date, 'd/M/yyyy h:mm:ss a', 'es-MX');
  },

  clone(obj: any) {
    // return deepmerge(objA, objB);
    return Object.assign({}, obj);
  },

  cloneAs<T>(ctr: new () => T, objectSource: any): T {
    const clone = new ctr();
    return Object.assign(clone, objectSource);
    // return deepmerge(clone, objectSource);
  },

  clearObject(obj: any) {
    for (const prop of Object.getOwnPropertyNames(obj)) {
      delete obj[prop];
    }
  },

  assignTo(target: any, source: any): any {
    return Object.assign(target, source);
  },

  assignCleanTo(target: any, source: any): any {
    // Tools.clearObject(target);
    Object.assign(target, source);
    return target;
  },

  assignCleanToAs<T>(ctr: new () => T, target: any, source: any): T {
    // const rt = deepmerge(new ctr(), source);
    // Tools.clearObject(target);
    Object.assign(target, source);
    return target;
  }

};
