import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { InitGuard } from 'src/app/lib/services/init.guard';
import { ContextGuard } from 'src/app/lib/services/context.guard';


const routes: Routes = [
  { path: 'visitas', canActivate: [InitGuard], loadChildren: () => import('./pages/visita-crud/visita-crud.module').then(m => m.VisitaCrudPageModule) },
  { path: 'visitas/:contexts', canActivate: [InitGuard, ContextGuard], loadChildren: () => import('./pages/visita-crud/visita-crud.module').then(m => m.VisitaCrudPageModule) },
];


@NgModule({
  declarations: [],
  imports: [
    RouterModule.forChild(routes),
    CommonModule
  ],
  exports: [
    RouterModule
  ]
})
export class VisitasModule { }
