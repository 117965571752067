import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'lib-loading-panel',
  templateUrl: './loading-panel.component.html',
  styleUrls: ['./loading-panel.component.scss'],
})
export class LoadingPanelComponent implements OnInit {
  @Input() loadingText = 'Cargando...';
  @Input() showSpinner = true;
  @Input() showIcon = false;
  @Input() iconImg = '';

  constructor() { }

  ngOnInit() {}

}
