import { Component, Input, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { Control } from '../../Control';
import { Form } from '../../Form';
import { AbsControlComponent } from '../AbsControlComponent';

// tslint:disable-next-line: max-line-length
const EMAILPATTERN = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
// tslint:disable-next-line: max-line-length
// const URLPATTERN = /^(?:http|ftp)s?:\/\/(?:(?:[A-Z0-9](?:[A-Z0-9-]{0,61}[A-Z0-9])?\.)+(?:[A-Z]{2,6}\.?|[A-Z0-9-]{2,}\.?)|localhost|\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}|\[?[A-F0-9]*:[A-F0-9:]+\]?)(?::\d+)?(?:\/?|[/?]\S+)$/i;

@Component({
  selector: 'app-email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.scss'],
})
export class EmailComponent extends AbsControlComponent implements OnInit {
  @Input() control: Control;
  @Input() form: Form;

  constructor() {
    super();
  }

  ngOnInit() {
    this.setupAbsControl([Validators.pattern(EMAILPATTERN), ...this.createTextFieldRequirements()]);
  }

  getValidatorErrorMessage(name: string) {
    if (name === 'pattern') {
      return 'Email invalido';
    }
  }

}
