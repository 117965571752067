// tslint:disable: variable-name
import { Model } from '../../../lib/data/model';
import { BaseUser } from '../../../lib/data/BaseUser';

@Model({ name: 'Usuario', primaryKey: 'id', restEndpoint: 'api/sistema/usuarios/' })
export class Usuario extends BaseUser {
  es_comite = false;
  puesto: string;
  jefe_directo: number;
  sucursales: number[];
  // usuarios_apoyo: [{ id: number, puesto: string, respuestas: string }];
  // respuestas: string;
  banderas: [{ nombre: string, valor: string }];

  tiene_bandera(nombre: string): boolean {
    if (!this.banderas) {
      return false;
    }
    return this.banderas.filter(iter => iter.nombre === nombre).length > 0;
  }
}
