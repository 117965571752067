import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Presentacion } from './models/presentacion';
import { Producto } from './models/producto';
// import { Moneda } from './models/moneda';
import { Sucursal } from './models/sucursal';
import { TipoDePrecio } from './models/tipoDePrecio';
import { Precios } from './models/precios';
import { Casos } from './models/casos';
import { Seguimientos } from './models/seguimientos';
import { Aprobaciones } from './models/aprobaciones';
import { Especificacion } from './models/especificacion';
import { Routes, RouterModule } from '@angular/router';
import { PreciosPage } from './pages/precios/precios.page';
import { InitGuard } from 'src/app/lib/services/init.guard';
import { UsuarioPrecios } from './models/usuarioPrecios';
import { PreciosNotificationsService } from './services/precios-notifications.service';
// import { Medida } from './models/medida';

const routes: Routes = [
  {
    path: 'precios', canActivate: [InitGuard], loadChildren: () => import('./pages/precios/precios.module').then(m => m.PreciosPageModule)
  },
  {
    path: 'precios/caso/:id', canActivate: [InitGuard], loadChildren: () => import('./pages/caso/caso.module').then(m => m.CasoPageModule)
  },
  {
    path: 'precios/caso/:id/', canActivate: [InitGuard], loadChildren: () => import('./pages/caso/caso.module').then(m => m.CasoPageModule)
  },
  {
    path: 'precios/lista', canActivate: [InitGuard], loadChildren: () => import('./pages/lista-v2/lista-v2.module').then(m => m.ListaV2PageModule)
  }
];

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forChild(routes),
    CommonModule
  ],
  exports: [RouterModule]
})
export class PreciosModule {

  constructor(preciosService: PreciosNotificationsService) {
    // console.log('PreciosModule SETUP!');
  }

}


export const PreciosModels: any[] = [
  Presentacion, Producto, Casos, Seguimientos, Aprobaciones, TipoDePrecio, Precios, Sucursal, Especificacion, UsuarioPrecios
];
