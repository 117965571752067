// tslint:disable: variable-name
import { Injectable, EventEmitter } from '@angular/core';
import { WebapiService } from './webapi.service';
import { AsyncValue } from './tools';
import { Tools } from './tools';
import { AlertController, LoadingController, NavController } from '@ionic/angular';
import { HttpErrorResponse, HttpClient } from '@angular/common/http';
import { LoggerService } from './logger.service';
import { ConfigService } from './config.service';
import { BehaviorSubject } from 'rxjs';
import { BaseUser } from '../data/BaseUser';
import { IUiService } from './ui.service.interface';
import { RuntimeServices } from './runtimeServices';


export class AuthSettings {
  allowPasswordReset = true;
  resetPassSendCodeUrl = 'solicitar_codigo';
  resetPassConfirmCodeUrl = 'reestablecer_password';
  loginUrl = 'token-auth/';
  logoutUrl = '';
  ignoreUrlLogutErrors = true;
  tokenRefreshUrl = 'token-refresh/';
  tokenVerifyUrl = 'token-verify/';
  localStoragePrefix = 'app_';
  getUserUrl = undefined;
  logoutRedirectPage = undefined;
  autoRefreshTokenInterval = 1000 * 60 * 30; // Set to undefined or 0 to disable feature
  refreshPageOnLogout = true;
  changePasswordUrl = 'password_change/';
  ceresAuthEnabled = true;
  ceresAuthUrl = '';
  ceresAuthApp = '';
  userModel: new () => BaseUser;
  userGlobalContext = 'user';
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  /** Backend token prefixed with 'Bearer ' */
  bearerToken: string;
  /** Last obtained unedited backend tokens */
  accessToken: string;
  refreshToken: string;
  isLoggedIn = false;
  userId: number;
  userName: string;
  settings: AuthSettings;

  /** Currently logged user */
  user: BaseUser;

  /** Async utility, used for waiting for the user to login */
  waitForLogin = new AsyncValue<boolean>();

  /** Event fired when the user logs in or logs out */
  onUserLoginChange = new BehaviorSubject<boolean>(false);

  readonly bearerTokenStorageId = 'Bearer';
  readonly accessTokenStorageId = 'access';
  readonly refreshTokenStorageId = 'refresh';
  readonly usernameStorageId = 'username';
  readonly userIdStorageId = 'userId';

  private refreshTokenSubscription = null;

  private uiServ: IUiService;

  errorCodesMessages = {
    471: 'Las credenciales proporcionadas son incorrectas.',
    472: 'Las credenciales proporcionadas son incorrectas.',
    473: 'No tiene permiso para acceder a esta aplicación. Por favor solicite al departamento de sistemas acceso.',
    474: 'No se puede iniciar sesión desde esta aplicación.',
    475: 'Tiempo para ingresar código excedido.',
    476: 'Código incorrecto. Inténtelo de nuevo.'
  };
  sendCodeMessages = {
    272: 'Ingrese él código de acceso enviado por SMS.',
    273: 'Ingrese él código de acceso enviado por email.',
    274: 'Ingrese él código mostrado en su app de autenticación.',
  };

  constructor(
    public webapiServ: WebapiService,
    public alertCtrl: AlertController,
    private loggerServ: LoggerService,
    private navCtrl: NavController,
    private httpClient: HttpClient,
    private configServ: ConfigService,
    public ldgCtrl: LoadingController) { }

  async setup(settings: AuthSettings) {
    this.uiServ = RuntimeServices.uiServ;
    try {
      this.settings = settings;
      this.bearerToken = localStorage.getItem(settings.localStoragePrefix + this.bearerTokenStorageId);
      this.userName = localStorage.getItem(settings.localStoragePrefix + this.usernameStorageId);
      this.accessToken = localStorage.getItem(settings.localStoragePrefix + this.accessTokenStorageId);
      this.refreshToken = localStorage.getItem(settings.localStoragePrefix + this.refreshTokenStorageId);
      if (this.bearerToken) {
        // this.refreshToken();
        this.isLoggedIn = true;
        this.webapiServ.setToken(this.bearerToken);
        await this.updateTokens();
        this.updateRefreshCallback();
        this.onUserLoginChange.next(this.isLoggedIn);
        // this.onUserLoginChange.emit(this.isLoggedIn);
      }

      if (this.isLoggedIn && settings.getUserUrl && settings.userModel) {
        await this.getUser(settings.userModel);
        if (this.user.password_invalido) {
          this.uiServ.clearLoadingMessage();
          await this.passwordChange();
        }
      }
    } catch (err) {
      await this.logout();
    }
    // await this.verifyToken();
    // console.log('TOKEN:', localStorage.getItem(this.bearerTokenStorageId));
  }

  public async login(user: string, pass: string) {
    await this.uiServ.loadingMessage('Iniciando sesión...');

    // 2 factor auth and check app access
    if (this.settings.ceresAuthEnabled) {
      const result = await this.ceresAuthPreLogin(user, pass);
      if (!result) { return; }
    }

    // this.clearToken();
    const url = this.settings.loginUrl; // 'api/token-auth/';
    const params = { username: user, password: pass };
    const response = await this.webapiServ.post(url, params);
    this.readToken(response);
    this.isLoggedIn = true;
    this.waitForLogin.setValue(true);
    this.onUserLoginChange.next(this.isLoggedIn);
    this.webapiServ.setToken(this.bearerToken);
    if (this.isLoggedIn && this.settings.getUserUrl && this.settings.userModel) {
      await this.getUser(this.settings.userModel);
    }
    this.updateRefreshCallback();
  }

  private async ceresAuthPreLogin(user: string, pass: string): Promise<boolean> {

    const ceresAuthParams = {
      user,
      password: pass,
      app: this.settings.ceresAuthApp,
      code: 0
    };
    try {
      const authInfo = await this.httpClient.post(this.settings.ceresAuthUrl + '/auth/prelogin/', ceresAuthParams).toPromise() as number;
      if (authInfo === 271) {
        return true;
      }
      const msg = await this.alertCtrl.create({
        message: this.sendCodeMessages[authInfo],
        inputs: [{ type: 'number', name: 'code' }],
        buttons: [{ text: 'Aceptar' }]
      });
      await this.uiServ.clearLoadingMessage();
      await msg.present();
      const res = await msg.onDidDismiss();
      if (res.role !== 'cancel') {
        const code = res.data.values.code;
        console.log(res);
        ceresAuthParams.code = code;
        await this.uiServ.loadingMessage('Autenticando...');
        try {
          const result = await this.httpClient.post(this.settings.ceresAuthUrl + '/auth/code/', ceresAuthParams).toPromise();
          await this.uiServ.clearLoadingMessage();
          return true;
        } catch (err) {
          this.loggerServ.error('AuthServ', 'Falló autenticación a CeresAuth', err);
          if (err instanceof HttpErrorResponse) { await this.uiServ.error(this.errorCodesMessages[err.status]); }
        }
      }

    } catch (err) {
      this.loggerServ.error('AuthServ', 'Falló autenticación a CeresAuth', err);
      if (err instanceof HttpErrorResponse && this.errorCodesMessages[err.status]) {
        await this.uiServ.error(this.errorCodesMessages[err.status]);
      } else {
        await this.uiServ.error(err);
      }
    }
    return false;
  }

  public async logout(ask?: boolean) {
    if (ask) {
      const wants = await this.uiServ.pregunta('¿Está seguro que desea terminar la sesión?');
      if (!wants) { return; }
    }

    // this.clearToken();
    if (this.settings.logoutUrl) {
      try {
        const response = await this.webapiServ.post(this.settings.logoutUrl);
      } catch (err) {
        if (this.settings.ignoreUrlLogutErrors) {
          this.loggerServ.error('AuthServ', 'Error loging out', err);
        } else {
          throw err;
        }
      }
    }
    this.isLoggedIn = false;
    this.user = null;
    this.updateRefreshCallback();
    localStorage.removeItem(this.settings.localStoragePrefix + this.bearerTokenStorageId);
    localStorage.removeItem(this.settings.localStoragePrefix + 'tokenTime');
    localStorage.removeItem(this.settings.localStoragePrefix + 'lastlogin');
    localStorage.removeItem(this.settings.localStoragePrefix + this.userIdStorageId);
    localStorage.removeItem(this.settings.localStoragePrefix + this.usernameStorageId);
    this.webapiServ.setToken(null);
    this.waitForLogin.value = false;
    this.configServ.initialized = false;
    if (this.settings.refreshPageOnLogout) {
      if (this.settings.logoutRedirectPage !== undefined) {
        window.location.href = this.settings.logoutRedirectPage;
      } else {
        window.location.reload();
      }
    } else {
      this.onUserLoginChange.next(this.isLoggedIn);
      if (this.settings.logoutRedirectPage !== undefined) {
        this.navCtrl.navigateRoot(this.settings.logoutRedirectPage, { animated: true });
      }
    }
  }

  private readToken(jwt: any) {
    // console.log(response);
    try {
      const parts = jwt.access.split('.');
      const jsonString = atob(parts[1]);
      const decodedData = JSON.parse(jsonString);
      // console.log(decodedData);
      this.userId = decodedData.user_id;
      this.userName = decodedData.username;
      const expiresIn = decodedData.exp;
      // console.log(decodedData, Date.now());
    } catch (err) {
      console.error(err);
      this.loggerServ.error('AuthService', 'Falló decodificación de token', err);
    }
    // this.bitacora.info('WEBAPI', 'Inicio correcto de sesión');
    this.bearerToken = 'Bearer ' + jwt.access;
    this.refreshToken = jwt.refresh;
    this.accessToken = jwt.access;
    localStorage.setItem(this.settings.localStoragePrefix + this.bearerTokenStorageId, this.bearerToken);
    localStorage.setItem(this.settings.localStoragePrefix + this.refreshTokenStorageId, this.refreshToken);
    localStorage.setItem(this.settings.localStoragePrefix + this.accessTokenStorageId, this.accessToken);
    localStorage.setItem(this.settings.localStoragePrefix + 'lastlogin', (new Date()).getTime().toString());
    localStorage.setItem(this.settings.localStoragePrefix + 'tokenTime', Date.now().toString());
    localStorage.setItem(this.settings.localStoragePrefix + this.userIdStorageId, this.userId.toString());
    localStorage.setItem(this.settings.localStoragePrefix + this.usernameStorageId, this.userName);
  }

  private getCookie(name: string): string {
    const nameLenPlus = (name.length + 1);
    return document.cookie
      .split(';')
      .map(c => c.trim())
      .filter(cookie => {
        return cookie.substring(0, nameLenPlus) === `${name}=`;
      })
      .map(cookie => {
        return decodeURIComponent(cookie.substring(nameLenPlus));
      })[0] || null;
  }

  async getUser<T extends BaseUser>(typeClass: new () => T): Promise<T> {
    if (this.user) { return this.user as T; }
    this.user = await this.webapiServ.getObject(this.settings.getUserUrl, typeClass);
    return this.user as T;
  }

  async updateTokens() {
    const token = this.refreshToken;
    try {
      const res = await this.webapiServ.post(this.settings.tokenRefreshUrl, { refresh: token });
      if (res && res.token) {
        // this.bitacora.info('WEBAPI', 'Token refrescado correctamente');
        this.readToken(res);
      }
    } catch (err) {
      if (err && (err.status === 401)) { // 401 -> auth token invalido
        // Sesión terminada. Logead de nuevo.
        // this.bitacora.error('WEBAPI', 'TOKEN INVALIDO, NECESARIO INICIAR SESION DE NUEVO');
        const msg = await this.alertCtrl.create({ header: 'Alerta', message: 'Es necesario iniciar sesión de nuevo.' });
        await msg.present();
        await msg.onDidDismiss();
        await this.logout();
        // throw new Error(err);
      }
      console.log('No se pudo refrescar token');
    }
  }

  async resetPassSendCode(user: string) {
    const result = await this.webapiServ.post(this.settings.resetPassSendCodeUrl, { email: user }, { fullUrl: true });
    return result;
  }

  async restPassConfirm(user: string, code: string, newPass: string) {
    //api/rest-auth/password/reset/
    //api/rest-auth/password/change/
    const params = {
      user_email: user,
      email_code: code,
      new_password: newPass,
    };
    return await this.webapiServ.post(this.settings.resetPassConfirmCodeUrl, params, { fullUrl: true });
  }

  async verifyToken() {
    try {
      const token = this.bearerToken.replace('Bearer ', '');
      await this.webapiServ.post(this.settings.tokenVerifyUrl, { token });
    } catch (err) {
      console.error(err);
      if (err instanceof HttpErrorResponse) {

      }
    }
  }

  async passwordChange(): Promise<boolean> {
    let msg = '';
    if (this.user.password_invalido) {
      msg = 'Es necesario ingresar un nuevo password para poder continuar';
    }
    const buttons = [{ text: 'Aceptar', role: 'OK' }];
    if (!this.user.password_invalido) {
      buttons.push({ text: 'Cancelar', role: 'cancel' });
    }
    const dlg = await this.alertCtrl.create({
      header: 'Cambiar contraseña',
      message: msg,
      inputs: [{
        id: 'current',
        name: 'current',
        type: 'password',
        attributes: { autocomplete: 'false' },
        placeholder: 'Contraseña actual'
      }, {
        id: 'new',
        name: 'new',
        type: 'password',
        attributes: { autocomplete: 'false' },
        placeholder: 'Nueva contraseña'
      }, {
        id: 'confirm',
        name: 'confirm',
        type: 'password',
        attributes: { autocomplete: 'false' },
        placeholder: 'Confirmar contraseña'
      }],
      buttons
    });
    await dlg.present();
    const res = await dlg.onDidDismiss();
    console.log(res);
    if (res.role === 'OK') {
      const vals = res.data.values;
      if (vals.new === vals.current) {
        this.uiServ.alert('La nueva contraseña es igual a la actual.');
        return;
      }
      if (vals.new !== vals.confirm) {
        this.uiServ.alert('La nueva contraseña no coincide con la confirmación.');
        return;
      }

      await this.uiServ.loadingMessage('Solicitando cambio...');
      try {
        const result = await this.webapiServ.put(this.settings.changePasswordUrl, { old_password: vals.current, new_password: vals.new });
        this.uiServ.clearLoadingMessage();
        this.uiServ.ok('Password actualizado');
        return true;
      } catch (err) {
        this.uiServ.error(err);
      }
      return false;
    }
  }

  redirectToUserHomePage(defaultPage: string) {
    if (this.user) {
      console.log(this.user);
      // await this.uiServ.wait(100);
      // await
      const userPage = this.user.pagina_inicial || defaultPage;
      this.navCtrl.navigateRoot(userPage, { animated: false });
      // (window.location as any) = '/';

    }
  }

  private updateRefreshCallback() {
    // console.log(this.refreshTokenSubscription, this.settings.autoRefreshTokenInterval);
    if (this.refreshTokenSubscription) {
      window.clearInterval(this.refreshTokenSubscription);
    }
    if (!this.settings ||
      !this.settings.autoRefreshTokenInterval ||
      !this.isLoggedIn) {
      return;
    }
    this.refreshTokenSubscription = window.setInterval(async (self: AuthService) => self.updateTokens(), this.settings.autoRefreshTokenInterval, this);
  }

}
