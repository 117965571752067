import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { LoggerService } from './logger.service';
import { UiService } from './ui.service';
import { WebapiService } from './webapi.service';

@Injectable({
  providedIn: 'root'
})
export class ServerCodeService {
  readonly FormValidateBeforeSaveGrp = 'FormValidateBeforeSave';
  readonly FormComponentInitializedGrp = 'FormComponentInitialized';

  constructor(
    private webapiServ: WebapiService,
    private loggerServ: LoggerService,
    private dataServ: DataService,
    private uiServ: UiService
  ) { }

  /** Container of all code (functions and values) stored by server script */
  serverCode: { [name: string]: { [name: string]: any; }; } = {};

  getCodeGroup(name: string): { [name: string]: any; } {
    if (!this.serverCode[name]) {
      this.serverCode[name] = {};
    }
    return this.serverCode[name];
  }

  async loadCodeFromFile(fullRoute: string, cache = true) {
    try {
      const res = await this.webapiServ.get(fullRoute);
      await this.loadCode(res);
      if (cache) {
        localStorage['serverCodeCache' + fullRoute] = res;
      }
    } catch (err) {
      console.log(err);
      this.uiServ.error(err);
      if (cache) {
        this.loadFromCache(fullRoute);
      }
    }
  }

  private async loadFromCache(fullRoute: string) {
    const lastCode = localStorage['serverCodeCache' + fullRoute];
    if (lastCode) {
      await this.loadCode(lastCode);
    }
  }

  private async loadCode(code: string) {
    // tslint:disable-next-line: no-eval
    const obj = eval(code);
    // tslint:disable-next-line: no-string-literal
    if (window['FrontendCodeLoaded']) {
      // tslint:disable-next-line: no-string-literal
      window['FrontendCodeLoaded'](this);
    }
  }

  execute(group: string, funcName: string, args: any[]) {
    const grp = this.getCodeGroup(group);
    try {
      if (grp[funcName]) {
        if (args) {
          return grp[funcName](...args);
        } else {
          return grp[funcName]();
        }
      }
    } catch (err) {
      console.error(err);
      this.loggerServ.error('ServerCode', 'Fallo en ejecución de código de servidor "' + funcName + '" de grupo ' + group, err);
    }
    return null;
  }
}
