import { Component, OnInit, Input } from '@angular/core';
import { Control } from '../../Control';
// import { FormControlComponent } from 'src/app/lib/forms/controls/ControlRegistry';
import { Form } from '../../Form';
import { DateControlArgs } from './date.args';
import { AbsControlComponent } from '../AbsControlComponent';
import { DatePipe } from '@angular/common';
import { FormControl } from '@angular/forms';
import { add, formatISO } from 'date-fns';
import { DateService, DayNamesShortEsp, MonthNamesEsp, MonthNamesShortEsp } from 'src/app/lib/services/date.service';


// @FormControlComponent('date', DateControlArgs)
@Component({
  selector: 'app-date',
  templateUrl: './date.component.html',
  styleUrls: ['./date.component.scss'],
})
export class DateComponent extends AbsControlComponent implements OnInit {
  @Input() control: Control;
  @Input() form: Form;
  monthNames = MonthNamesEsp;
  monthShortNames = MonthNamesShortEsp;
  dayNames = DayNamesShortEsp;
  args: DateControlArgs;
  UIControl: FormControl;
  customPickerOptions: any;
  minDate: string;
  maxDate: string;

  constructor(private datePipe: DatePipe, private dateServ: DateService) {
    super();

    this.customPickerOptions = {
      buttons: [{
        text: 'Cancelar',
        role: 'cancel',
        handler: () => console.log('Clicked Save!')
      }, {
        text: 'Limpiar',
        handler: () => { this.UIControl.patchValue(null); }
      }, {
        text: 'Aceptar',
        handler: (data) => {
          // console.log('Data', data);
          const today = new Date();
          const year = data.year ? data.year.value : today.getFullYear();
          const day = data.day ? data.day.value : today.getDate();
          const month = data.month ? data.month.value - 1 : today.getMonth();
          const hour = data.hour ? data.hour.value : today.getHours();
          const minute = data.minute ? data.minute.value : today.getMinutes();
          const val = new Date(year, month, day, hour, minute);
          const utc = this.dateServ.toDatetimeUTC(val);
          // console.log('Data', data, val, utc);
          this.UIControl.patchValue(utc);
        }
      }]
    };
  }

  ngOnInit() {
    this.setupAbsControl();
    if (this.control.args) {
      this.args = this.control.args as DateControlArgs;
    } else {
      this.args = new DateControlArgs();
    }
    const baseDate = new Date();
    let date: Date;

    // Min date:
    if (this.args.min === 'now') {
      date = baseDate;
    } else if (this.args.minRel) {
      date = add(baseDate, this.args.minRel);
    } else {
      date = add(baseDate, { months: -12 });
    }
    this.minDate = formatISO(date);
    // console.log(baseDate, date, this.args.min);

    // Max date:
    if (this.args.max === 'now') {
      date = baseDate;
    } else if (this.args.maxRel) {
      date = add(baseDate, this.args.maxRel);
    } else {
      date = add(baseDate, { months: 12 });
    }
    this.maxDate = formatISO(date);
    // console.log(baseDate, date, this.args.min);

    // console.log('date ctrl:', this.control.field, this.control.ngControl.value, this.args);

    this.UIControl = new FormControl(this.control.ngControl.value);
    this.UIControl.setValidators(this.control.ngControl.validator);

    // console.log(this.args.assignNow, this.control.ngControl.value);
    if (this.args.assignNow && !this.control.ngControl.value) {
      this.setToday();
    }

    // console.log('date ctrl:' + this.control.field, 'value:', this.control.ngControl.value, 'min:' + this.minDate, 'max:' + this.maxDate, 'args:', this.args);
  }

  onChange(evt) {
    // const saveFormat = this.args.saveFormat || 'yyyy-MM-ddTHH:mm:ss.SSS';
    try {
      const transformedValue = this.dateServ.toDatetimeUTC(this.UIControl.value, this.args.saveFormat); // this.datePipe.transform(this.UIControl.value, saveFormat, 'UTC', 'es-MX') + 'Z';
      // console.log(this.UIControl.value, ' changed to ', transformedValue, ' from ', this.args.saveFormat);
      // const processed = this.dateServ.fromUTC(transformedValue);
      this.control.ngControl.patchValue(transformedValue);
    } catch (err) {
      console.error(err);
      this.control.ngControl.setValue(undefined);
    }
    this.modified(null);
  }

  setToday() {
    this.UIControl.setValue(new Date().toISOString());
    this.onChange(null);
  }

}
