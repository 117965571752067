import { Component } from '@angular/core';

import { NavController, Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AuthService } from './lib/services/auth.service';
import { ConfigService } from './lib/services/config.service';
import { MainMenuService } from './lib/services/main-menu.service';
import { WebapiService } from './lib/services/webapi.service';
import { NotificationService } from './lib/services/notification.service';
import { APP_VERSION } from '../version.json';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  version = APP_VERSION;
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private navCtrl: NavController,
    public webapiServ: WebapiService,
    public configServ: ConfigService,
    public authServ: AuthService,
    public mainMenuServ: MainMenuService,
    public notificationServ: NotificationService
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      if (this.platform.is('cordova')) {
        this.statusBar.styleDefault();
        this.splashScreen.hide();
      }
    });
  }

  splitPaneVisibileChange(evt) {
    // console.log(evt);
    this.mainMenuServ.setSplitStatus(evt.detail.visible);
  }

  getHeaderUserName() {
    let usr = '';
    if (this.authServ.user) {
      usr += this.authServ.user.fullName + '<br>' + this.authServ.user.username;
    }
    return usr;
  }

  goHome() {
    this.navCtrl.navigateRoot('dash');//, { replaceUrl: true, skipLocationChange: true });
    this.mainMenuServ.toggle();
  }

  openNotifications() {
    this.navCtrl.navigateRoot('editar/sistema_Notificacion');//, { replaceUrl: true, skipLocationChange: true });
    this.mainMenuServ.toggle();
  }

  openOptions() {
    this.navCtrl.navigateRoot('opciones');// , { replaceUrl: true, skipLocationChange: true }); corregir <-
    this.mainMenuServ.toggle();
  }
}
