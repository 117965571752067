<ion-label [class]="getLabelClass() + ' ctrl-label'" [position]="labelPosition">{{control.label}}</ion-label>

<div class="imageHolder">
  <ion-icon name="image" class="icon" *ngIf="!control.ngControl.value"></ion-icon>
  <img [src]="control.ngControl.value">
</div>

<input type="file" capture accept="image/*" #cameraInput name="cameraInput" style="display: none;"
  (change)="pictureTaken($event)">

<ion-item>
  <div  class="buttonsBar">
    <ion-button (click)="loadImage()" fill="outline">
      <ion-icon name="image" slot="start"></ion-icon>
      Cargar imagen
    </ion-button>
    <!-- <ion-button (click)="clear()" *ngIf="!args.disableClear">
      <ion-icon name="image" slot="start"></ion-icon>
      Remover imagen</ion-button> -->
  </div>
</ion-item>