import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LoggerService } from './logger.service';

// Opcioens de usuario
export class UISettings {
  tema: string;
  mostrar_noticias: boolean;
  agrupar_siempre_menus: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  initialized = false;
  version = '0.0.1';
  // onReInitialize: BehaviorSubject<void>;
  // settings: Settings;
  settings: any;
  settingsKey: string;

  constructor(private loggerServ: LoggerService) { }

  public async setup(settingsKey: string) {
    // this.version = APP_VERSION;
    /*if (this.initialized) {
      this.loggerServ.info('ConfigService', 'Reinitializing app');
      // this.onReInitialize.next();
    }*/
    this.settingsKey = settingsKey;
    this.settings = {};
    try {
      const storedSettings = localStorage.getItem(settingsKey);
      if (storedSettings) {
        this.settings = JSON.parse(storedSettings);
      } else {
        this.settings = {};
      }
    } catch (err) {
      console.log(err);
    }
    this.initialized = true;
  }

  setSetting(key: string, value: any) {
    const changed = this.settings[key] !== value;
    this.settings[key] = value;
    if (changed) {
      this.settingsChanged();
    }
  }

  getSetting(key: string, defaultValue: any) {
    if (this.settings[key] === undefined) {
      return defaultValue;
    }
    return this.settings[key];
  }

  private settingsChanged() {
    // console.log(this.settings);
    try {
      const serialized = JSON.stringify(this.settings);
      localStorage.setItem(this.settingsKey, serialized);
    } catch (err) {
      console.log(err);
      this.loggerServ.error('ConfigService', 'Error guardando configuración', err);
    }
  }

}
