<ion-item [disabled]="form.computeIsControlDisabled(control)">
  <ion-label [class]="getLabelClass()" [position]="labelPosition">{{control.label}}</ion-label>
  <ion-input type="text" *ngIf="form.computeIsControlReadonly(control)" readonly="true" [value]="getValue()">
  </ion-input>
  <ion-select interface="popover" [formControl]="control.ngControl" (ionChange)="modified($event)" *ngIf="!(form.computeIsControlReadonly(control))">
    <ion-select-option *ngFor="let opc of args.options" [value]="opc.key">{{opc.value}}</ion-select-option>
  </ion-select>
  <div *ngIf="args.suffix" class="suffix">
    {{args.suffix}}
  </div>
</ion-item>