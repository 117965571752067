import { Form } from '../forms/Form';
import { Context } from './context';
import { IRepositoryBase } from './repository';

/** Settings for the CRUD component */

export class CrudSettings {

  /** String property of the model to use for the main label in the listing */
  labelProperty: string;

  secondaryLabelProperty: string;

  searchField?: string;

  searchFields?: string[];

  form: string | (() => Form);

  allowSearch = true;

  repository: string | IRepositoryBase;

  disableAdd?: boolean;

  disableAddWhen?: string;

  allowEditWhen?: string;

  title: string;

  queryParams?: { [name: string]: any; } = {};

  context?: Context;

  filterToggles?: CrudFilterToggle[];

  customLabel: (obj: any) => string;

  constructor(other?: Partial<CrudSettings>) {
    if (other) {
      Object.assign(this, other);
    }
  }

  canAdd(): boolean {
    if (this.disableAdd !== undefined) {
      return this.disableAdd;
    } else if (this.disableAddWhen && this.context) {
      return this.context.execute(this.disableAddWhen, true);
    }
    return true;
  }
}

export class CurdLabelComponent {
  field?: string;
  hideWhen?: string;
  noSpace?: boolean;
}

export class CrudFilterToggle {
  icon: string;
  urlParam: string;
  title: string;
}
