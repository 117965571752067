<div class="cardContainer" *ngIf="state=='InputCredentials'">
  <ion-card class="card autoFillInputs">
    <ion-card-content>
      <ion-grid>
        <ion-row>
          <div class="tittle" (click)="openTestingMenu()">
            <h3 *ngIf="!uiServ.setupSettings.appBigLogo">{{title}}</h3>
            <img *ngIf="uiServ.setupSettings.appBigLogo" [src]="uiServ.setupSettings.appBigLogo">
            <h4>Inicio de sesión</h4>
          </div>
          <ion-select [hidden]="true" (ionChange)="testingUserChanged()" [(ngModel)]="testingUserSelected" #hiddenSelect>
            <ion-select-option *ngFor="let opc of testingCredentials" [value]="opc">{{opc.name}}</ion-select-option>
          </ion-select>
        </ion-row>
        <ion-row class="ion-align-items-center">
          <ion-col size="4">
            <div class="label">Usuario</div>
          </ion-col>
          <ion-col>
            <ion-input [(ngModel)]="usuario" id="login_usuario"></ion-input>
          </ion-col>
        </ion-row>
        <ion-row class="ion-align-items-center">
          <ion-col size="4">
            <div class="label">Contraseña</div>
          </ion-col>
          <ion-col>
            <ion-input type="password" [(ngModel)]="password" (keyup.enter)="loginButtonClick()" id="login_password"></ion-input>
          </ion-col>
        </ion-row>
        <ion-row class="ion-align-items-center" style="padding-top: 30px;">
          <ion-col style="text-align: center;">
            <ion-button (click)="loginButtonClick()" [disabled]="!(usuario && password)" id="login_button">Iniciar sesión</ion-button>
          </ion-col>
        </ion-row>
        <ion-row class="ion-align-items-center" *ngIf="authServ.settings.allowPasswordReset">
          <ion-col style="text-align: center;">
            <ion-button fill="clear" color="secondary" (click)="resetPassword()" id="login_forgot">Olvidé mi contraseña</ion-button>
          </ion-col>
        </ion-row>
        <div class="ver">
          {{configServ.version}}
        </div>
      </ion-grid>
    </ion-card-content>
  </ion-card>
</div>

<div class="cardContainer" *ngIf="state == 'AskResetPassword'">
  <ion-card class="card">
    <ion-card-content>
      <ion-grid>
        <ion-row>
          <div class="tittle">
            <h3>{{title}}</h3>
            <h4>Recuperar contraseña</h4>
          </div>
        </ion-row>
        <ion-row class="ion-align-items-center">
          <div style="width: 100%; padding: 15px; text-align: center; margin-top: -48px;">
            Ingrese el correo de su usuario para recibir un código que le permitirá cambiar su contraseña.
          </div>
        </ion-row>
        <ion-row class="ion-align-items-center">
          <ion-col size="4">
            <div class="label">Usuario</div>
          </ion-col>
          <ion-col>
            <ion-input [(ngModel)]="resetUser"></ion-input>
          </ion-col>
        </ion-row>
        <ion-row class="ion-align-items-center" style="width: 100%;padding-top: 20px;">
          <ion-col style="text-align: center;">
            <ion-button (click)="resetSendCode()">Enviar código</ion-button>
          </ion-col>
        </ion-row>
        <ion-row class="ion-align-items-center" style="width: 100%;padding-top: 0px;">
          <ion-col style="text-align: center;">
            <ion-button (click)="resetGoToConfirm()" fill="clear">Ya tengo el código</ion-button>
          </ion-col>
        </ion-row>
        <div class="ver">
          <ion-button fill="clear" (click)="resetCancel()">
            <ion-icon name="close-outline" slot="icon-only" color="dark"></ion-icon>
          </ion-button>
        </div>
      </ion-grid>
    </ion-card-content>
  </ion-card>
</div>

<div class="cardContainer" *ngIf="state == 'AskConfirmResetCode'">
  <ion-card class="card">
    <ion-card-content>
      <ion-grid>
        <ion-row>
          <div class="tittle">
            <h3>{{title}}</h3>
            <h4>Recuperar contraseña</h4>
          </div>
        </ion-row>
        <ion-row class="ion-align-items-center">
          <div style="width: 100%; padding: 15px; text-align: center; margin-top: -48px;">
            Ingrese el último código que recibió en su bandeja de correo.
          </div>
        </ion-row>
        <ion-row class="ion-align-items-center">
          <ion-col size="4">
            <div class="label">Código</div>
          </ion-col>
          <ion-col>
            <ion-input type="text" [(ngModel)]="resetCode"></ion-input>
          </ion-col>
        </ion-row>
        <ion-row class="ion-align-items-center">
          <ion-col size="4">
            <div class="label">Usuario</div>
          </ion-col>
          <ion-col>
            <ion-input [(ngModel)]="resetUser"></ion-input>
          </ion-col>
        </ion-row>
        <ion-row class="ion-align-items-center">
          <ion-col size="4">
            <div class="label">Contraseña</div>
          </ion-col>
          <ion-col>
            <ion-input type="password" [(ngModel)]="resetNewPass" autocomplete="new-password"></ion-input>
          </ion-col>
        </ion-row>
        <ion-row class="ion-align-items-center">
          <ion-col size="4">
            <div class="label">Confirmar contraseña</div>
          </ion-col>
          <ion-col>
            <ion-input type="password" [(ngModel)]="resetNewPassConfirm" autocomplete="new-password"></ion-input>
          </ion-col>
        </ion-row>
        <ion-row class="ion-align-items-center" style="width: 100%;padding-top: 20px;">
          <ion-col style="text-align: center;">
            <ion-button (click)="resetConfirm()">Restablecer</ion-button>
          </ion-col>
        </ion-row>
        <div class="ver">
          <ion-button fill="clear" (click)="resetCancel()">
            <ion-icon name="close-outline" slot="icon-only" color="dark"></ion-icon>
          </ion-button>
        </div>
      </ion-grid>
    </ion-card-content>
  </ion-card>
</div>