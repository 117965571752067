// tslint:disable: variable-name
// tslint:disable: max-line-length
import { Model } from '../../../lib/data/model';
import { Form } from '../../../lib/forms/Form';
import { SelectModalCtrlArgs } from 'src/app/lib/forms/controls/selectmodal/selectmodal.args';
import { NumberCtrlArgs, NumberCtrlType } from 'src/app/lib/forms/controls/number/args';
import { SelectCtrlArgs, SelectCtrlArgsOptions } from 'src/app/lib/forms/controls/select/args';
import { Control } from 'src/app/lib/forms/Control';
import { DataService } from 'src/app/lib/services/data.service';
import { DateControlArgs } from 'src/app/lib/forms/controls/date/date.args';
import { UsuarioPrecios } from './usuarioPrecios';
import { Subject } from 'rxjs';
import { Titles } from 'src/app/lib/data/titles';

export enum CasoEstado {
  NUEVO = 1,
  ACTIVO = 2,
  CERRADO = 3
}
export enum CasoTipoVenta {
  CONTADO = 1,
  CREDITO = 2
}
export const CasoTipoVenta_Select: SelectCtrlArgsOptions[] = [
  { key: 1, value: 'Contado' },
  { key: 2, value: 'Crédito' },
];

function ActualizaLabelDePrecio(ctrl: Control) {
  let label = 'Precio sugerido';
  if (ctrl.field === 'presentacion' || ctrl.field === 'medida') {
    const form = ctrl.parent as Form;
    const precioCtrl = form.getControl('precio_sugerido');
    /*
    const pressCtrl = form.getControl('presentacion');
    const medidaCtrl = form.getControl('medida');
    if (pressCtrl.ngControl.value && medidaCtrl.ngControl.value) {
      label += ' (Ej: ' + pressCtrl.ngControl.value + ' x ' + form.context.getValue('select__medida').__str__ + ')';
    }*/
    const pressCtrl = form.getControl('presentacion');
    const medidaCtrl = form.getControl('medida');
    if (medidaCtrl.ngControl.value) {
      // label += ' (Ej: ' + pressCtrl.ngControl.value + ' x ' + form.context.getValue('select__medida').__str__ + ')';
      label += ' por c/ ' + medidaCtrl.ngControl.value;
    }
    precioCtrl.label = label;
  }
}

function ResetFields(ctrl: Control) {
  if (ctrl.field === 'tipo_venta') {
    const form = ctrl.parent as Form;
    if (ctrl.ngControl.value !== 2) {
      const dias = form.getControl('dias_para_credito');
      dias.ngControl.patchValue(undefined);
    }
  }
}

@Model({
  name: 'Casos',
  singular: 'caso',
  plural: 'casos',
  primaryKey: 'id',
  restEndpoint: 'precios/casos/',
  //menuOptions: {
  //  groups: [{
  //    title: '🏠 Mi menú',
  //    childOptions: [{
  //      icon: 'assets/icons/Visitas/Tipo_cierre.png',
  //      title: 'Solicitar precios',
  //      route: 'precios',
  //      authGroups: ['precios'],
  //      authPermissions: ['Can add Caso', 'Can see Caso']
  //    }]
  //  }]
  //},
  titles: new Titles([
    {
      text: 'return "Nueva solicitud de precios"',
      when: 'return val.editor().isNew'
    },
    {
      text: 'return "Información de solicitud"',
      when: 'return !val.editor().isNew'
    }
  ]),
  forms: {
    default: () => {
      const frm = new Form({
        title: 'Nueva solicitud',
        childControls: [
          /*{
            field: 'producto', label: 'Producto', type: 'selectModal', required: true, args: new SelectModalCtrlArgs({
              model: 'Producto',
              titleProperty: 'nombre',
              allowAddWhen: '["usr::puesto","==","gerente"]',
              allowEditWhen: '["usr::puesto","==","gerente"]'
            })
          },*/
          { field: 'cliente', label: 'Cliente', type: 'text', required: true },
          { field: 'producto', label: 'Producto (Ej: Urea granular a granel)', type: 'textarea', required: true },
          { field: 'presentacion', label: 'Presentación (Ej: Sacos 50Kg)', type: 'textarea', required: true },
          { field: 'cantidad', label: 'Cantidad', type: 'number', required: true, args: new NumberCtrlArgs({ suffix: '' }) },
          { field: 'medida', label: 'Medida', type: 'selectModal', required: true, args: new SelectModalCtrlArgs({ model: 'sistema_Medida', allowAdd: false, storeValueInContext: true }) },
          { field: 'precio_sugerido', label: 'Precio unitario sugerido', type: 'number', args: new NumberCtrlArgs({ suffix: '', type: NumberCtrlType.Currency }) },
          { field: 'margen_sugerido', label: 'Margen sugerido (% ya incluido en el precio)', type: 'number', required: false, min: 0, args: new NumberCtrlArgs({ type: NumberCtrlType.Percent }) }, //max: 200
          { field: 'precio_competencia', label: 'Precio competencia', type: 'number', args: new NumberCtrlArgs({ suffix: '', type: NumberCtrlType.Currency }) },
          { field: 'tipo_venta', label: 'Tipo de venta', type: 'select', required: true, args: new SelectCtrlArgs({ options: CasoTipoVenta_Select }) },
          { field: 'dias_para_credito', label: 'Días de credito', type: 'number', required: true, hiddenWhen: 'return val.formValue.tipo_venta == 1 || !val.formValue.tipo_venta' },
          { field: 'fecha_requerido', label: 'Plazo de entrega', type: 'date', required: true, args: new DateControlArgs({ min: 'now', pickerFormat: 'DD MMM YYYY HH mm', displayFormat: 'DD de MMMM de YYYY, hh:mm A', maxRel: { years: 1 } }) },
          { field: 'lugar_entrega', label: 'Lugar de entrega', type: 'textarea', required: true },
          { field: 'comentarios', label: 'Comentarios', type: 'textarea' },
        ]
        // onLoad: Casos.formChanged,
        // onControlValueChanged: Casos.formChanged
      });
      frm.onControlValueChanged.subscribe((ctrl) => {
        ActualizaLabelDePrecio(ctrl);
        ResetFields(ctrl);
      });
      return frm;
    },
    readonly: () => {
      const frm = new Form({
        readonly: true,
        disableSave: true,
        disableDelete: true,
        title: 'Información de solicitud',
        childControls: [
          // { field: 'producto', label: 'Producto', type: 'selectModal', args: new SelectModalCtrlArgs({ model: 'Producto', titleProperty: 'nombre' }) },
          { field: 'cliente', label: 'Cliente', type: 'text', },
          { field: 'producto', label: 'Producto', type: 'textarea' },
          { field: 'presentacion', label: 'Presentación', type: 'textarea' },
          { field: 'cantidad', label: 'Cantidad', type: 'number', args: new NumberCtrlArgs({ suffix: '' }) },
          { field: 'medida', label: 'Medida', type: 'selectModal', args: new SelectModalCtrlArgs({ model: 'sistema_Medida' }) },
          { field: 'precio_sugerido', label: 'Precio sugerido', type: 'number', args: new NumberCtrlArgs({ suffix: '', type: NumberCtrlType.Currency }), hiddenWhen: 'return !val.formValue.precio_sugerido' },
          { field: 'margen_sugerido', label: 'Margen sugerido', type: 'number', min: 0, max: 100, args: new NumberCtrlArgs({ type: NumberCtrlType.Percent }), hiddenWhen: 'return !val.formValue.precio_sugerido' },
          { field: 'tipo_venta', label: 'Tipo de venta', type: 'select', args: new SelectCtrlArgs({ options: CasoTipoVenta_Select }) },
          { field: 'dias_para_credito', label: 'Días para credito', type: 'number', hiddenWhen: 'return val.formValue.tipo_venta == 1' },
          { field: 'fecha_requerido', label: 'Plazo de entrega', type: 'date', args: new DateControlArgs({ min: 'now', pickerFormat: 'DD MMM YYYY HH mm', displayFormat: 'DD de MMMM de YYYY, hh:mm A' }) },
          { field: 'lugar_entrega', label: 'Lugar de entrega', type: 'text', max: 50 },
          { field: 'comentarios', label: 'Comentarios', type: 'textarea', },
          { field: 'fecha_alta', label: 'Fecha y hora de solicitud', type: 'date', args: new DateControlArgs({ min: 'now', pickerFormat: 'DD MMM YYYY HH mm', displayFormat: 'DD de MMMM de YYYY, hh:mm A' }) },

          { field: 'usuario_creador_nombre', label: 'Solicitante', type: 'text', },
          { field: 'usuario_responsable_nombre', label: 'Responsable actual', type: 'text', }
        ],
        // onLoad: Casos.formChanged,
      });
      frm.onLoad.subscribe((rfrm) => {
        ActualizaLabelDePrecio(rfrm.getControl('presentacion'));
      })
      return frm;
    },
  }
})
export class Casos {
  id: number;
  usuario_creador: number;
  usuario_creador_nombre: string;
  usuario_asignado: number;
  usuario_asignado_nombre: string;
  usuario_responsable: number;
  cliente: string; // max_length=100
  producto: string;
  cantidad: number;
  fecha_requerido: string;
  fecha_alta: string;
  fecha_cierre: string;
  comentarios: string;
  status = CasoEstado.NUEVO;
  lugar_entrega: string;
  tipo_venta: CasoTipoVenta;
  responde_costos: boolean;

  _leidoStatus: 'none' | 'read' | 'notRead';

  static async reloadPresentacion(form: Form) {
    const currentValue = form.getControl('producto').ngControl.value;
    const currentCant = form.getControl('cantidad').ngControl.value;
    // console.log('VALUE:', currentValue);
    // No se usa ya que producto ahora es string
    /*const ctrlArgs = form.getControl('cantidad').args as NumberCtrlArgs;
    if (currentValue === undefined) { ctrlArgs.suffix = ''; return; }
    const preRepo = DataService.Singleton.getRepo('Presentacion');
    const productoRepo = DataService.Singleton.getRepo('Producto');
    try {
      const prd = await productoRepo.query({ id: currentValue });
      const res = await preRepo.query({ id: prd[0].medida });
      if (currentCant === 1) {
        ctrlArgs.suffix = res[0].nombre;
      } else {
        ctrlArgs.suffix = res[0].nombre_plural;
      }
    } catch (err) {
      console.error(err);
      ctrlArgs.suffix = '';
    }*/
  }

  static async formChanged(control?: Control) {
    const form = control.parent as Form;
    if (control) {
      if (control.field === 'producto' || control.field === 'cantidad') {
        await Casos.reloadPresentacion(form);
      }
    } else {
      await Casos.reloadPresentacion(form);
    }
    // console.log(component, form, control);
    /*console.log('Loaded ', component, form);
    const ctrlArgs = form.getControl('cantidad').args as NumberCtrlArgs;
    const prd = form.getControl('producto');
    if (prd.value) {
      ctrlArgs.suffix = prd.value;
    } else {
      ctrlArgs.suffix = '';
    }*/
  }
}

