<ion-item>
  <ion-label [class]="getLabelClass()" [position]="labelPosition">{{control.label}}</ion-label>
  <div class="formatedValueBox" *ngIf="args.type === 1 && !hasFocus">
    {{control.ngControl.value | number }}
  </div>
  <div class="formatedValueBox" *ngIf="args.type === 2 && !hasFocus">
    {{control.ngControl.value | currency:'MXN' }}
  </div>
  <div class="formatedValueBox" *ngIf="args.type === 3 && !hasFocus">
    {{control.ngControl.value }} {{ control.ngControl.value !== undefined ? '%' : ''}}
  </div>
  <ion-input
    type="number"
    inputmode="numeric"
    [formControl]="control.ngControl"
    [min]="control.min"
    [max]="control.max"
    (ionChange)="modified($event)"
    [readonly]="form.computeIsControlReadonly(control)"
    [disabled]="form.disabled || control.disabled"
    (ionFocus)="focusChange(true)"
    (ionBlur)="focusChange(false)">
  </ion-input>
  <div *ngIf="args.suffix" class="suffix">
    {{args.suffix}}
  </div>
</ion-item>