import { Component, Input, OnInit } from '@angular/core';
import { Control } from '../../Control';
import { Form } from '../../Form';


@Component({
  selector: 'app-validator-error',
  templateUrl: './validator-error.component.html',
  styleUrls: ['./validator-error.component.scss'],
})
export class ValidatorErrorComponent implements OnInit {
  static commonValidatorsMessages = {
    min: 'El valor introducido es menor al requerido',
    max: 'El valor introducido es mayor al requerido',
    email: 'Email invalido',
    required: 'Campo requerido',
    minLength: 'Se requiere de mas texto en este campo',
    maxLength: 'Texto sobrepasa el limite aceptado',
  };

  @Input() control: Control;

  constructor() { }

  ngOnInit() { }

  getErrorMessage(validator: string) {
    let msg = 'Error sin establecer';
    if (ValidatorErrorComponent.commonValidatorsMessages[validator]) {
      msg = ValidatorErrorComponent.commonValidatorsMessages[validator];
    } else {
      msg = this.control.ngComponent.getValidatorErrorMessage(validator);
    }
    return msg;
  }

}
