import { IControlArgs } from '../../Control';

export class DateControlArgs implements IControlArgs {
  displayFormat = 'DD/MM/YYYY HH:mm';
  pickerFormat = 'DD MMMM YYYY HH:mm';
  saveFormat?: string;
  min: string;
  max: string;
  minRel?: Duration;
  maxRel?: Duration;
  allowClear = true;
  assignNow?: boolean;

  constructor(other?: Partial<DateControlArgs>, other2?: Partial<DateControlArgs>) {
    // this.min = new Date().toISOString();
    if (other) {
      Object.assign(this, other);
    }
    if (other2) {
      Object.assign(this, other2);
    }
  }
}

window['DateControlArgs'] = DateControlArgs;