import { Injectable } from '@angular/core';
import { MenuController } from '@ionic/angular';
import { AuthService } from './auth.service';
import { Menu, MenuGroup, MenuOption } from '../menu/menu';
import { Router, NavigationStart } from '@angular/router';
import { Subscription } from 'rxjs';
import { DataService } from './data.service';
import deepmerge from 'deepmerge';
import { BaseUser } from '../data/BaseUser';
import { ConfigService } from './config.service';

export class Settings {
  mainMenuInHeader = false;
  mainMenuName = 'mainMenu';
  mainMenu: Menu;
  mainMenuEnabled = false;
  appTitle = 'AppTittle';
  hideMenuIfNotLoggedIn = true;
  contextualOptions?: MenuOption[];
}

@Injectable({
  providedIn: 'root'
})
export class MainMenuService {
  settings: Settings;
  splitIsActive: boolean;
  private prevRoute: string;
  private navSubscription: Subscription;

  constructor(
    public menuCtrl: MenuController,
    private router: Router,
    private dataServ: DataService,
    private configServ: ConfigService,
    private authServ: AuthService) {
    this.settings = new Settings();
  }

  async setup(settings: Settings) {
    this.settings = settings;
    if (this.settings.mainMenuEnabled) {
      this.menuCtrl.enable(true, this.settings.mainMenuName);
    }
    if (settings.hideMenuIfNotLoggedIn) {
      this.authServ.onUserLoginChange.subscribe((state) => {
        this.menuCtrl.enable(state, this.settings.mainMenuName);
      });
      this.menuCtrl.enable(this.authServ.isLoggedIn, this.settings.mainMenuName);
    }

    if (this.navSubscription) {
      this.navSubscription.unsubscribe();
    }

    this.navSubscription = this.router.events.subscribe({
      next: (e) => {
        if (e instanceof NavigationStart) {
          // console.log('NAVIGATION ', e);

          // Hide last viewer created
          /*try {
            if (this.lastImgViewerCreated && this.lastImgViewerCreated.hide) {
              this.lastImgViewerCreated.hide();
            }
          } catch (e) {
            console.error(e);
          }*/
          if (this.prevRoute) {
            const prevOpcOld = this.findOptionByRoute(this.prevRoute);
            if (prevOpcOld) {
              prevOpcOld.isActive = false;
            }
          }

          // Update selected option
          this.prevRoute = e.url.replace('/', '');
          const prevOpc = this.findOptionByRoute(this.prevRoute);
          if (prevOpc) {
            prevOpc.isActive = true;
            // console.log(prevOpc);
          }
        }
      }
    });
  }

  /** Refreshes the state of the menu. Auth, order, visibility and colapse */
  updateState() {
    this.updateAuth();
    this.updateSorting();
    this.updateColapseState();
  }

  updateSorting() {
    for (const grp of this.settings.mainMenu.groups) {
      this.sortMenuGroupOptions(grp);
    }
  }

  sortMenuGroupOptions(grp: MenuGroup) {
    if (!grp.childOptions) { return; }
    // console.log(grp.title);
    grp.childOptions.sort((a, b) => {
      const ta = a.order !== undefined ? a.order : 0;
      const tb = b.order !== undefined ? b.order : 0;
      // console.log(ta,tb, ta.localeCompare(tb));
      // console.log(ta, tb, ta - tb, a.title, b.title);
      return ta - tb;
      // return ta.localeCompare(tb);
    });
  }

  setSplitStatus(isVisible: boolean) {
    this.splitIsActive = isVisible;
  }

  toggle() {
    this.menuCtrl.toggle(this.settings.mainMenuName);
  }

  readMenuGroupCollapseState(menuGrp: MenuGroup) {
    // menuGrp.collapsed = !menuGrp.collapsed;
    // this.configServ.setSetting('menu_state_' + menuGrp.title, menuGrp.collapsed);
  }

  findOptionByRoute(route: string): MenuOption {
    // console.log(route);
    if (!this.settings || !this.settings.mainMenu) {
      return null;
    }
    for (const grp of this.settings.mainMenu.groups) {
      for (const option of grp.childOptions) {
        if (option.route === route) {
          return option;
        }
      }
    }
  }

  mergeMenusFromModels() {
    for (const modelName in this.dataServ.modelRegistry) {
      if (!this.dataServ.modelRegistry.hasOwnProperty(modelName)) { continue; }
      const model = this.dataServ.modelRegistry[modelName];
      if (!model.menuOptions) { continue; }

      // Menu group options
      if (model.menuOptions.groups) {
        model.menuOptions.groups.forEach(grp => {
          // console.log(grp);
          const menuGrp = this.getMenuGroup(grp.id || grp.title);
          if (menuGrp) {
            const opcs = grp.childOptions;
            delete grp.childOptions;
            Object.assign(menuGrp, grp);
            menuGrp.childOptions.push(...opcs);
          } else {
            this.settings.mainMenu.groups.push(grp);
          }
        });
        // Object.assign(this.settings.mainMenu.groups, model.menuOptions.groups);
      }
      // Context options
      if (model.menuOptions.contextualOptions) {
        model.menuOptions.contextualOptions.forEach(opc => {
          if (!this.settings.contextualOptions) {
            this.settings.contextualOptions = [];
          }
          this.settings.contextualOptions.push(opc);
        });
      }
    }
    this.updateState();
    // console.log(this.settings);
  }

  getContextualMenuOptions(contextId: string): MenuOption[] {
    if (!this.settings.contextualOptions) {
      return [];
    }
    return this.settings.contextualOptions.filter(iter => iter.context === contextId);
  }

  getMenuGroup(id: string) {
    return this.settings.mainMenu.groups.find(iter => iter.id === id || iter.title === id);
  }

  /** Updates authorization (visibility) in all options of all menus of the main menu */
  updateAuth() {
    const user = this.authServ.user;
    if (this.settings.mainMenu.options) {
      this.settings.mainMenu.options.forEach(element => {
        this.revalidateAuthInOption(user, element);
      });
    }
    this.settings.mainMenu.groups.forEach(group => {
      let visibleCount = 0;
      group.childOptions.forEach(element => {
        visibleCount += this.revalidateAuthInOption(user, element);
      });
      if (visibleCount === 0) {
        group.hiddenByAuth = true;
      }
    });
  }

  /** Returns the number of authorizations given to the user for a menu option */
  private revalidateAuthInOption(user: BaseUser, opc: MenuOption): number {
    // return 1;
    let hasPerms = false;
    let hasGroups = false;
    let hasFlags = false;
    let hasApiPerms = false;
    if (opc.authPermissions) {
      // console.log(opc);
      hasPerms = user.hasAnyPermission(opc.authPermissions);
    }
    if (opc.authGroups) {
      // console.log(opc);
      hasGroups = user.hasAnyGroup(opc.authGroups);
    }
    if (opc.authBanderas) {
      // TODO hasFlags = user.ha
    }
    /*
    if (opc.apiPermissions && opc.apiPermissions.length) {
      hasApiPerms = true;
    }*/
    const isHidden = !(hasPerms || hasGroups || hasFlags); //  || hasApiPerms);
    opc.hiddenByAuth = isHidden;

    let visibleCount = !isHidden ? 1 : 0;

    /*if (!visibleCount) {
      console.log(opc.title, visibleCount);
    }*/

    if (opc.childOptions) {
      opc.childOptions.forEach(element => {
        visibleCount += this.revalidateAuthInOption(user, element);
      });
    }

    return visibleCount;
  }

  toggleMenuGroup(menuGrp: MenuGroup) {
    menuGrp.collapsed = !menuGrp.collapsed;
    this.configServ.setSetting(this.computeGroupName(menuGrp), menuGrp.collapsed);
  }

  computeGroupName(menuGrp: MenuGroup) {
    return 'menu_state_' + encodeURI(menuGrp.title);
  }

  updateColapseState() {
    this.settings.mainMenu.groups.forEach(group => {
      const state = this.configServ.getSetting(this.computeGroupName(group), null);
      if (state != null) {
        group.collapsed = state;
      }
    });
  }

}
