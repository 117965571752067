import { Component, OnInit, Input } from '@angular/core';
import { Form } from '../../Form';
import { Control } from '../../Control';
import { AbsControlComponent } from '../AbsControlComponent';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
})
export class CheckboxComponent extends AbsControlComponent implements OnInit {
  @Input() control: Control;
  @Input() form: Form;

  constructor() {
    super();
  }

  ngOnInit() {
    this.setupAbsControl();
  }

}
