<div *ngIf="enableRender">
  <ion-virtual-scroll *ngIf="!isFirstLoading" [items]="valuesFiltered" approxItemHeight="47px">
    <ion-item *virtualItem="let item" detail button (click)="edit(item)">
      <ion-label class="ion-text-wrap crudLabel">
        <span [innerHTML]="getLabel(item)"></span>
      </ion-label>
    </ion-item>
  </ion-virtual-scroll>

  <!-- <ion-list *ngIf="!isFirstLoading">
    <ion-item *ngFor="let item of valuesFiltered" detail button (click)="edit(item)">
      <ion-label class="ion-text-wrap crudLabel">
        <span [innerHTML]="getLabel(item)"></span>
      </ion-label>
    </ion-item>
  </ion-list> -->

  <ion-infinite-scroll threshold="100px" (ionInfinite)="loadNext($event)">
    <ion-infinite-scroll-content loadingSpinner="bubbles" loadingText="Cargando más datos...">
    </ion-infinite-scroll-content>
  </ion-infinite-scroll>

  <lib-loading-panel *ngIf="isFirstLoading"></lib-loading-panel>
</div>