import { LOCALE_ID, NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { IonicSelectableModule } from 'ionic-selectable';
import { QuillModule } from 'ngx-quill';

import { TextComponent } from './forms/controls/text/text.component';
import { NumberComponent } from './forms/controls/number/number.component';
import { SelectModalComponent } from './forms/controls/selectmodal/selectmodal.component';
import { MenuComponent } from './menu/menu/menu.component';
import { DateComponent } from './forms/controls/date/date.component';
import { LoginComponent } from './components/login/login.component';
import { LoadingPanelComponent } from './components/loading-panel/loading-panel.component';
import { HeaderComponent } from './components/header/header.component';
import { FormComponent } from './components/form/form.component';
import { EditorComponent } from './components/editor/editor.component';
import { CrudComponent } from './components/crud/crud.component';
import { CheckboxComponent } from './forms/controls/checkbox/checkbox.component';
import { SelectComponent } from './forms/controls/select/select.component';
import { TextAreaComponent } from './forms/controls/textarea/textarea.component';
import { MoneyComponent } from './forms/controls/money/money.component';
import { MenuCompactComponent } from './menu/menu-compact/menu-compact.component';
import { EmailComponent } from './forms/controls/email/email.component';
import { ValidatorErrorComponent } from './forms/controls/validator-error/validator-error.component';
import { ObjectComponent } from './forms/controls/object/object.component';
import { ObjectArrayComponent } from './forms/controls/object-array/object-array.component';
import { TextImageComponent } from './forms/controls/text-image/text-image.component';
import { RichTextComponent } from './forms/controls/rich-text/rich-text.component';
import { ngquillConfig as ngQuillConfig } from './plugins/ngquillConfig';

const formComponents = [
  TextComponent,
  TextAreaComponent,
  CheckboxComponent,
  DateComponent,
  NumberComponent,
  MoneyComponent,
  SelectModalComponent,
  SelectComponent,
  EmailComponent,
  ValidatorErrorComponent,
  ObjectComponent,
  ObjectArrayComponent,
  TextImageComponent,
  RichTextComponent
];

@NgModule({
  declarations: [
    formComponents,
    FormComponent,
    LoginComponent,
    EditorComponent,
    HeaderComponent,
    MenuComponent,
    MenuCompactComponent,
    LoadingPanelComponent,
    CrudComponent
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    IonicSelectableModule,
    IonicModule,
    CommonModule,
    QuillModule.forRoot(ngQuillConfig),
  ],
  exports: [
    FormsModule,
    FormComponent,
    EditorComponent,
    LoginComponent,
    HeaderComponent,
    MenuComponent,
    MenuCompactComponent,
    LoadingPanelComponent,
    CrudComponent
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'es-MX' },
    DatePipe]
})
export class LibModule { }
