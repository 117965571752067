import { IControlArgs } from '../../Control';

export enum NumberCtrlType {
  NoFormat, Number, Currency, Percent, Formatted
}

export class NumberCtrlArgs implements IControlArgs {
  suffix: string;
  type = NumberCtrlType.Number;

  constructor(other?: Partial<NumberCtrlArgs>) {
    if (other) {
      Object.assign(this, other);
    }
  }
}

window['NumberCtrlArgs'] = NumberCtrlArgs;