<ion-header>
  <ion-toolbar color="primary">
    <ion-buttons slot="start" *ngIf="headerButtonAvailable() && !hideMenuButton">
      <ion-button (click)="openMenu()" style="margin-right: -16px;">
        <ion-icon name="menu-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-buttons slot="end" *ngIf="closable">
      <ion-button (click)="doClose()">
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title class="titleText">{{getTitle()}}</ion-title>
  </ion-toolbar>
  <ng-content></ng-content>
</ion-header>
