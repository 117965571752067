import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { InitGuard } from 'src/app/lib/services/init.guard';
import { ContextGuard } from 'src/app/lib/services/context.guard';
import { LibModule } from 'src/app/lib/lib.module';


const routes: Routes = [
  { path: 'ppr/seguimientos', canActivate: [InitGuard], loadChildren: () => import('./pages/seguimiento/seguimiento.module').then(m => m.SeguimientoPageModule) },
  { path: 'ppr/seguimientos/:contexts', canActivate: [InitGuard, ContextGuard], loadChildren: () => import('./pages/seguimiento/seguimiento.module').then(m => m.SeguimientoPageModule) },
];


@NgModule({
  declarations: [
  ],
  imports: [
    RouterModule.forChild(routes),
    CommonModule
  ],
  exports: [
    RouterModule
  ]
})
export class PPRModule {
  constructor() {
    // console.log('asdf');
  }
}
