
import { Control } from './Control';
import { RequestType } from '../services/webapi.service';
import { FormGroup } from '@angular/forms';
import { Context } from '../data/context';
import { Subject } from 'rxjs';
import { FormAction } from './FormAction';
// import { environment } from 'src/environments/environment';

export class Form extends Control {

  // Settings
  formName?: any;
  title?: string;
  help?: string;
  disableDelete?: boolean;
  disableSave?: boolean;
  disableChange?: boolean;
  hideTitle?: boolean;
  readonly?: boolean;
  preferredSaveMethod?: RequestType;
  context?: Context;
  __isVisible = true;
  deleteText = 'Borrar';
  saveText = 'Guardar';
  cancelText = 'Cancelar';

  // Events
  customSave?: (obj: any, form: Form) => Promise<void>;
  customDelete?: (obj: any, form: Form) => Promise<void>;
  onSaved?: () => void;
  onDeleted?: () => void;
  onValidate?: (form: Form) => Promise<[{ field: string; message: string; }] | null>;

  // onValueChange?: (component: any, form: Form, control: Control) => void;
  onLoad = new Subject<Form>();
  onControlValueChanged = new Subject<Control>();

  // Runtime
  ngFormGroup?: FormGroup;
  actions?: FormAction[];
  editorComponent?: any;
  formComponent?: any;
  editorComponentId?: any;

  public constructor(init?: Partial<Form>) {
    super(init);
    Object.assign(this, init);
    this.childControls?.forEach(ctrl => {
      ctrl.parent = this;
    });
  }

  getControl(fieldName: string): Control {
    if (!this.childControls) { return null; }
    return this.childControls.find(ctrl => ctrl.field === fieldName);
  }

  removeControl(fieldName: string) {
    if (!this.childControls) { return null; }
    const indx = this.childControls.findIndex(ctrl => ctrl.field === fieldName);
    this.childControls.splice(indx, 1);
  }

  computeIsControlDisabled(control: Control): boolean {
    if (this.disabled) { return true; }
    if (control.disabled !== undefined) {
      return control.disabled;
    } else if (control.disableWhen) {
      return this.context.execute(control.disableWhen);
    }
    return false;
  }

  computeIsControlHidden(control: Control): boolean {
    let isHidden = this.innerComputeIsControlHidden(control);
    /* if (!environment.production && control.__debugEnabled) {
      console.log()
    }*/
    if (control.field === 'ocultar') {
      isHidden = true;
    }
    control.__isVisible = !isHidden;
    // console.log(control.field, !isHidden);
    return isHidden;
  }

  private innerComputeIsControlHidden(control: Control): boolean {
    // console.log(control.field, control.hidden, control.hiddenWhen);
    if (control.hidden !== undefined) {
      return control.hidden;
    } else if (control.hiddenWhen) {
      return this.context.execute(control.hiddenWhen, false, control.debugHiddenWhen);
    }
    return false;
  }

  computeIsControlRequired(control: Control): boolean {
    // console.log(control.field, control.hidden, control.hiddenWhen, control.required, control.requiredWhen);
    if (control.requiredWhen) {
      // console.log(control.requiredWhen);
      return control.__isRequired = this.context.execute(control.requiredWhen);
    } else {
      return control.__isRequired = control.required;
    }
  }

  computeIsControlReadonly(control: Control): boolean {
    if (this.readonly) { return control.__isReadOnly = true; }
    if (this.readonly !== undefined) { return control.__isReadOnly = this.readonly; }
    if (control.readonly !== undefined) {
      return control.__isReadOnly = control.readonly;
    } else if (control.readonlyWhen) {
      return control.__isReadOnly = this.context.execute(control.readonlyWhen);
    }
    return control.__isReadOnly = false;
  }

  getValue(fieldName: string) {
    return this.ngFormGroup.get(fieldName).value;
  }

  configControls(controls: Control[]) {
    for (const c of controls) {
      let og = this.childControls.find(i => i.field === c.field);
      if (!og) {
        console.error('Campo ' + c.field + ' no existe en modelo ', this);
      } else {
        og = Object.assign(og, c);
      }
    }
  }

  setControls(controls: Control[]) {
    this.configControls(controls);
    for (let i = this.childControls.length - 1; i >= 0; i--) {
      const declared = controls.find(iter => this.childControls[i].field === iter.field);
      if (!declared && this.childControls[i].field !== 'id') {
        this.childControls.splice(i, 1);
      }
    }
    /*
    for (const ctrl of this.childControls) {
      const newDeclaration = controls.find(i => ctrl.field === i.field);
      if (newDeclaration) {
        Object.assign(ctrl, newDeclaration);
      } else {
        ctrl.hidden = true;
      }
    }
    */
  }

  showFields(controls: string[]) {
    for (const ctrl of this.childControls) {
      const found = controls.findIndex(i => ctrl.field === i) > -1;
      ctrl.hidden = !found;
    }
  }

  removeFieldsExcept(controls: string[]) {
    for (let i = this.childControls.length - 1; i >= 0; i--) {
      const found = controls.findIndex(iter => this.childControls[i].field === iter) > -1;
      if (found) { continue; }
      this.childControls.splice(i, 1);
    }
  }
}

export enum FormEvent {
  OnCreate, OnClose, OnSave, OnDelete, OnControlChange
}

// export type FormCreator: (name: type) => Form;
export type FormCreatorFunc = () => Form;
