// tslint:disable: variable-name
import { Model } from '../../../lib/data/model';
import { BaseUser } from '../../../lib/data/BaseUser';

@Model({
  name: 'UsuarioPrecios',
  primaryKey: 'id',
  restEndpoint: 'precios/usuarios/'
})
export class UsuarioPrecios {
  id: number;
  usuario: number;
  nombre: string;
  responde_costos: boolean;
  es_comite = false;
  puesto: string;
  jefe_directo: number;
  jefe_directo_nombre: string;
  sucursales: number[];
  usuarios_apoyo: [{ id: number, puesto: string, respuestas: string }];
  respuestas: string;
}
