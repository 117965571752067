import { Injectable } from '@angular/core';
import { CanActivate, CanLoad, Route, UrlSegment, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { ContextService } from './context.service';

@Injectable({
  providedIn: 'root'
})
export class ContextGuard implements CanActivate {

  constructor(
    private contextServ: ContextService
  ) {

  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (next.paramMap.has('contexts')) {
      const rawContextsIds = next.paramMap.get('contexts').split('~');
      // console.log(rawContextsIds);
      const allQueryParams = {};
      for(const cxt of rawContextsIds) {
        if(!this.contextServ.hasContext(cxt))  {
          return false;
        }
      }
    }
    return true;
  }
  /*canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    return true;
  }*/
}
